import { Input, List, Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { getGroupLists, getGroups } from "../../redux/actions/groupActions";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

const { TabPane } = Tabs;

function Dashboards(props) {
  const [search, setSearch] = useState("");

  const groups = useSelector((state) => state.groups.groups);
  const lists = useSelector((state) => state.groups.lists);
  const loading = useSelector((state) => state.groups.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getGroupLists());
  }, [dispatch]);

  if (loading) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );
  }
  return (
    <Tabs>
      <TabPane tab="Groupes" key="groups">
        <div className="vpn-header">
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Recherche"
          />
        </div>
        <div className="scrollable">
          <List
            itemLayout="horizontal"
            dataSource={groups.filter(
              (grp) =>
                grp.label.toLowerCase().indexOf(search.toLowerCase()) >= 0
            )}
            renderItem={(item) => (
              <List.Item
                actions={[<Link to={`/dashboards/${item.key}`}>Afficher</Link>]}
              >
                <List.Item.Meta title={item.label} />
              </List.Item>
            )}
          />
        </div>
      </TabPane>
      <TabPane tab="Listes" key="lists">
        <div className="vpn-header">
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Recherche"
          />
        </div>
        <div className="scrollable">
          <List
            itemLayout="horizontal"
            dataSource={lists.filter(
              (list) =>
                list.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
            )}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Link to={`/dashboards/list/${item.key}`}>Afficher</Link>,
                ]}
              >
                <List.Item.Meta title={item.name} />
              </List.Item>
            )}
          />
        </div>
      </TabPane>
    </Tabs>
  );
}

export default Dashboards;
