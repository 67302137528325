import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  deleteCherry,
  getBranches,
  getCherries,
  getCommit,
  setCherryPick,
} from "../../redux/actions/gitActions";
import { useDispatch, useSelector } from "react-redux";

import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "../../utils/axios";

function Repos() {
  const [showModal, setShowModal] = useState(false);
  const cherries = useSelector((state) => state.git.cherries);
  const branches = useSelector((state) => state.git.branches);
  const commit = useSelector((state) => state.git.commit);
  const loading = useSelector((state) => state.git.loading);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBranches());
    dispatch(getCherries());
  }, [dispatch]);

  useEffect(() => {
    if (commit?.message) {
      let shortMessage = commit.message.split("\n")[0];
      shortMessage = shortMessage.substring(20) + " #MAINTENANCE";
      form.setFieldsValue({ label: shortMessage });
    }
  }, [commit, form]);

  const handleMerge = async (id) => {
    const queueName = "cherries";
    await axios.post("/api/messages", {
      envs: ["no_env"],
      queueName,
      id,
    });
    notification.success({
      message: "Succès",
      description: "Message envoyé, un refresh de la liste dans 5 secondes",
    });
    setTimeout(() => {
      dispatch(getCherries());
    }, 5000);
  };

  const handleDelete = (id) => {
    dispatch(deleteCherry(id));
  };

  const handleUpdateForm = (e) => {
    if (e?.target?.value) {
      dispatch(getCommit(e.target.value));
    } else {
      dispatch(getCommit(form.getFieldsValue()["origin"]));
    }
  };

  const handleCreateMerge = () => {
    const values = form.getFieldsValue();
    dispatch(setCherryPick(values));
    setShowModal(false);
    form.resetFields();
  };

  const reposColumns = [
    {
      key: "oid",
      title: "oid",
      dataIndex: "oid",
      width: 20,
    },
    {
      key: "date",
      title: "Date de création",
      dataIndex: "created_at",
      width: 50,
    },
    {
      key: "commit",
      title: "Commit d'origine",
      dataIndex: "origin",
      width: 20,
    },
    {
      key: "label",
      title: "Message",
      dataIndex: "label",
      width: 60,
    },
    {
      key: "destination",
      title: "Branches de destination",
      dataIndex: "destination",
      width: 20,
      render: (text, record, index) => {
        return (
          <div className="branches-tags">
            {JSON.parse(record.destination).map((dest) => (
              <Tag key={dest}>{dest}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      width: 20,
      render: (text, record, index) => {
        return (
          <React.Fragment>
            <Button onClick={() => handleMerge(record.oid)}>Fusionner</Button>
            <Button
              type="danger"
              onClick={() => handleDelete(record.oid)}
              style={{ marginLeft: 8 }}
            >
              Supprimer
            </Button>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Modal
        title="Programmer un cherry-pick"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={handleCreateMerge}
        width="50vw"
      >
        <Form form={form}>
          <Form.Item label="Commit" name="origin">
            <Input onBlur={handleUpdateForm} />
          </Form.Item>
          <Form.Item label="Libéllé" name="label">
            <Input.Search
              loading={loading}
              disabled={loading}
              onSearch={handleUpdateForm}
            />
          </Form.Item>
          <Form.Item label="Branches de destination" name="destination">
            <Select
              mode="multiple"
              options={branches.map((branch) => ({
                label: branch,
                value: branch,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
      <div className="clients-header">
        <Button onClick={() => setShowModal(true)}>
          <PlusCircleOutlined />
          Ajouter
        </Button>
      </div>
      <div className="scrollable">
        <Table
          columns={reposColumns}
          dataSource={cherries.filter((cherry) => cherry.status === 0)}
          size="small"
          key="oid"
          loading={loading}
          pagination={false}
        />
      </div>
    </React.Fragment>
  );
}

export default Repos;
