import axios from "../../utils/axios";

export const SET_GROUPS = "SET_GROUPS";
export const SET_GROUPS_LOADING = "SET_GROUPS_LOADING";
export const ADD_GROUP = "ADD_GROUP";
export const SET_GROUP = "SET_GROUP";
export const SET_GROUP_LISTS = "SET_GROUP_LISTS";

export const setGroups = (groups) => {
  return {
    type: SET_GROUPS,
    groups,
  };
};

export const setGroupLists = (lists) => {
  return {
    type: SET_GROUP_LISTS,
    lists,
  };
};

export const setGroupsLoading = (loading) => {
  return {
    type: SET_GROUPS_LOADING,
    loading,
  };
};

export const setGroup = (group) => {
  return {
    type: SET_GROUP,
    group,
  };
};

export const addGroup = (group) => {
  return {
    type: ADD_GROUP,
    group,
  };
};

export const getGroups = () => {
  return async function (dispatch, getState) {
    dispatch(setGroupsLoading(true));
    const res = await axios.get("/api/groups");
    if (res.data && Array.isArray(res.data)) {
      dispatch(setGroups(res.data));
    }
    dispatch(setGroupsLoading(false));
  };
};

export const getGroupLists = () => {
  return async function (dispatch, getState) {
    dispatch(setGroupsLoading(true));
    const res = await axios.get("/api/grouplists");
    if (res.data && Array.isArray(res.data)) {
      dispatch(setGroupLists(res.data));
    }
    dispatch(setGroupsLoading(false));
  };
};

export const updateGroup = (group) => {
  return async function (dispatch, getState) {
    dispatch(setGroup(group));
    await axios.put("/api/groups/" + group.key, { group });
    dispatch(getGroups());
  };
};

export const createGroup = (group) => {
  return async function (dispatch, getState) {
    await axios.post("/api/groups", { group });
    dispatch(getGroups());
  };
};

export const deleteGroup = (key) => {
  return async function (dispatch, getState) {
    await axios.delete("/api/groups/" + key);
    dispatch(getGroups());
  };
};

export const saveList = (list) => {
  return async function (dispatch, getState) {
    await axios.post("/api/grouplists", { list });
    dispatch(getGroupLists());
  };
};

export const deleteList = (list) => {
  return async function (dispatch, getState) {
    await axios.delete("/api/grouplists/" + list.oid);
    dispatch(getGroupLists());
  };
};
