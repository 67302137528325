import {
  SET_FIELDS,
  SET_JIRA_LOADING,
  SET_JIRA_TYPES,
  SET_PROJECTS,
  SET_USERS,
} from "../actions/jiraActions";

const INITIAL_STATE = {
  projects: [],
  types: [],
  fields: [],
  users: [],
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };

    case SET_JIRA_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case SET_JIRA_TYPES:
      return {
        ...state,
        types: action.types,
      };

    case SET_FIELDS:
      return {
        ...state,
        fields: action.fields,
      };

    case SET_USERS:
      return {
        ...state,
        users: action.users,
      };

    default:
      return state;
  }
};

export default reducer;
