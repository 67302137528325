import {
  ADD_GROUP,
  SET_GROUP,
  SET_GROUPS,
  SET_GROUPS_LOADING,
  SET_GROUP_LISTS,
} from "../actions/groupActions";

const INITIAL_STATE = {
  groups: [],
  loading: false,
  latest: null,
  lists: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_GROUPS:
      return {
        ...state,
        groups: action.groups,
      };

    case SET_GROUP_LISTS:
      return {
        ...state,
        lists: action.lists,
      };

    case SET_GROUPS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case ADD_GROUP:
      return {
        ...state,
        groups: [action.group, ...state.groups],
      };

    case SET_GROUP:
      const newData = [...state.groups];
      let group = newData.findIndex((srv) => srv.key === action.group.key);
      const item = newData[group];
      newData.splice(group, 1, { ...item, ...action.group });
      return {
        ...state,
        groups: newData,
      };

    default:
      return state;
  }
};

export default reducer;
