import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import {
  createStrategy,
  deleteStrategy,
  getStrategies,
  updateStrategy,
} from "../../redux/actions/strategyActions";
import { useDispatch, useSelector } from "react-redux";

import EditableTable from "../utils/EditableTable";
import { PlusCircleOutlined } from "@ant-design/icons";

function Strategy(props) {
  const [showStrategyModal, setShowStrategyModal] = useState(false);
  const strategies = useSelector((state) => state.strategies.strategies);
  const loading = useSelector((state) => state.strategies.loading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getStrategies());
  }, [dispatch]);

  const handleCreateStrategy = async () => {
    try {
      const values = await form.validateFields();
      dispatch(createStrategy(values));
      setShowStrategyModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSave = (strategy) => {
    dispatch(updateStrategy(strategy));
  };

  const handleDelete = (key) => {
    dispatch(deleteStrategy(key));
  };

  const strategyColumns = [
    {
      key: "oid",
      title: "oid",
      dataIndex: "key",
      width: 60,
    },
    {
      key: "comment",
      title: "Commentaire",
      dataIndex: "comment",
      editable: true,
    },
    {
      key: "duration",
      title: "Durée de validité (en heures)",
      dataIndex: "duration",
      editable: true,
    },
  ];

  return (
    <React.Fragment>
      <Modal
        title="Créer une stratégie"
        visible={showStrategyModal}
        onCancel={() => setShowStrategyModal(false)}
        onOk={handleCreateStrategy}
        width="50vw"
      >
        <Form form={form}>
          <Form.Item label="Commentaire" name="comment">
            <Input />
          </Form.Item>
          <Form.Item label="Durée de validité (en heures)" name="duration">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <div className="clients-header">
        <Button onClick={() => setShowStrategyModal(true)}>
          <PlusCircleOutlined />
          Ajouter
        </Button>
      </div>
      <EditableTable
        columns={strategyColumns}
        dataSource={strategies}
        size="small"
        onSave={handleSave}
        loading={loading}
        onDelete={handleDelete}
      />
    </React.Fragment>
  );
}

Strategy.propTypes = {};

export default Strategy;
