import axios from "../../utils/axios";

export const SET_SERVERS = "SET_SERVERS";
export const SET_AGENTS = "SET_AGENTS";
export const ADD_SERVER = "ADD_SERVER";
export const SET_SERVER = "SET_SERVER";
export const SET_SERVERS_LOADING = "SET_SERVERS_LOADING";

export const setServers = (servers) => {
  return {
    type: SET_SERVERS,
    servers,
  };
};

export const setAgents = (agents) => {
  return {
    type: SET_AGENTS,
    agents,
  };
};

export const setServersLoading = (loading) => {
  return {
    type: SET_SERVERS_LOADING,
    loading,
  };
};

export const setServer = (server) => {
  return {
    type: SET_SERVER,
    server,
  };
};

export const addServer = (server) => {
  return {
    type: ADD_SERVER,
    server,
  };
};

export const getServers = () => {
  return async function (dispatch, getState) {
    dispatch(setServersLoading(true));
    const res = await axios.get("/api/servers");
    if (res.data && Array.isArray(res.data)) {
      dispatch(setServers(res.data));
    }
    dispatch(setServersLoading(false));
  };
};

export const getAgents = () => {
  return async function (dispatch, getState) {
    dispatch(setServersLoading(true));
    const res = await axios.get("/api/agents");
    if (res.data && Array.isArray(res.data)) {
      dispatch(setAgents(res.data));
    }
    dispatch(setServersLoading(false));
  };
};

export const updateServer = (server, old_agent) => {
  return async function (dispatch, getState) {
    dispatch(setServer(server));
    await axios.put("/api/servers/" + server.key, { server, old_agent });
  };
};

export const createServer = (server) => {
  return async function (dispatch, getState) {
    await axios.post("/api/servers", { server });
    dispatch(getServers());
  };
};

export const deleteServer = (key) => {
  return async function (dispatch, getState) {
    await axios.delete("/api/servers/" + key);
    dispatch(getServers());
  };
};
