import React, { useEffect, useState } from "react";

import DiskHistoryGraph from "./DiskHistoryGraph";
import ServerDiskGraph from "./ServerDiskGraph";
import { Spin } from "antd";
import axios from "../../utils/axios";
import { nest } from "d3-collection";

function Servers({ list, group }) {
  const [servers, setServers] = useState([]);
  const [stats, setStats] = useState({});
  const [benchResults, setBenchResults] = useState({});

  useEffect(() => {
    const getData = async () => {
      let res;
      if (list) {
        res = await axios.get("/api/stats/servers/" + group + "?list=true");
      } else {
        res = await axios.get("/api/stats/servers/" + group);
      }
      setStats(res.data);
    };
    getData();
  }, [list, group]);

  useEffect(() => {
    if (stats.disk) {
      const tmpSrv = stats.disk.reduce((prev, curr) => {
        let key = Object.keys(prev).find((key) => key === curr.hostname);
        if (key) {
          prev[key].push(curr);
        } else {
          prev[curr.hostname] = [curr];
        }
        return prev;
      }, {});
      setServers(tmpSrv);
    }
  }, [stats]);

  useEffect(() => {
    if (stats.bench) {
      var sumstat = nest() // nest function allows to group the calculation per level of a factor
        .key((d) => d.hostname)
        .entries(stats.bench);
      let results = {};
      sumstat.forEach((server) => {
        let tmpBench = server.values.map((bench) => JSON.parse(bench.results));

        tmpBench = tmpBench.map((bench) => {
          for (let key in bench) {
            let a = bench[key].split(":");
            var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
            bench[key] = seconds;
          }
          return bench;
        });

        let latest = { ...tmpBench[tmpBench.length - 1] };
        for (let key in latest) {
          latest[key] = latest[key].toFixed(2);
        }
        const c_values = tmpBench.map((bench) => bench.c_result);
        const c_mean =
          c_values.reduce((prev, curr) => prev + curr, 0) / c_values.length;
        const cti_server_values = tmpBench.map(
          (bench) => bench.cti_server_result
        );
        const cti_server_mean =
          cti_server_values.reduce((prev, curr) => prev + curr, 0) /
          cti_server_values.length;
        const d_values = tmpBench.map((bench) => bench.d_result);
        const d_mean =
          d_values.reduce((prev, curr) => prev + curr, 0) / d_values.length;
        const mean = {
          c_result: c_mean.toFixed(2),
          d_result: d_mean.toFixed(2),
          cti_server_result: cti_server_mean.toFixed(2),
        };
        let max = tmpBench.reduce(
          (prev, curr) =>
            curr.cti_server_result > prev.cti_server_result ? curr : prev,
          { c_result: 0, d_result: 0, cti_server_result: 0 }
        );
        for (let key in max) {
          max[key] = max[key].toFixed(2);
        }
        results[server.key] = [latest, mean, max];
      });
      setBenchResults(results);
    }
  }, [stats]);

  if (!Object.keys(stats).length) {
    return <Spin style={{ width: "100%", marginTop: "50vh" }} />;
  }
  console.log(benchResults, servers);
  return (
    <div className="servers-dashboard scrollable">
      {Object.entries(servers).map((server) => {
        const isOffline = server[1].length && !server[1][0].timestamp_ok;
        const isWarning =
          server[1].length &&
          server[1].some((disk) => disk.available < disk.total / 10);
        const uptimeServer = stats.uptime.find(
          (srv) => srv.hostname === server[0]
        );
        return (
          <div
            className={`server-card ${isOffline ? "group-env-error" : ""} ${
              isWarning ? "group-env-warning" : ""
            }`}
            key={server[0]}
          >
            <div className="server-card-title">
              {server[0]} -
              {!isOffline
                ? ` Démarré depuis le ${
                    uptimeServer ? uptimeServer.started : ""
                  } UTC`
                : ` Ce serveur semble éteint, dernier signe de vie le ${server[1][0].timestamp}`}
            </div>
            <div className="server-card-content">
              <div className="server-card-left">
                <div className="server-disk-pie">
                  <ServerDiskGraph
                    disks={server[1]}
                    index={server[0]}
                    isOffline={isOffline}
                  />
                </div>
                <div className="server-bench">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>cti_server</th>
                        <th>disque CTI</th>
                        <th>disque C:</th>
                      </tr>
                    </thead>
                    <tbody>
                      {benchResults[server[0]] && (
                        <React.Fragment>
                          <tr>
                            <td>Dernière</td>
                            <td>
                              {benchResults[server[0]][0].cti_server_result}
                            </td>
                            <td>{benchResults[server[0]][0].c_result}</td>
                            <td>{benchResults[server[0]][0].d_result}</td>
                          </tr>
                          <tr>
                            <td>Moyenne</td>
                            <td>
                              {benchResults[server[0]][1].cti_server_result}
                            </td>
                            <td>{benchResults[server[0]][1].c_result}</td>
                            <td>{benchResults[server[0]][1].d_result}</td>
                          </tr>
                          <tr>
                            <td>Max</td>
                            <td>
                              {benchResults[server[0]][2].cti_server_result}
                            </td>
                            <td>{benchResults[server[0]][2].c_result}</td>
                            <td>{benchResults[server[0]][2].d_result}</td>
                          </tr>
                        </React.Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="server-graphs">
                <div className="server-disk-history">
                  <DiskHistoryGraph
                    index={server[0]}
                    isOffline={isOffline}
                    data={stats.diskHistory.filter(
                      (hist) => hist.hostname === server[0]
                    )}
                    memoryData={stats.memoryHistory.filter(
                      (hist) => hist.hostname === server[0]
                    )}
                    title="l'espace disque"
                    order={server[1]}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="server-ai"></div>
    </div>
  );
}

Servers.propTypes = {};

export default Servers;
