import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card } from "antd";
import { Line } from "@ant-design/charts";
import { getStats } from "../redux/actions/statsActions";

function Stats() {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.stats.stats);

  useEffect(() => {
    if (!stats) {
      dispatch(getStats());
    }
  }, [dispatch, stats]);

  var config = {
    data: stats.stats.sort((a, b) => (a.day < b.day ? -1 : 1)),
    xField: "day",
    yField: "logs",
    seriesField: "category",
    xAxis: { type: "time" },
    legend: {
      itemName: {
        formatter: (text, item) => {
          switch (text) {
            case "logs":
              return "Nb Logs";
            case "errors":
              return "Nb erreurs";
            default:
              break;
          }
        },
      },
    },
  };
  return (
    <React.Fragment>
      <Card className="stats-card" title="Nombre de logs par jour">
        <Line {...config} />
      </Card>
    </React.Fragment>
  );
}

export default Stats;
