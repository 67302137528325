import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  notification,
} from "antd";
import {
  PlusCircleOutlined,
  ReloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import {
  createServer,
  deleteServer,
  updateServer,
} from "../../redux/actions/serverActions";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "antd/lib/checkbox/Checkbox";
import EditableTable from "../utils/EditableTable";
import axios from "../../utils/axios";
import { encode } from "uint8-to-base64";
import moment from "moment";
import { sendAllLicences } from "../../redux/actions/licenceActions";

const pako = require("pako");

function Servers() {
  const [showServerModal, setShowServerModal] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedServer, setSelectedServer] = useState();

  const servers = useSelector((state) => state.servers.servers);
  const modules = useSelector((state) => state.modules.modules);
  const licences = useSelector((state) => state.licences.licences);
  const loading = useSelector((state) => state.servers.loading);
  const keycloak = useSelector((state) => state.keycloak.keycloak);
  const clients = useSelector((state) => state.clients.clients);
  const agents = useSelector((state) => state.servers.agents);

  const [showRenewModal, setShowRenewModal] = useState(false);
  const [renewDate, setRenewDate] = useState(
    moment().endOf("year").add(1, "month")
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleCreateServer = async () => {
    try {
      let values = await form.validateFields();
      values.is_mq_compatible = values.is_mq_compatible ?? false;
      dispatch(createServer(values));
      setShowServerModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSave = (server, old_agent = "") => {
    dispatch(updateServer(server, old_agent));
  };

  const handleDelete = (key) => {
    dispatch(deleteServer(key));
  };

  const handleShowRenewModal = (key) => {
    setShowRenewModal(true);
    setSelectedServer(key);
  };

  const handleShowActionModal = (key) => {
    setShowActionModal(true);
    setSelectedServer(key);
  };

  const handleAgentChange = (value, key) => {
    const server = servers.find((srv) => srv.key === key);
    const old_agent = server.agent_id;
    const modServer = { ...server, agent_id: value };
    handleSave(modServer, old_agent);
  };

  const renewAllLicences = () => {
    const server = servers.find((srv) => srv.key === selectedServer);
    if (!server) {
      notification.error({
        message: "Erreur",
        description: "Une erreur est survenue",
      });
      handleCloseRenewModal();
      return;
    }
    const serverClients = JSON.parse(server.finess);
    const serverLicences = licences.filter((lic) =>
      serverClients.includes(lic.finess_id)
    );
    const updatedLicences = serverLicences.map((licence) => {
      let newLicence = { ...licence };
      const date_expiration = renewDate.format("YYYY-MM-DD");
      const date = date_expiration.replace(/\//g, "").replace(/-/gm, "");
      const client = clients.find((client) => client.key === licence.finess_id);
      const finess = client.finess;

      const server = servers.find((serv) => serv.key === selectedServer);
      const serverName = server.hostname.toUpperCase().replace(/[-\s]/g, "");

      const module = modules.find((mod) => mod.oid === licence.module_id);
      const moduleCode = module.name;

      newLicence.comment = `Renouvellement effectué le ${moment().format(
        "DD/MM/YYYY"
      )} par ${keycloak.idTokenParsed.preferred_username}`;
      newLicence.date_expiration = date_expiration;

      var enc = new TextEncoder(); // always utf-8
      const arr = enc.encode(`--${serverName}|${finess}|${moduleCode}|${date}`);
      const output = pako.deflate(arr);
      const key = encode(output);

      newLicence.cle = key;
      return newLicence;
    });

    dispatch(sendAllLicences(updatedLicences));
    handleCloseRenewModal();
  };

  const handleCloseRenewModal = () => {
    setShowRenewModal(false);
  };

  const handleSendLicences = (key) => {
    const server = servers.find((srv) => srv.key === key);
    const queueName = `${server.hostname.toLowerCase()}-${server.key}`;
    const serverClients = JSON.parse(server.finess_code);
    axios.post("/api/messages", {
      queueName,
      envs: serverClients,
      action: "update-licence",
      modules: [],
    });
  };

  const handleUpdateRemotes = async (key) => {
    const server = servers.find((srv) => srv.key === key);
    const queueName = `${server.hostname.toLowerCase()}-${server.key}`;
    const serverClients = JSON.parse(server.finess_code);
    const res = await axios.post("/api/messages", {
      queueName,
      envs: [serverClients[0]],
      action: "update-remotes",
      modules: [],
    });
    if (res?.data?.correlation_id) {
      notification.info({
        message: "Message envoyée",
        description: `Identifiant du message: ${res.data.correlation_id}`,
      });
    } else {
      notification.error({
        message: "Message",
        description: "Erreur lors de l'envoi du message",
      });
    }
  };

  const serverColumns = [
    {
      key: "oid",
      title: "oid",
      dataIndex: "key",
      width: 60,
    },
    {
      key: "label",
      title: "Hostname",
      dataIndex: "hostname",
      width: 300,
      editable: true,
    },
    {
      key: "comment",
      title: "Nom",
      dataIndex: "comment",
      editable: true,
      width: 300,
    },
    {
      key: "agent",
      title: "Agent",
      width: 300,
      dataIndex: "agent",
      render: (text, record) => (
        <Select
          onChange={(value) => handleAgentChange(value, record.key)}
          style={{ width: 300 }}
          options={agents}
          value={record.agent_id}
        />
      ),
    },
    {
      key: "is_mq_compatible",
      title: "RabbitMq",
      dataIndex: "is_mq_compatible",
      type: "checkbox",
      editable: true,
      width: 80,
      align: "center",
    },
    {
      key: "queue",
      title: "Queue id",
      dataIndex: "queue",
      width: 160,
      render: (text, record) => {
        return record.is_mq_compatible
          ? `${record.hostname.toLowerCase()}-${record.key}`
          : "";
      },
    },
    {
      key: "maj",
      title: "Mises à jour",
      dataIndex: "maj",
      width: 160,
      align: "center",
      render: (text, record) => (
        <Button
          disabled={!record.is_mq_compatible}
          onClick={() => handleShowActionModal(record.key)}
        >
          Afficher
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Modal
        title="Actions"
        visible={showActionModal}
        onCancel={() => setShowActionModal(false)}
      >
        <div className="action-modal">
          <div className="action-modal-row">
            <div className="action-modal-label">Licences</div>
            <div className="action-modal-actions">
              <Tooltip title="Renouveler toutes les licences">
                <Button onClick={() => handleShowRenewModal(selectedServer)}>
                  <ReloadOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="Envoyer les licences sur le serveur (RabbitMq doit être configuré sur le serveur)">
                <Button
                  style={{ marginLeft: 8 }}
                  onClick={() => handleSendLicences(selectedServer)}
                >
                  <UploadOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="action-modal-row">
            <div className="action-modal-label">Dépôts</div>
            <div className="action-modal-actions">
              <Tooltip title="Mettre à jour les dépôts">
                <Button onClick={() => handleUpdateRemotes(selectedServer)}>
                  <ReloadOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Créer un serveur"
        visible={showServerModal}
        onCancel={() => setShowServerModal(false)}
        onOk={handleCreateServer}
        cancelText="Annuler"
        okText="Ajouter"
      >
        <Form form={form}>
          <Form.Item label="Hostname" name="hostname">
            <Input />
          </Form.Item>
          <Form.Item label="Nom" name="comment">
            <Input />
          </Form.Item>
          <Form.Item
            label="Compatible RabbitMQ"
            name="is_mq_compatible"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Renouveler toutes les licences du serveur"
        visible={showRenewModal}
        onCancel={() => setShowRenewModal(false)}
        cancelText="Annuler"
        okText="Confirmer"
        width="40vw"
        onOk={renewAllLicences}
      >
        <Alert
          message="Attention: Vous vous apprêtez à renouveler toutes les licences du serveur"
          type="warning"
          style={{ marginBottom: 24 }}
        />
        <span>Nouvelle date d'expiration:</span>
        <DatePicker
          value={renewDate}
          onChange={(val) => setRenewDate(val)}
          style={{ marginLeft: 12 }}
        />
      </Modal>
      <div className="clients-header">
        <Input
          placeholder="Recherche"
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          onClick={() => setShowServerModal(true)}
          style={{ marginLeft: 8 }}
        >
          <PlusCircleOutlined />
          Ajouter
        </Button>
      </div>
      <div className="scrollable">
        <EditableTable
          columns={serverColumns}
          dataSource={servers.filter((srv) =>
            srv.label.toLowerCase().includes(search.toLocaleLowerCase())
          )}
          size="small"
          onSave={handleSave}
          loading={loading}
          onDelete={handleDelete}
        />
      </div>
    </React.Fragment>
  );
}

export default Servers;
