import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { authenticateUser } from "../../redux/actions/keycloakActions";

function Login(props) {
  const keycloak = useSelector((state) => state.keycloak);
  const ref = useSelector((state) => state.keycloak.ref);
  const dispatch = useDispatch();
  const location = useLocation();

  const tmpRef =
    location.state?.referrer && location.state?.referrer !== "/"
      ? location.state?.referrer
      : "/home";

  useEffect(() => {
    if (!keycloak.authenticated && !keycloak.loading) {
      dispatch(authenticateUser(tmpRef));
    }
  }, [dispatch, keycloak.authenticated, tmpRef, keycloak.loading]);

  if (keycloak.authenticated) {
    return <Redirect to={ref} />;
  }

  return <div>Login en cours</div>;
}

Login.propTypes = {};

export default Login;
