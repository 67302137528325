import React, { useEffect, useState } from "react";

import { Button } from "antd";
import moment from "moment";

function DatePresets({ handleChangeDate }) {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    let days = [];
    var tuesday = moment().startOf("month").day("Tuesday");
    let week = 1;
    while (tuesday.isBefore(moment().add(1, "month"))) {
      if (week === 1 && tuesday.date() > 7) {
        tuesday.add(7, "d");
      }
      days.push({
        title: `v${week}`,
        allDay: true,
        start: tuesday.format("YYYY-MM-DD"),
        full: tuesday.clone(),
        display: "background",
        color: "#f39c12",
      });
      tuesday.add(7, "d");
      week = week === 4 ? 1 : week + 1;
    }
    setDates(days.slice(-4));
  }, []);
  return (
    <React.Fragment>
      <div className="date-presets-title">Prochaines vagues</div>
      <div className="dates-presets">
        {dates.map((date, index) => (
          <Button
            key={index}
            className="date-presets-value"
            onClick={() => handleChangeDate(date.full)}
          >
            {date.title}
          </Button>
        ))}
      </div>
    </React.Fragment>
  );
}

export default DatePresets;
