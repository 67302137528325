import { Button, Select, Tooltip } from "antd";
import { CheckCircleOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import React, { useState } from "react";

import ErrorTooltip from "./ErrorTooltip";
import PropTypes from "prop-types";
import axios from "../../utils/axios";

function LogLineError({ record, errors }) {
  const [errorIsModified, setErrorIsModified] = useState(
    record.error_code && !record.error_confirmed
  );
  const [selectedError, setSelectedError] = useState(record.error_oid);

  const handleErrorChange = (value) => {
    setErrorIsModified(true);
    setSelectedError(value);
  };

  const handleSaveError = async () => {
    let bodyFormData = new FormData();
    bodyFormData.append("error", selectedError);
    await axios.post("/api/logs/" + record.oid, bodyFormData);
    setErrorIsModified(false);
  };

  const options = errors.map((err) => (
    <Select.Option
      key={err.oid}
      value={err.oid}
      label={`${err.code} - ${err.texte}`}
    >
      <Tooltip title={`${err.code} - ${err.texte}`}>
        <span
          style={{
            color:
              err.code === record.error_code && !record.error_confirmed
                ? "#f39c12"
                : "rgba(0, 0, 0, 0.85)",
          }}
        >{`${err.code} - ${err.texte}`}</span>
      </Tooltip>
    </Select.Option>
  ));

  return (
    <div style={{ display: "flex", minWidth: "200px" }}>
      <Button type="link">
        <Tooltip
          title={<ErrorTooltip errors={errors} selectedError={selectedError} />}
        >
          <InfoCircleTwoTone />
        </Tooltip>
      </Button>
      <Select
        style={{ width: "350px" }}
        showSearch
        defaultValue={record.error_oid}
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={selectedError}
        onChange={handleErrorChange}
      >
        {options}
      </Select>
      <Button
        disabled={!errorIsModified}
        style={{ marginLeft: "8px" }}
        onClick={handleSaveError}
      >
        <CheckCircleOutlined twoToneColor="#52c41a" />
      </Button>
    </div>
  );
}

LogLineError.propTypes = {
  record: PropTypes.object,
  errors: PropTypes.array,
};

export default LogLineError;
