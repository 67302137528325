import {
  ADD_CONTACT,
  SET_CONTACTS,
  SET_CONTACTS_LOADING,
  UPDATE_CONTACT,
} from "../actions/contactActions";

const INITIAL_STATE = {
  contacts: {},
  loading: false,
  latest: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.contacts,
      };

    case ADD_CONTACT:
      return {
        ...state,
        contacts: { ...state.contacts, [action.contact.key]: action.contact },
      };

    case SET_CONTACTS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case UPDATE_CONTACT:
      const newData = {
        ...state.contacts,
        [action.contact.key]: action.contact,
      };
      return {
        ...state,
        contacts: newData,
      };

    default:
      return state;
  }
};

export default reducer;
