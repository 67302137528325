import { Button, Form, Input, Modal, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
  createIssue,
  createLogIssue,
  getFields,
  getProjects,
  getSubTypes,
  getUsers,
} from "../../redux/actions/jiraActions";
import { useDispatch, useSelector } from "react-redux";

import { getClients } from "../../redux/actions/clientActions";
import { getListLogs } from "../../redux/actions/logsActions";
import { getServers } from "../../redux/actions/serverActions";

function List() {
  const dispatch = useDispatch();
  const logs = useSelector((state) => state.logs.list);
  const projects = useSelector((state) => state.jira.projects);
  const types = useSelector((state) => state.jira.types);
  const users = useSelector((state) => state.jira.users);
  const fields = useSelector((state) => state.jira.fields);
  const clients = useSelector((state) => state.clients.clients);
  const servers = useSelector((state) => state.servers.servers);
  const loading = useSelector((state) => state.jira.loading);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [extraFields, setExtraFields] = useState([]);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getListLogs());
    dispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    if (!clients.length) {
      dispatch(getClients());
    }
  }, [dispatch, clients]);

  useEffect(() => {
    if (!servers.length) {
      dispatch(getServers());
    }
  }, [dispatch, servers]);

  useEffect(() => {
    const treeLogs = logs.reduce((prev, curr) => {
      let server = prev.find((srv) => srv.hostname === curr.hostname);
      if (server) {
        let client = server.children.find(
          (child) => child.filename === curr.filename
        );
        if (client) {
          client.children.push({
            ...curr,
            envs: JSON.parse(curr.envs),
            issues: JSON.parse(curr.issues).filter((iss) => iss),
            logs: JSON.parse(curr.logs),
          });
        } else {
          client = {
            hostname: curr.hostname,
            filename: curr.filename,
            key: curr.key,
            count: curr.count,
            children: [],
          };
          server.children.push(client);
        }
      } else {
        server = {
          hostname: curr.hostname,
          key: curr.key,
          count: curr.count,
          children: [],
        };
        prev.push(server);
      }
      return prev;
    }, []);
    setData(treeLogs);
  }, [logs]);

  useEffect(() => {
    const formFields = form.getFieldsValue();
    const keys = Object.keys(formFields);
    const requiredKeys = fields.map((field) => field.fieldId);
    const missingKeys = requiredKeys.filter(
      (key) => keys.findIndex((reqKey) => reqKey === key) === -1
    );
    const fullMissingFields = fields.filter((field) =>
      missingKeys.includes(field.fieldId)
    );
    setExtraFields(fullMissingFields);
  }, [fields, form]);

  const handleCreateIssue = async () => {
    const values = await form.validateFields();
    setSubmitLoading(true);
    let data = {
      fields: {
        project: {
          key: values.project,
        },
        summary: values.summary,
        description: values.description,
        issuetype: {
          id: values.issuetype,
        },
        assignee: {
          name: values.assignee,
        },
        customfield_10700: values.customfield_10700,
      },
    };
    if (extraFields.length) {
      extraFields.forEach((field) => {
        data.fields[field] = values[field];
      });
    }
    const issue = await createIssue(data);
    dispatch(createLogIssue(selectedRecord.logs[0], issue));
    setSubmitLoading(false);
    setShowModal(false);
  };

  const handleNewIssue = (record) => {
    setSelectedRecord(record);
    form.resetFields();
    setShowModal(true);
    dispatch(getSubTypes("EX"));
    dispatch(getUsers("EX"));
    form.setFieldsValue({
      summary: `Erreur de traitement sur ${record.filename} (${record.envs.join(
        ","
      )}) ${record.count} occurrences`,
      description: record.message,
      customfield_10700: record.envs
        .map((env) => {
          const fullEnv = clients.find(
            (searchEnv) =>
              searchEnv.code_client_cti === env &&
              searchEnv.hostname === record.hostname
          );
          if (!fullEnv) {
            return env;
          }
          const group = fullEnv.group_name ? `[${fullEnv.group_name}]` : "";
          return `${fullEnv.code_client_cti ?? "???"} - ${group} ${
            fullEnv.raison_sociale ?? "???"
          } (${fullEnv.finess})`;
        })
        .join("###"),
    });
  };

  const handleProjectChange = (val) => {
    form.setFieldsValue({ issuetype: null });
    dispatch(getSubTypes(val));
    dispatch(getUsers(val));
  };

  const handleSubtypeChange = (val) => {
    const project = form.getFieldValue("project");

    dispatch(getFields(project, val));
  };

  const columns = [
    {
      title: "Serveur",
      dataIndex: "hostname",
      key: "hostname",
      resizable: true,
      width: 200,
      render: (text, record) => {
        const server = servers.find((srv) => srv.hostname === record.hostname);
        if (server) {
          return `${server.comment} - ${server.hostname}`;
        } else {
          return text;
        }
      },
    },
    {
      title: "Job",
      dataIndex: "filename",
      key: "filename",
      width: 200,
      ellipsis: true,
    },
    {
      title: "Environnement(s)",
      dataIndex: "env",
      key: "client",
      width: 100,
      render: (text, record) => {
        const envs = record.envs ?? [];
        return envs.map((env) => (
          <Tag color="red" key={env}>
            {env}
          </Tag>
        ));
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: 300,
      ellipsis: true,
    },
    {
      title: "Nombre",
      dataIndex: "count",
      key: "count",
      width: 50,
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      align: "center",
      render: (text, record) => {
        if (!record.envs) {
          return null;
        } else if (record.issues && record.issues.length) {
          return (
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://jira.ctisante.com/browse/${record.issues[0]}`}
            >
              <Button>{record.issues[0]}</Button>
            </a>
          );
        } else {
          return (
            <Button type="primary" onClick={() => handleNewIssue(record)}>
              Créer ticket JIRA
            </Button>
          );
        }
      },
    },
  ];

  return (
    <React.Fragment>
      <Modal
        title="Créer un ticket JIRA"
        visible={showModal}
        width="50vw"
        okText="Envoyer"
        okButtonProps={{ loading: submitLoading }}
        onOk={handleCreateIssue}
        onCancel={() => setShowModal(false)}
      >
        <Form form={form}>
          <Form.Item
            name="project"
            label="Projet"
            initialValue="EX"
            required
            rules={[{ required: true, message: "Ce champ est obligatoire" }]}
          >
            <Select
              onChange={handleProjectChange}
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {projects.map((project) => (
                <Select.Option
                  value={project.key}
                  label={project.name}
                  key={project.key}
                >
                  <div>
                    <img
                      src={project.avatarUrls["16x16"]}
                      height="16px"
                      style={{ marginRight: 8 }}
                      alt={project.name}
                    />
                    {project.name}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="issuetype"
            label="Type"
            required
            rules={[{ required: true, message: "Ce champ est obligatoire" }]}
          >
            <Select
              onChange={handleSubtypeChange}
              loading={loading}
              disabled={loading}
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {types.map((type) => (
                <Select.Option
                  value={type.id}
                  label={type.name}
                  key={type.name}
                >
                  <div>
                    <img
                      src={type.iconUrl}
                      height="16px"
                      style={{ marginRight: 8 }}
                      alt={type.name}
                    />
                    {type.name}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="assignee"
            label="Responsable"
            required
            rules={[{ required: true, message: "Ce champ est obligatoire" }]}
          >
            <Select
              loading={loading}
              disabled={loading}
              style={{ width: "100%" }}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users.map((usr) => (
                <Select.Option value={usr.name} label={usr.name} key={usr.name}>
                  <div>
                    <img
                      src={usr.avatarUrls["16x16"]}
                      height="16px"
                      style={{ marginRight: 8 }}
                      alt={usr.displayName}
                    />
                    {usr.displayName}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="summary"
            label="Titre"
            required
            rules={[{ required: true, message: "Ce champ est obligatoire" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            required
            rules={[{ required: true, message: "Ce champ est obligatoire" }]}
          >
            <Input.TextArea autoSize={{ minRows: 6 }} />
          </Form.Item>
          <Form.Item name="customfield_10700" hidden>
            <Input />
          </Form.Item>
          {extraFields.map((field) => (
            <Form.Item
              name={field.fieldId}
              label={field.name}
              key={field.fieldId}
            >
              <Input />
            </Form.Item>
          ))}
        </Form>
      </Modal>
      <h2>Liste des erreurs survenues dans les 36 dernières heures</h2>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={!data.length}
        expandable={{ defaultExpandAllRows: true }}
      />
    </React.Fragment>
  );
}

export default List;
