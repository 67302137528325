import React, { useEffect, useRef, useState } from "react";
import { scaleBand, scaleLinear } from "d3-scale";

import axios from "../../utils/axios";
import moment from "moment";
import { select } from "d3-selection";
import useResize from "../../hooks/useResize";

function GroupMinimap({ group, list }) {
  const ref = useRef();
  const mapRef = useRef();
  const [mapData, setMapData] = useState([]);
  const size = useResize(mapRef);

  useEffect(() => {
    setMapData([]);
    const getData = async () => {
      let url = "";
      if (list) {
        url = `/api/groupminimap/${group}?list=true`;
      } else {
        url = `/api/groupminimap/${group}`;
      }
      const res = await axios.get(url);
      setMapData(res.data);
    };
    if (group) {
      getData();
    }
  }, [group, list]);

  useEffect(() => {
    select(ref.current).selectAll("*").remove();
    if (mapData.length) {
      const fullWidth = size?.width ?? mapRef.current.offsetWidth;
      const fullHeight = size?.height ?? mapRef.current.offsetHeight;
      const svg = select(ref.current).attr("viewBox", [
        0,
        0,
        fullWidth,
        fullHeight,
      ]);

      const nodeWidth = Math.min((fullWidth - 15 * 3) / 15, 15);
      const nodeHeight = nodeWidth * 4;

      const color = (node) => {
        const colors = ["#73d13d", "#ffc53d", "#ff4d4f", "#A9A9A9"];
        return colors[Number(node.status_id)];
      };
      const data = [...mapData].reverse();

      var tooltip = select(".tooltip")
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("z-index", "10");

      const yScale = scaleLinear().range([nodeHeight, 0]).domain([0, 1]);
      const xScale = scaleBand()
        .range([0, fullWidth])
        .domain(data.map((s, i) => i))
        .paddingInner(0.2);

      const nodes = svg
        .selectAll()
        .data(data)
        .enter()
        .append("rect")
        .attr("x", (s, i) => xScale(i))
        .attr("width", nodeWidth)
        .attr("rx", 5)
        .attr("ry", 5)
        .attr("height", (d) => nodeHeight - yScale(0))
        .attr("y", yScale(0))
        .style("fill", (d) => color(d));

      svg
        .append("text")
        .attr("x", 0)
        .attr("y", nodeHeight + 19)
        .style("font-size", "10px")
        .style("fill", "white")
        .text("J-15");

      svg
        .append("text")
        .attr("x", fullWidth / 2)
        .attr("y", nodeHeight + 19)
        .style("font-size", "14px")
        .style("fill", "white")
        .attr("text-anchor", "middle")
        .text("Historique");

      svg
        .append("text")
        .attr("x", fullWidth - 52)
        .attr("y", nodeHeight + 19)
        .style("font-size", "10px")
        .style("fill", "white")
        .text("Aujourd'hui");

      svg
        .append("line")
        .style("stroke", "lightgrey")
        .style("stroke-width", 1)
        .attr("x1", 30)
        .attr("y1", nodeHeight + 15)
        .attr("x2", fullWidth / 2 - 40)
        .attr("y2", nodeHeight + 15);

      svg
        .append("line")
        .style("stroke", "lightgrey")
        .style("stroke-width", 1)
        .attr("x1", fullWidth / 2 + 40)
        .attr("y1", nodeHeight + 15)
        .attr("x2", fullWidth - 60)
        .attr("y2", nodeHeight + 15);

      nodes
        .on("click", (e, d) => console.log(d, group))
        .on("mouseover", function (d) {
          select(this).style("opacity", 0.7);
          return tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (e, node) {
          return tooltip
            .html(
              `<div>Nuit du ${moment(node.date).format("DD/MM")}</div><div>${
                node.errors
              } erreurs</div><div>${node.total} traitements effectués</div>`
            )
            .style("top", e.pageY + 10 + "px")
            .style("left", e.pageX + "px");
        })
        .on("mouseout", function () {
          select(this).style("opacity", 1);
          return tooltip.style("visibility", "hidden");
        });

      nodes
        .transition()
        .duration(450)
        .attr("y", function (d) {
          return yScale(1);
        })
        .attr("height", function (d) {
          return nodeHeight - yScale(1);
        })
        .delay(function (d, i) {
          return i * 5;
        });
    }
  }, [mapData, size, group]);

  return (
    <div ref={mapRef} style={{ width: "100%", height: "100%" }}>
      <svg
        ref={ref}
        style={{
          fill: "rgb(275,242,245)",
          width: "100%",
          height: "100%",
          overflow: "visible",
        }}
      />
      <div className="tooltip" />
    </div>
  );
}

export default GroupMinimap;
