import { Button, Input, Popconfirm, Table, Transfer } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  deleteList,
  getGroupLists,
  getGroups,
  saveList,
} from "../../redux/actions/groupActions";
import { useDispatch, useSelector } from "react-redux";

import Modal from "antd/lib/modal/Modal";

function GroupList() {
  const groups = useSelector((state) => state.groups.groups);
  const lists = useSelector((state) => state.groups.lists);
  const loading = useSelector((state) => state.groups.loading);
  const [selectedId, setSelectedId] = useState(-1);
  const [listName, setListName] = useState("");
  const [listGroups, setListGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getGroupLists());
  }, [dispatch]);

  const handleAddList = () => {
    setListName("");
    setSelectedId(-1);
    setListGroups([]);
    setShowModal(true);
  };

  const handleSetList = (record) => {
    setSelectedId(record.oid);
    setListName(record.name);
    setListGroups(JSON.parse(record.group_id));
    setShowModal(true);
  };

  const handleSaveList = () => {
    dispatch(saveList({ oid: selectedId, name: listName, groups: listGroups }));
    setShowModal(false);
  };

  const handleAddGroup = (keys, dir) => {
    if (dir === "right") {
      setListGroups(keys);
    } else {
      setListGroups([...listGroups].filter((key) => keys.includes(key)));
    }
  };

  const handleDeleteList = (record) => {
    dispatch(deleteList(record));
  };

  const columns = [
    {
      key: "oid",
      title: "oid",
      dataIndex: "oid",
      width: 50,
    },
    {
      key: "name",
      title: "Nom",
      dataIndex: "name",
      width: 100,
    },
    {
      key: "groups",
      title: "Groupes",
      dataIndex: "group_id",
      width: 200,
      ellipsis: true,
      render: (text, record) => {
        const groupId = JSON.parse(record.group_id);
        const fullGroups = groups.filter((grp) => groupId.includes(grp.oid));
        return fullGroups.map((grp) => grp.name).join(" - ");
      },
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      width: 50,
      render: (text, record) => (
        <>
          <Button onClick={() => handleSetList(record)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Etes vous sur de vouloir supprimer cette liste ?"
            onConfirm={() => handleDeleteList(record)}
          >
            <Button style={{ marginLeft: 8 }} type="danger">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Modal
        title="Modifier"
        okText="Modifier"
        cancelText="Annuler"
        onOk={handleSaveList}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        width="80vw"
      >
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          <span style={{ marginRight: 8, fontWeigh: 600 }}>
            Nom de la liste:{" "}
          </span>
          <Input
            value={listName}
            onChange={(e) => setListName(e.target.value)}
          />
        </div>
        <Transfer
          dataSource={groups.map((grp) => ({
            title: grp.label,
            description: grp.label,
            key: grp.oid,
          }))}
          targetKeys={listGroups}
          onChange={handleAddGroup}
          size="small"
          titles={["Disponibles", "Selectionés"]}
          render={(item) => item.title}
        />
      </Modal>
      <div className="clients-header">
        <Button onClick={handleAddList} icon={<PlusOutlined />}>
          Ajouter
        </Button>
      </div>
      <div className="scrollable">
        <Table
          loading={loading}
          dataSource={lists}
          columns={columns}
          pagination={false}
          size="small"
        />
      </div>
    </React.Fragment>
  );
}

export default GroupList;
