import axios from "../../utils/axios";

export const SET_ACCESS = "SET_ACCESS";
export const ADD_ACCESS = "ADD_ACCESS";
export const UPDATE_ACCESS = "UPDATE_ACCESS";
export const SET_ACCESS_LOADING = "SET_ACCESS_LOADING";

export const setAccess = (access) => {
  return {
    type: SET_ACCESS,
    access,
  };
};

export const addAccess = (access) => {
  return {
    type: ADD_ACCESS,
    access,
  };
};

export const setAccessLoading = (loading) => {
  return {
    type: SET_ACCESS_LOADING,
    loading,
  };
};

export const updateAccess = (access) => {
  return {
    type: UPDATE_ACCESS,
    access,
  };
};

export const getAccess = () => {
  return async function (dispatch, getState) {
    dispatch(setAccessLoading(true));
    const res = await axios.get("/api/access");
    if (res.data && res.data.length) {
      dispatch(setAccess(res.data));
    }
    dispatch(setAccessLoading(false));
  };
};

export const saveAccess = (record) => {
  return async function (dispatch, getState) {
    dispatch(setAccessLoading(true));
    await axios.post("/api/access", { record });
    dispatch(getAccess());
  };
};

export const deleteAccess = (record) => {
  return async function (dispatch, getState) {
    dispatch(setAccessLoading(true));
    await axios.delete("/api/access/" + record.oid);
    dispatch(getAccess());
  };
};
