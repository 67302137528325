import {
  ADD_ACCESS,
  SET_ACCESS,
  SET_ACCESS_LOADING,
  UPDATE_ACCESS,
} from "../actions/accessActions";

const INITIAL_STATE = {
  access: [],
  loading: false,
  latest: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACCESS:
      return {
        ...state,
        access: action.access,
      };

    case ADD_ACCESS:
      return {
        ...state,
        access: [...state.access, action.access],
      };

    case UPDATE_ACCESS:
      const newData = [...state.access];
      let access = newData.findIndex((acc) => acc.key === action.access.key);
      const item = newData[access];
      newData.splice(access, 1, { ...item, ...action.access });
      return {
        ...state,
        access: newData,
      };

    case SET_ACCESS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    default:
      return state;
  }
};

export default reducer;
