import GroupList from "./GroupList";
import JobFiness from "./JobFiness";
import JobStrategy from "./JobStrategy";
import React from "react";
import Repos from "./Repos";
//import Roles from "./Roles";
import Strategy from "./Strategy";
import { Tabs } from "antd";

const { TabPane } = Tabs;

function Settings(props) {
  return (
    <React.Fragment>
      <Tabs>
        <TabPane tab="Stratégies" key="strategy">
          <Strategy />
        </TabPane>
        <TabPane tab="Stratégie par job" key="jobStrategy">
          <JobStrategy />
        </TabPane>
        <TabPane tab="Jobs par environnement" key="jobFiness">
          <JobFiness />
        </TabPane>
        <TabPane tab="Listes" key="lists">
          <GroupList />
        </TabPane>
        {/* <TabPane tab="Roles utilisateurs" key="roles">
          <Roles />
        </TabPane> */}
        <TabPane tab="Gestion dépôts" key="repos">
          <Repos />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}

export default Settings;
