import {
  SET_CLIENTS,
  SET_CLIENTS_ERRORS,
  SET_CLIENTS_LOADING,
} from "../actions/clientActions";

const INITIAL_STATE = {
  clients: [],
  errors: [],
  loading: false,
  latest: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.clients,
      };

    case SET_CLIENTS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case SET_CLIENTS_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    default:
      return state;
  }
};

export default reducer;
