import {
  ADD_LICENCE,
  SET_LICENCES,
  SET_LICENCES_LOADING,
  UPDATE_LICENCE,
} from "../actions/licenceActions";

const INITIAL_STATE = {
  licences: [],
  loading: false,
  latest: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LICENCES:
      return {
        ...state,
        licences: action.licences,
      };

    case SET_LICENCES_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case UPDATE_LICENCE:
      const newData = [...state.licences];
      let licence = newData.findIndex((lic) => lic.key === action.licence.key);
      const item = newData[licence];
      newData.splice(licence, 1, { ...item, ...action.licence });
      return {
        ...state,
        licences: newData,
      };

    case ADD_LICENCE:
      return {
        ...state,
        licences: [...state.licences, action.licence],
      };

    default:
      return state;
  }
};

export default reducer;
