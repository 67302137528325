import {
  SET_AUTHENTICATED,
  SET_KEYCLOAK,
  SET_KEYCLOAK_LOADING,
  SET_KEYCLOAK_REF,
} from "../actions/keycloakActions";

const INITIAL_STATE = {
  keycoak: null,
  authenticated: false,
  loading: false,
  ref: "/home",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_KEYCLOAK:
      return {
        ...state,
        keycloak: action.keycloak,
      };

    case SET_KEYCLOAK_REF:
      return {
        ...state,
        ref: action.ref,
      };

    case SET_KEYCLOAK_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
      };

    default:
      return state;
  }
};

export default reducer;
