import {
  SET_BRANCHES,
  SET_CHERRIES,
  SET_COMMIT,
  SET_GIT_LOADING,
} from "../actions/gitActions";

const INITIAL_STATE = {
  branches: [],
  cherries: [],
  loading: false,
  commit: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BRANCHES:
      return {
        ...state,
        branches: action.branches,
      };

    case SET_CHERRIES:
      return {
        ...state,
        cherries: action.cherries,
      };

    case SET_GIT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case SET_COMMIT:
      return {
        ...state,
        commit: action.commit,
      };

    default:
      return state;
  }
};

export default reducer;
