import axios from "../../utils/axios";

export const SET_USERS = "SET_USERS";
export const SET_USERS_LOADING = "SET_USERS_LOADING";

export const setUsers = (users) => {
  return {
    type: SET_USERS,
    users,
  };
};

export const setUsersLoading = (loading) => {
  return {
    type: SET_USERS_LOADING,
    loading,
  };
};

export const getUsers = () => {
  return async function (dispatch, getState) {
    dispatch(setUsersLoading(true));
    const res = await axios.get("/api/users");
    if (res.data && Array.isArray(res.data)) {
      dispatch(setUsers(res.data));
    }
    dispatch(setUsersLoading(false));
  };
};
