import { SET_VAGUES, SET_VAGUES_LOADING } from "../actions/vagueActions";

const INITIAL_STATE = {
  vagues: [],
  loading: false,
  latest: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_VAGUES:
      return {
        ...state,
        vagues: action.vagues,
      };

    case SET_VAGUES_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    default:
      return state;
  }
};

export default reducer;
