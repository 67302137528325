import {
  SET_JOBFINESS,
  SET_JOBFINESS_LOADING,
} from "../actions/jobFinessActions";

const INITIAL_STATE = {
  jobFiness: [],
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_JOBFINESS:
      return {
        ...state,
        jobFiness: action.jobFiness,
      };

    case SET_JOBFINESS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    default:
      return state;
  }
};

export default reducer;
