import {
  SET_MODULES,
  SET_MODULES_LOADING,
  UPDATE_MODULE,
} from "../actions/moduleActions";

const INITIAL_STATE = {
  modules: [],
  loading: false,
  latest: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MODULES:
      return {
        ...state,
        modules: action.modules,
      };

    case SET_MODULES_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case UPDATE_MODULE:
      const newData = [...state.modules];
      let module = state.modules.findIndex(
        (mod) => mod.key === action.module.key
      );
      const item = newData[module];
      newData.splice(module, 1, { ...item, ...action.module });
      return {
        ...state,
        modules: newData,
      };

    default:
      return state;
  }
};

export default reducer;
