import PropTypes from "prop-types";
import Rds from "./Rds";
import React from "react";
import RemoteClients from "./RemoteClients";
import { Tabs } from "antd";
import Vpn from "./Vpn";

const { TabPane } = Tabs;

function Remote({ resourceAccess, username, token }) {
  return (
    <Tabs>
      <TabPane tab="VPN" key="0">
        <Vpn resourceAccess={resourceAccess} username={username} />
      </TabPane>
      <TabPane tab="RDS" key="1">
        <Rds
          token={token}
          resourceAccess={resourceAccess}
          username={username}
        />
      </TabPane>
      <TabPane tab="Clients" key="2">
        <RemoteClients token={token} />
      </TabPane>
    </Tabs>
  );
}

Remote.propTypes = {
  resourceAccess: PropTypes.object,
  username: PropTypes.string,
  token: PropTypes.string,
};

export default Remote;
