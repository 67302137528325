import "./Home.css";

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { Divider, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { getRestarts, getStats } from "../../../redux/actions/statsActions";
import { useDispatch, useSelector } from "react-redux";

import { Facebook } from "react-content-loader";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import { Link } from "react-router-dom";
import dayGridPlugin from "@fullcalendar/daygrid";
import frLocale from "@fullcalendar/core/locales/fr";
import { getServers } from "../../../redux/actions/serverActions";
import moment from "moment";

const Ok = () => (
  <CheckCircleTwoTone twoToneColor="#7cd992" style={{ fontSize: 48 }} />
);
const Warning = () => (
  <InfoCircleTwoTone twoToneColor="#f7e463" style={{ fontSize: 48 }} />
);
const Error = () => (
  <CloseCircleTwoTone twoToneColor="#eb6060" style={{ fontSize: 48 }} />
);

function Home(props) {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const stats = useSelector((state) => state.stats.stats);
  const restarts = useSelector((state) => state.stats.restarts);
  const servers = useSelector((state) => state.servers.servers);
  const loading = useSelector((state) => state.stats.loading);
  const keycloak = useSelector((state) => state.keycloak);

  const ready = !loading && stats;

  useEffect(() => {
    if (!stats) {
      dispatch(getStats());
      dispatch(getServers());
      dispatch(getRestarts());
    } else {
      const disengaged = stats.disengaged.map((event) => ({
        title: `Reprise ${event.code_client_cti} - ${event.groupe} - ${event.comment}`,
        allDay: true,
        start: event.ignore_until,
        color: "#eb6060",
      }));
      let days = [];
      var tuesday = moment().add(-1, "month").startOf("month").day("Tuesday");
      let week = 1;
      while (tuesday.isBefore(moment().add(6, "month"))) {
        if (week === 1 && tuesday.date() > 7) {
          tuesday.add(7, "d");
        }
        days.push({
          title: `v${week}`,
          allDay: true,
          start: tuesday.format("YYYY-MM-DD"),
          display: "background",
          color: "#f39c12",
        });
        if (tuesday.date() >= 1 && tuesday.date() <= 7) {
          days.push({
            title: `v${tuesday.format("YY")}.${tuesday.month() + 1}`,
            allDay: true,
            start: tuesday.format("YYYY-MM-DD"),
            color: "#f39c12",
            display: "background",
          });
        }
        tuesday.add(7, "d");
        week = week === 4 ? 1 : week + 1;
      }
      setEvents([...disengaged, ...days]);
    }
  }, [dispatch, stats]);

  const updateErrors = stats
    ? [
        ...new Set(
          stats.errors
            .filter((err) => err.tag_ok === 2)
            .map((error) => error.client)
        ),
      ]
    : 0;
  const licErrors = stats ? stats.errors.filter((err) => !err.lic_ok) : 0;

  return keycloak.keycloak ? (
    <div className="home">
      <div className="home-header">
        <h1>Accueil - Bienvenue {keycloak.keycloak.tokenParsed.name}</h1>
        <Divider />
      </div>
      <div className="home-widgets">
        <div className="home-card first-row">
          <div className="card-title">Mises à jour</div>
          <div className="card-content">
            {!ready ? (
              <Facebook width={100} />
            ) : (
              <div className="card-content">
                <div className="card-value">
                  {<Link to="/clients/errors">{updateErrors.length}</Link>}
                </div>
                <div className="card-icon">
                  {updateErrors.length ? <Error /> : <Ok />}
                </div>
                <div className="card-desc">
                  Environnement(s) en erreur de mise à jour
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="home-card first-row">
          <div className="card-title">Licences</div>
          <div className="card-content">
            {!ready ? (
              <Facebook width={100} />
            ) : (
              <div className="card-content">
                <div className="card-value">
                  <Link to="/clients/errors">{licErrors.length}</Link>
                </div>
                <div className="card-icon">
                  {licErrors.length ? <Error /> : <Ok />}
                </div>
                <div className="card-desc">
                  Licence(s) différentes entre le serveur et exploit
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="home-card first-row">
          <div className="card-title">Environnements</div>
          <div className="card-content">
            {!ready ? (
              <Facebook width={100} />
            ) : (
              <div className="card-content">
                <div className="card-value">{stats.disengaged.length}</div>
                <div className="card-icon">
                  {stats.disengaged.length ? <Warning /> : <Ok />}
                </div>
                <div className="card-desc">Environnement(s) débrayé(s)</div>
              </div>
            )}
          </div>
        </div>
        <div className="home-card first-row">
          <div className="card-title">Serveurs</div>
          <div className="card-content">
            {!ready ? (
              <Facebook width={100} />
            ) : (
              <div className="card-content">
                <div className="card-value">
                  <Tooltip
                    title={stats.servers.map((server) => (
                      <div>
                        {server.hostname}: {server.comment}
                      </div>
                    ))}
                  >
                    {stats.servers.length}
                  </Tooltip>
                </div>
                <div className="card-icon">
                  {stats.servers.length ? <Error /> : <Ok />}
                </div>
                <div className="card-desc">
                  Serveur(s) n'ayant pas donné signe de vie
                </div>
                <div
                  className="card-desc desc-warning"
                  style={{
                    color: stats.unfollowed.length ? "#f39c12" : "green",
                  }}
                >
                  {stats.unfollowed.length} serveur(s) non suivis sur{" "}
                  {servers ? servers.length : 0} au total
                </div>
                <div
                  className="card-desc desc-warning"
                  style={{
                    color: restarts.length ? "#eb6060" : "green",
                  }}
                >
                  <Tooltip
                    title={restarts.map((srv) => (
                      <div>
                        {srv.hostname} - {srv.comment} - Redémarré à{" "}
                        {srv.started} UTC
                      </div>
                    ))}
                  >
                    {restarts.length} serveur(s) ayant redémarré dans la nuit
                  </Tooltip>
                </div>
                <div
                  className="card-desc desc-warning"
                  style={{
                    color: stats.agents.totalInactive ? "#eb6060" : "green",
                  }}
                >
                  <Tooltip
                    title={stats.agents.list
                      .filter((agent) => !agent.active)
                      .map((agent) => (
                        <div>{agent?.local_metadata?.host?.hostname}</div>
                      ))}
                  >
                    {stats.agents.totalInactive} Agents inactifs
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="home-card second-row">
          <div className="card-title">
            Nuit du {moment().add(-1, "days").format("DD/MM")}
          </div>
          <div className="card-content">
            {!ready ? (
              <Facebook width={400} />
            ) : (
              <div className="current-night">
                <div className="stats-jobs">
                  {stats.jobs} Traitements exécutés
                </div>
                <div className="stats-jobs-error">
                  {Math.round((stats.jobsError / stats.jobs) * 100)} % en erreur
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="home-card third-row">
          <div className="card-content" style={{ marginTop: 8 }}>
            <div className="calendar">
              <FullCalendar
                locale={frLocale}
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                dayMaxEvents
                height={600}
                events={events}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,dayGridWeek",
                }}
                aspectRatio={1}
                eventClassNames={(event) =>
                  event.event.title.match(/v[0-9]*\.[0-9]*/gm)
                    ? "first-background-event"
                    : "second-background-event"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

Home.propTypes = {};

export default Home;
