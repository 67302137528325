import {
  ADD_SERVER,
  SET_AGENTS,
  SET_SERVER,
  SET_SERVERS,
  SET_SERVERS_LOADING,
} from "../actions/serverActions";

const INITIAL_STATE = {
  servers: [],
  agents: [],
  loading: false,
  latest: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SERVERS:
      return {
        ...state,
        servers: action.servers,
      };

    case SET_AGENTS:
      return {
        ...state,
        agents: action.agents,
      };

    case SET_SERVERS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case ADD_SERVER:
      return {
        ...state,
        servers: [action.server, ...state.servers],
      };

    case SET_SERVER:
      const newData = [...state.servers];
      let server = newData.findIndex((srv) => srv.key === action.server.key);
      const item = newData[server];
      newData.splice(server, 1, { ...item, ...action.server });
      return {
        ...state,
        servers: newData,
      };

    default:
      return state;
  }
};

export default reducer;
