import React, { useEffect, useState } from "react";

import { Facebook } from "react-content-loader";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import { providers } from "../Clients/Finess";

const { TabPane } = Tabs;

const defaultModules = {
  activite: { label: "Activite", module: "iCTI_activite" },
  rhs_rsf: { label: "SSR", module: "iCTI_pmsissr" },
  rss_rsf: { label: "PMSI", module: "iCTI_pmsi" },
  rss_rsf_en_cours: { label: "PMSI - Fil de l'eau", module: "iCTI_pmsi" },
  compta: { label: "Compta", module: "iCTI_compta" },
  rh: { label: "RH", module: "iCTI_rh" },
  planning: { label: "Planning", module: "iCTI_rh" },
  eco: { label: "Eco", module: "iCTI_eco" },
  dpi: { label: "Dpi", module: "iCTI_dpi" },
  extraction: { label: "Extraction", module: "iCTI_extraction" },
  kiwee: { label: "Extraction Kiwee", module: "iCTI_kiwee" },
  fae: { label: "Extraction FAE", module: "iCTI_fae" },
};

function Environment({ stats, ready }) {
  const [modules, setModules] = useState(["Total"]);

  useEffect(() => {
    if (stats?.envs) {
      let tmpModules = [
        ...new Set(
          stats.envs.reduce(
            (prev, curr) => [...prev, ...Object.keys(curr.traitements_status)],
            []
          )
        ),
      ];
      tmpModules = tmpModules.filter((mod) =>
        Object.keys(defaultModules).includes(mod)
      );
      setModules(tmpModules);
    }
  }, [stats]);

  return (
    <Tabs>
      <TabPane tab="Tous" key="all">
        <div style={{ overflow: "scroll" }}>
          {!ready ? (
            <Facebook width={400} />
          ) : (
            <div className="group-envs">
              {stats.envs
                .sort((a, b) => (b.status_id < a.status_id ? -1 : 1))
                .map((env) => {
                  let classname = "group-env";
                  switch (env.status_id) {
                    case 0:
                      classname += " group-env-success";
                      break;
                    case 1:
                      classname += " group-env-warning";
                      break;
                    case 2:
                      classname += " group-env-error";
                      break;
                    default:
                      break;
                  }
                  return (
                    <div className={classname} key={env.code_client_cti}>
                      <div className="env-name">{`${env.code_client_cti} - ${env.raison_sociale}`}</div>
                      <div className="env-server">{env.hostname}</div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </TabPane>
      {modules.map((mod) => (
        <TabPane tab={defaultModules[mod]?.label} key={mod}>
          {!ready ? (
            <Facebook width={400} />
          ) : (
            <div className="group-envs">
              {stats.envs
                .filter((env) =>
                  Object.keys(env.traitements_status).includes(mod)
                )
                .sort((a, b) =>
                  b.traitements_status[mod] < a.traitements_status[mod] ? -1 : 1
                )
                .map((env) => {
                  let classname = "group-env";
                  const traitementsIndex = JSON.parse(
                    env.traitements
                  ).findIndex((job) => job === mod);
                  const statusIndex = JSON.parse(env.status)[traitementsIndex];
                  switch (statusIndex) {
                    case 0:
                      classname += " group-env-success";
                      break;
                    case 1:
                      classname += " group-env-warning";
                      break;
                    case 2:
                      classname += " group-env-error";
                      break;
                    default:
                      break;
                  }
                  return (
                    <div className={classname} key={env.code_client_cti}>
                      <div className="env-name">{`${env.code_client_cti} - ${env.raison_sociale}`}</div>
                      <div className="env-server">{env.hostname}</div>
                      <div className="env-provider">
                        {Object.keys(env.latest_settings).length
                          ? env.latest_settings.ENVIRONMENT[
                              providers[defaultModules[mod].module]
                            ]
                          : ""}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </TabPane>
      ))}
    </Tabs>
  );
}

Environment.propTypes = {
  stats: PropTypes.object,
  ready: PropTypes.bool,
};

export default Environment;
