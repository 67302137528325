import { Button, Form, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  getJobStrategies,
  updateJobStrategy,
} from "../../redux/actions/strategyActions";
import { useDispatch, useSelector } from "react-redux";

import { PlusCircleOutlined } from "@ant-design/icons";

function JobStrategy() {
  const [showStrategyModal, setShowStrategyModal] = useState(false);
  const jobStrategies = useSelector((state) => state.strategies.jobStrategies);
  const jobs = useSelector((state) => state.strategies.jobs);
  const strategies = useSelector((state) => state.strategies.strategies);
  const loading = useSelector((state) => state.strategies.loading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getJobStrategies());
  }, [dispatch]);

  const handleCreateStrategy = async () => {
    try {
      const values = await form.validateFields();
      dispatch(updateJobStrategy(values));
      setShowStrategyModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSave = (value, record) => {
    const modJob = { ...record, strategy_id: value };
    dispatch(updateJobStrategy(modJob));
  };

  const strategyColumns = [
    {
      key: "oid",
      title: "oid",
      dataIndex: "oid",
      width: 60,
    },
    {
      key: "job",
      title: "Job",
      dataIndex: "filename",
      width: 300,
    },
    {
      key: "strategy",
      title: "Stratégie",
      dataIndex: "strategy_id",
      width: 300,
      render: (text, record) => {
        return (
          <Select
            onChange={(val) => handleSave(val, record)}
            value={record.strategy_id}
            options={strategies}
            style={{ width: "100%" }}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Modal
        title="Assigner une stratégie à un job"
        visible={showStrategyModal}
        onCancel={() => setShowStrategyModal(false)}
        onOk={handleCreateStrategy}
        width="50vw"
      >
        <Form form={form}>
          <Form.Item label="Job" name="job_id">
            <Select
              options={jobs}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item label="Strategie" name="strategy_id">
            <Select
              options={strategies}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <div className="clients-header">
        <Button onClick={() => setShowStrategyModal(true)}>
          <PlusCircleOutlined />
          Ajouter
        </Button>
      </div>
      <div className="scrollable">
        <Table
          columns={strategyColumns}
          dataSource={jobStrategies}
          size="small"
          loading={loading}
          pagination={false}
        />
      </div>
    </React.Fragment>
  );
}

export default JobStrategy;
