import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import React, { useEffect } from "react";
import { Table, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getClientErrors } from "../../redux/actions/clientActions";

function Errors() {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.clients.errors);
  const loading = useSelector((state) => state.clients.loading);

  useEffect(() => {
    dispatch(getClientErrors());
  }, [dispatch]);

  const columns = [
    {
      key: "client",
      title: "Client",
      dataIndex: "client",
    },
    {
      key: "module",
      title: "Module",
      dataIndex: "module",
      width: 300,
    },
    {
      key: "maj",
      title: "Mise à jour",
      dataIndex: "tag_ok",
      width: 100,
      align: "center",
      render: (text, record) => {
        switch (record.tag_ok) {
          case 0:
            return <CheckCircleTwoTone twoToneColor="#52c41a" />;

          case 1:
            return (
              <Tooltip title="Le module sera mis à jour ce soir">
                <CloseCircleTwoTone twoToneColor="#f7e463" />
              </Tooltip>
            );

          case 2:
            return (
              <Tooltip title="La version installée ne correspond pas à celle demandée (vague utilisée)">
                <CloseCircleTwoTone twoToneColor="red" />
              </Tooltip>
            );

          default:
            break;
        }
      },
    },
    {
      key: "lic",
      title: "Licence",
      dataIndex: "lic_ok",
      width: 100,
      align: "center",
      render: (text, record) => {
        return record.lic_ok ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <Tooltip title="La licence installée ne correspond pas à celle enregistrée dans le gestionnaire de licences">
            <CloseCircleTwoTone twoToneColor="red" />
          </Tooltip>
        );
      },
    },
    {
      key: "detail",
      title: "Détail maj",
      dataIndex: "#",
      render: (text, record) => {
        if (record.tag_ok === 2) {
          return (
            <>
              <div>
                <span>Tag trouvé: </span>
                <Tag color="red">{record.actual_tag}</Tag>
              </div>
              <div>
                <span>Tag attendu: </span>
                <Tag color="blue">{record.tag}</Tag>
              </div>
            </>
          );
        } else if (record.tag_ok === 1) {
          return "Le module sera mis à jour ce soir";
        }
      },
    },
    {
      key: "detail_lic",
      title: "Détail licence",
      dataIndex: "#",
      render: (text, record) => {
        if (!record.lic_ok) {
          return (
            <>
              <div>
                <span>Licence trouvée: </span>
                <Tag color="red">{record.raw_actual_key}</Tag>
              </div>
              <div>
                <span>Licence attendue: </span>
                <Tag color="blue">{record.raw_key}</Tag>
              </div>
            </>
          );
        }
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="scrollable">
        <Table
          columns={columns}
          dataSource={errors}
          size="small"
          pagination={false}
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
}

export default Errors;
