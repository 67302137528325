import axios from "../../utils/axios";

export const SET_ERRORS = "SET_ERRORS";
export const SET_ERRORS_LOADING = "SET_ERRORS_LOADING";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const ADD_ERROR = "ADD_ERROR";
export const MODIFY_ERROR = "MODIFY_ERROR";

const setErrors = (errors) => {
  return {
    type: SET_ERRORS,
    errors,
  };
};

const setErrorsLoading = (loading) => {
  return {
    type: SET_ERRORS_LOADING,
    loading,
  };
};

const removeError = (key) => {
  return {
    type: REMOVE_ERROR,
    key,
  };
};

export const adderror = (error) => {
  return {
    type: ADD_ERROR,
    error,
  };
};

const modifyError = (error) => {
  return {
    type: MODIFY_ERROR,
    error,
  };
};

export const getErrors = () => {
  return async function (dispatch, getState) {
    dispatch(setErrorsLoading(true));
    const res = await axios.get("/api/errors");
    dispatch(setErrors(res.data));
    dispatch(setErrorsLoading(false));
  };
};

export const deleteError = (key) => {
  return async function (dispatch, getState) {
    await axios.delete("/api/errors/" + key);
    dispatch(removeError(key));
  };
};

export const createError = (row) => {
  return async function (dispatch, getSate) {
    await axios.post("/api/errors", { error: row });
    dispatch(getErrors());
  };
};

export const updateError = (row) => {
  return async function (dispatch, getSate) {
    dispatch(modifyError(row));
    axios.put("/api/errors/" + row.key, { error: row });
  };
};
