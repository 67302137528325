import {
  Button,
  Divider,
  Input,
  Select,
  Spin,
  Switch,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";

import Modal from "antd/lib/modal/Modal";
import PropTypes from "prop-types";
import RdsList from "./RdsList";
import axios from "../../utils/axios";

function Rds({ token, resourceAccess, username }) {
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [selectedVpn, setSelectedVpn] = useState("elsan");
  const [rdsUsername, setRdsUsername] = useState("");
  const [groupByClient, setGroupByClient] = useState(true);

  const [trig, setTrig] = useState("");

  const getData = React.useCallback(async () => {
    const res = await axios.get(
      `/guacamole/api/session/data/mysql/connections?token=${token}`
    );
    if (res.data) {
      let orderedData = Object.values(res.data);
      if (
        !resourceAccess.guacamole.roles.includes("guac-admin") &&
        !resourceAccess.guacamole.roles.includes("guac-exploit")
      ) {
        orderedData = orderedData.filter((conn) => {
          if (conn.parentIdentifier !== "1" && conn.parentIdentifier !== "3") {
            const hasTrig = /-\s([a-z]{3})/.test(conn.name);
            if (
              !hasTrig ||
              (hasTrig && conn.name.match(/-\s([a-z]{3})/)[1] === username)
            ) {
              return true;
            }
            return false;
          }
          return false;
        });
      }

      if (groupByClient) {
        orderedData = orderedData.map((conn) => {
          if (conn.parentIdentifier === "3") {
            conn.parentIdentifier = "2";
          }
          return conn;
        });
      }

      if (resourceAccess.guacamole.roles.includes("guac-exploit")) {
        orderedData = orderedData.filter(
          (conn) => conn.parentIdentifier !== "1"
        );
      }

      if (search !== "") {
        orderedData = orderedData.filter((conn) =>
          conn.name.toLowerCase().includes(search.toLowerCase())
        );
      }

      orderedData = orderedData.sort((a, b) => {
        if (a.parentIdentifier === 0) return -1;
        return a.name.localeCompare(b.name);
      });

      let groupedData = {};

      orderedData.forEach((conn) => {
        const hasTrig = /-\s([a-z]{3})/.test(conn.name);
        if (hasTrig && conn.parentIdentifier !== "1") {
          conn.parentIdentifier = 0;
        }
        groupedData[conn.parentIdentifier] =
          groupedData[conn.parentIdentifier] ?? [];
        groupedData[conn.parentIdentifier].push(conn);
      });

      let finalData = {};
      for (let key in groupedData) {
        let subGroups = {};
        const group = groupedData[key];
        Object.values(group).forEach((conn) => {
          let splitConnName = conn.name.split("-");
          splitConnName.pop();
          let connKey = splitConnName.join("-");

          subGroups[connKey] = subGroups[connKey] ?? [];
          subGroups[connKey].push(conn);
        });
        finalData[key] = subGroups;
      }

      setData(finalData);
    }
  }, [token, resourceAccess.guacamole.roles, search, username, groupByClient]);

  const getGroups = React.useCallback(async () => {
    const res = await axios.get(
      `/guacamole/api/session/data/mysql/connectionGroups?token=${token}`
    );
    if (res.data) {
      const customGroups = [
        { identifier: 0, name: "Mes RDS" },
        ...Object.values(res.data),
      ];
      setGroups(customGroups);
    }
  }, [token]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
    }, 10000);
    return () => clearInterval(interval);
  }, [getData, getGroups]);

  useEffect(() => {
    if (search !== "") {
      let newData = {};
      for (let key in data) {
        newData[key] = {};
        for (let name in data[key]) {
          if (name.toLowerCase().includes(search.toLowerCase())) {
            newData[key][name] = data[key][name];
          }
        }
      }
      setData(newData);
    }
    // eslint-disable-next-line
  }, [search]);

  const elsanTmp = {
    parentIdentifier: "2",
    name: `157 - Elsan - ${trig.toLowerCase()}`,
    protocol: "rdp",
    parameters: {
      port: "1111",
      "ignore-cert": "true",
      "enable-drive": "true",
      hostname: `vpn_elsan_${trig.toLowerCase()}`,
      username: rdsUsername,
      "drive-name": "CTI Share",
      "drive-path": "/drive",
      "server-layout": "fr-fr-azerty",
    },
    attributes: {},
  };

  const handleCreateRds = async () => {
    if (
      trig === username ||
      resourceAccess.guacamole.roles.includes("guac-admin")
    ) {
      setshowModal(false);
      let rdsData = {};
      if (selectedVpn === "elsan") {
        rdsData = elsanTmp;
      }
      const res = await axios.post(
        `/guacamole/api/session/data/mysql/connections?token=${token}`,
        rdsData
      );
      if (res.status === 200) {
        const connData = [
          {
            op: "add",
            path: `/connectionPermissions/${res.data.identifier}`,
            value: "READ",
          },
        ];
        await axios.patch(
          `/guacamole/api/session/data/mysql/userGroups/keycloak-guac-user/permissions?token=${token}`,
          connData
        );
        getData();
      }
    } else {
      notification.error({
        message: "Erreur",
        description: "Vous n'avez pas le droit de créer ce VPN",
      });
    }
  };

  const renderGroups = () => {
    let res = [];
    for (let key in data) {
      if (Object.keys(data[key]).length) {
        const group = groups.find(
          (group) => group.identifier.toString() === key.toString()
        );
        res.push(
          <React.Fragment key={key}>
            <h3>{group?.name}</h3>
            <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
            <RdsList
              data={data[key]}
              group={key}
              resourceAccess={resourceAccess}
            />
          </React.Fragment>
        );
      }
    }
    return res;
  };

  return (
    <>
      <Modal
        title="Créer un RDS"
        onCancel={() => setshowModal(!showModal)}
        onOk={handleCreateRds}
        okText="Créer"
        cancelText="Annuler"
        visible={showModal}
      >
        <div className="form-row">
          <div className="form-label">Type :</div>
          <Select
            value={selectedVpn}
            onChange={(value) => setSelectedVpn(value)}
          >
            <Select.Option value="elsan">Elsan</Select.Option>
          </Select>
        </div>
        <div className="form-row">
          <div className="form-label">Utilisateur :</div>
          <Input
            placeholder="Trigramme cti"
            value={trig}
            onChange={(e) => setTrig(e.target.value)}
          />
        </div>
        <div className="form-row">
          <div className="form-label">Utilisateur RDS :</div>
          <Input
            placeholder="Nom d'utilisateur RDS"
            value={rdsUsername}
            onChange={(e) => setRdsUsername(e.target.value)}
          />
        </div>
      </Modal>
      <div className="vpn-header">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Recherche"
        />
        {(resourceAccess.guacamole.roles.includes("guac-exploit") ||
          resourceAccess.guacamole.roles.includes("guac-admin")) && (
          <div className="exploit-view">
            <span style={{ marginLeft: "8px", fontWeight: 600 }}>
              Regroupement exploit:{" "}
            </span>
            <Switch
              checked={groupByClient}
              onChange={(val) => setGroupByClient(val)}
              style={{ width: "90px" }}
              checkedChildren="Client"
              unCheckedChildren="Catégorie"
            />
          </div>
        )}
        <Button onClick={() => setshowModal(!showModal)} type="primary">
          Créer un RDS
        </Button>
      </div>
      {Object.keys(data).length && Object.keys(groups).length ? (
        <div className="rds-data scrollable">{renderGroups()}</div>
      ) : (
        <Spin
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      )}
    </>
  );
}

Rds.propTypes = {
  token: PropTypes.string,
  resourceAccess: PropTypes.object,
  username: PropTypes.string,
};

export default Rds;
