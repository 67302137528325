import axios from "../../utils/axios";

export const SET_BRANCHES = "SET_BRANCHES";
export const SET_CHERRIES = "SET_CHERRIES";
export const SET_COMMIT = "SET_COMMIT";
export const SET_GIT_LOADING = "SET_ACCESS_LOADING";

export const setBranches = (branches) => {
  return {
    type: SET_BRANCHES,
    branches,
  };
};

export const setCherries = (cherries) => {
  return {
    type: SET_CHERRIES,
    cherries,
  };
};

export const setCommit = (commit) => {
  return {
    type: SET_COMMIT,
    commit,
  };
};

export const setGitLoading = (loading) => {
  return {
    type: SET_GIT_LOADING,
    loading,
  };
};

export const getBranches = () => {
  return async function (dispatch, getState) {
    dispatch(setGitLoading(true));
    const res = await axios.get("/api/git/branches");
    if (res.data) {
      dispatch(setBranches(Object.values(res.data)));
    }
    dispatch(setGitLoading(false));
  };
};

export const getCherries = () => {
  return async function (dispatch, getState) {
    dispatch(setGitLoading(true));
    const res = await axios.get("/api/git/cherries");
    if (res.data) {
      dispatch(setCherries(res.data));
    }
    dispatch(setGitLoading(false));
  };
};

export const getCommit = (id) => {
  return async function (dispatch, getState) {
    dispatch(setGitLoading(true));
    const res = await axios.get("/api/git/commit/" + id);
    if (res.data) {
      dispatch(setCommit(res.data));
    }
    dispatch(setGitLoading(false));
  };
};

export const setCherryPick = (values) => {
  return async function (dispatch, getState) {
    dispatch(setGitLoading(true));
    const res = await axios.post("/api/git/cherries", values);
    if (res.data) {
      dispatch(setCommit(res.data));
      dispatch(getCherries());
    }
  };
};

export const deleteCherry = (id) => {
  return async function (dispatch, getState) {
    dispatch(setGitLoading(true));
    const res = await axios.delete("/api/git/cherries/" + id);
    if (res.data) {
      dispatch(getCherries());
    }
  };
};
