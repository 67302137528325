import "./App.css";

import { Content } from "antd/lib/layout/layout";
import CtiHeader from "./Components/Layout/CtiHeader";
import { Layout } from "antd";
import Main from "./Components/Layout/Main";
import { Provider } from "react-redux";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./redux/store";

function App() {
  /* --------------------------------- Render --------------------------------- */
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <CtiHeader />
          <Content className="layout">
            <Main />
          </Content>
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;
