import { SET_MESSAGES, SET_MESSAGES_LOADING } from "../actions/messageActions";

const INITIAL_STATE = {
  messages: [],
  loading: false,
  latest: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };

    case SET_MESSAGES_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default reducer;
