import React, { useEffect } from "react";
import { getAgents, getServers } from "../../redux/actions/serverActions";
import { useDispatch, useSelector } from "react-redux";

import Contacts from "./Contacts";
import Finess from "./Finess";
import Groups from "./Groups";
import Servers from "./Servers";
import { Tabs } from "antd";
import { getAccess } from "../../redux/actions/accessActions";
import { getClients } from "../../redux/actions/clientActions";
import { getContacts } from "../../redux/actions/contactActions";
import { getGroups } from "../../redux/actions/groupActions";
import { getLicences } from "../../redux/actions/licenceActions";
import { getModules } from "../../redux/actions/moduleActions";
import { getUsers } from "../../redux/actions/userActions";
import { getVagues } from "../../redux/actions/vagueActions";

const { TabPane } = Tabs;

function Clients() {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contacts.contacts);

  useEffect(() => {
    dispatch(getServers());
    dispatch(getAgents());
    dispatch(getClients());
    dispatch(getVagues());
    dispatch(getGroups());
    dispatch(getLicences());
    dispatch(getModules());
    dispatch(getUsers());
    dispatch(getAccess());
  }, [dispatch]);

  useEffect(() => {
    if (!Object.values(contacts).length) {
      dispatch(getContacts());
    }
  });

  return (
    <React.Fragment>
      <Tabs>
        <TabPane key="client" tab="Environnements CTI">
          <Finess />
        </TabPane>
        <TabPane key="servers" tab="Serveurs">
          <Servers />
        </TabPane>
        <TabPane key="groups" tab="Groupes">
          <Groups />
        </TabPane>
        <TabPane key="contacts" tab="Contacts">
          <Contacts />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
}

export default Clients;
