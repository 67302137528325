import axios from "../../utils/axios";

export const SET_STATS = "SET_STATS";
export const SET_RESTARTS = "SET_RESTARTS";
export const SET_LATEST = "SET_LATEST";
export const SET_STATS_LOADING = "SET_STATS_LOADING";
export const SET_GROUP_STATS = "SET_GROUP_STATS";

export const setStats = (stats) => {
  return {
    type: SET_STATS,
    stats,
  };
};

export const setRestarts = (restarts) => {
  return {
    type: SET_RESTARTS,
    restarts,
  };
};

export const setLatest = (latest) => {
  return {
    type: SET_LATEST,
    latest,
  };
};

export const setStatsLoading = (loading) => {
  return {
    type: SET_STATS_LOADING,
    loading,
  };
};

export const setGroupStats = (stats) => {
  return {
    type: SET_GROUP_STATS,
    stats,
  };
};

export const getStats = () => {
  return async function (dispatch, getState) {
    dispatch(setStatsLoading(true));
    const res = await axios.get("/api/stats");
    if (res.data) {
      dispatch(setStats(res.data));
    }
    dispatch(setStatsLoading(false));
  };
};

export const getRestarts = () => {
  return async function (dispatch, getState) {
    dispatch(setStatsLoading(true));
    const res = await axios.get("/api/restarts");
    if (res.data) {
      dispatch(setRestarts(res.data));
    }
    dispatch(setStatsLoading(false));
  };
};

export const getLatest = () => {
  return async function (dispatch, getState) {
    dispatch(setStatsLoading(true));
    const res = await axios.get("/api/latest");
    if (res.data && res.data.length) {
      dispatch(setLatest(res.data));
    }
    dispatch(setStatsLoading(false));
  };
};

export const getGroupStats = (group, list) => {
  return async function (dispatch, getState) {
    dispatch(setStatsLoading(true));
    let res;
    if (list) {
      res = await axios.get("/api/stats/" + group + "?list=true");
    } else {
      res = await axios.get("/api/stats/" + group);
    }
    if (res.data) {
      let stats = {
        ...res.data,
        envs: res.data.envs.map((env) => {
          const traitements = JSON.parse(env.traitements);
          const status = JSON.parse(env.status);
          let traitements_status = {};
          traitements.forEach((job, index) => {
            if (job) {
              traitements_status[job] = status[index];
            }
          });
          return {
            ...env,
            latest_settings: JSON.parse(env.latest_settings),
            traitements_status: traitements_status,
          };
        }),
      };
      dispatch(setGroupStats(stats));
    }
    dispatch(setStatsLoading(false));
  };
};
