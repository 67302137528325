import { Button, Input, List, Select, Spin, notification } from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";

import Modal from "antd/lib/modal/Modal";
import axios from "../../utils/axios";
import { useEffect } from "react";

function Vpn({ resourceAccess, username }) {
  /* ---------------------------------- Hooks --------------------------------- */

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedVpn, setSelectedVpn] = useState("elsan");
  const [trig, setTrig] = useState("");

  const getData = React.useCallback(async () => {
    const res = await axios.get("/docker/containers/json?all=true");
    let tmpData = res.data.filter((con) => con.Labels["name"]);
    let runningData = tmpData
      .filter((con) => con.State === "running")
      .sort((a, b) => a.Labels.name.localeCompare(b.Labels.name));
    let stoppedData = tmpData
      .filter((con) => con.State !== "running")
      .sort((a, b) => a.Labels.name.localeCompare(b.Labels.name));
    tmpData = [...runningData, ...stoppedData];
    if (
      resourceAccess?.guacamole.roles.includes("guac-admin") ||
      resourceAccess?.guacamole.roles.includes("guac-exploit")
    ) {
      setData(tmpData);
    } else {
      let filteredData = tmpData.filter((container) => {
        if (container.Labels.type === "cti-vpn") {
          if (container.Labels.name.includes("(xp)")) {
            return false;
          }
          if (!container.Labels.user || container.Labels.user === username) {
            return true;
          }
          return false;
        }
        return false;
      });
      setData(filteredData);
    }
  }, [resourceAccess?.guacamole.roles, username]);

  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 10000);
    return () => clearInterval(interval);
  }, [getData]);

  /* ---------------------------------- Data ---------------------------------- */

  const elsanTmp = {
    Image: "fortivpn-proxy-elsan",
    ExposedPorts: {
      1111: {},
    },
    Labels: {
      type: "cti-vpn",
      name: `157 - Elsan - ${trig.toLowerCase()}`,
      user: trig.toLowerCase(),
    },
    HostConfig: {
      Devices: [
        {
          PathOnHost: "/dev/ppp",
          PathInContainer: "/dev/ppp",
          CgroupPermissions: "rwm",
        },
      ],
      CapAdd: ["NET_ADMIN"],
      Binds: [
        `/etc/vpn/elsan/elsan_${trig.toLowerCase()}.conf:/etc/openfortivpn/config`,
      ],
      AutoRemove: true,
    },
    Env: [
      "REMOTE_ADDR=172.31.249.167:3389",
      "REMOTE_SSH_ADDR=172.31.249.167:22",
    ],
    NetworkingConfig: {
      EndpointsConfig: {
        cti_rds: {},
      },
    },
  };

  /* ----------------------------- Partial render ----------------------------- */

  const getAvatar = (item) => {
    if (item.State === "running") {
      return <CheckCircleFilled style={{ color: "green", fontSize: 18 }} />;
    } else if (item.State === "exited") {
      return <CloseCircleFilled style={{ color: "red", fontSize: 18 }} />;
    } else {
      return <LoadingOutlined />;
    }
  };

  /* -------------------------------- Handlers -------------------------------- */

  const handleRunVpn = async (id, state) => {
    let action = state === "running" ? "stop" : "start";
    let url = `/docker/containers/${id}/${action}`;
    await axios.post(url);
    getData();
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleCreateContainer = async () => {
    if (
      trig === username ||
      resourceAccess?.guacamole.roles.includes("guac-admin") ||
      resourceAccess?.guacamole.roles.includes("guac-exploit")
    ) {
      setShowModal(false);
      let url = `/docker/containers/create?name=vpn_elsan_${trig.toLowerCase()}`;
      let vpnData = {};
      if (selectedVpn === "elsan") {
        vpnData = elsanTmp;
      }
      const res = await axios.post(url, vpnData);
      if (res.data.Id) {
        handleRunVpn(res.data.Id);
      }
    } else {
      notification.error({
        message: "Erreur",
        description: "Vous n'avez pas le droit de créer ce VPN",
      });
    }
  };

  /* --------------------------------- Render --------------------------------- */

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={handleShowModal}
        onOk={handleCreateContainer}
        cancelText="Annuler"
        okText="Lancer"
        title="Créer un VPN"
      >
        <div className="form-row">
          <div className="form-label">Type :</div>
          <Select
            value={selectedVpn}
            onChange={(value) => setSelectedVpn(value)}
          >
            <Select.Option value="elsan">Elsan</Select.Option>
          </Select>
        </div>
        <div className="form-row">
          <div className="form-label">Utilisateur :</div>
          <Input
            placeholder="Trigramme"
            value={trig}
            onChange={(e) => setTrig(e.target.value)}
          />
        </div>
      </Modal>
      <div className="vpn-header">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Recherche"
        />
        <Button onClick={handleShowModal} type="primary">
          Créer un VPN
        </Button>
      </div>
      {data.length ? (
        <div className="scrollable">
          <List
            itemLayout="horizontal"
            dataSource={data.filter((container) =>
              container.Labels["name"]
                .toLowerCase()
                .includes(search.toLowerCase())
            )}
            renderItem={(item) => {
              const isExploit = item.Labels["name"].includes("(xp)");
              let style = { minWidth: 120 };
              if (isExploit) {
                style.background = "#f39c12";
                style.borderColor = "#f39c12";

                if (item.State === "running") {
                  style.background = "#ff4d4f";
                  style.borderColor = "#ff4d4f";
                }
              }
              return (
                <List.Item
                  actions={[
                    <Button
                      onClick={() => handleRunVpn(item.Id, item.State)}
                      style={style}
                      type={item.State === "running" ? "danger" : "primary"}
                    >
                      {item.State === "exited" ? "Démarrer" : "Arrêter"}
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={getAvatar(item)}
                    title={item.Labels["name"].replace("(xp)", "")}
                    description={item.Status}
                  />
                </List.Item>
              );
            }}
          />
        </div>
      ) : (
        <Spin
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      )}
    </>
  );
}

Vpn.propTypes = {};

export default Vpn;
