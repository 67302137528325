import Keycloak from "keycloak-js";
import axios from "../../utils/axios";
import { batch } from "react-redux";
import localforage from "localforage";

export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_KEYCLOAK = "SET_KEYCLOAK";
export const SET_KEYCLOAK_LOADING = "SET_KEYCLOAK_LOADING";
export const SET_KEYCLOAK_REF = "SET_KEYCLOAK_REF";

export const setAuthenticated = (authenticated) => {
  return {
    type: SET_AUTHENTICATED,
    authenticated,
  };
};

export const setKeycloak = (keycloak = null) => {
  return {
    type: SET_KEYCLOAK,
    keycloak,
  };
};

export const setKeycloakLoading = (loading) => {
  return {
    type: SET_KEYCLOAK_LOADING,
    loading,
  };
};

export const setRef = (ref) => {
  return {
    type: SET_KEYCLOAK_REF,
    ref,
  };
};

export const authenticateUser = (ref) => {
  return async function (dispatch, getState) {
    dispatch(setKeycloakLoading(true));
    if (ref !== "/home") {
      await localforage.setItem("ref", ref);
    }
    const keycloakConn = Keycloak("../keycloak.json");
    const authenticated = await keycloakConn.init({
      onLoad: "login-required",
      redirectUri: `${window.location.origin}/login`,
    });
    let newwindow = window.open(
      "https://exploit.ctisante.com/guacamole/",
      "_blank",
      "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=5, height=5, visible=none"
    );
    setTimeout(() => {
      if (newwindow) {
        newwindow.close();
      }
    }, 10000);

    setInterval(async () => {
      await keycloakConn.updateToken(185);
      localforage.setItem("token", keycloakConn.token);
      localforage.setItem("refreshToken", keycloakConn.refreshToken);
      dispatch(setKeycloak(keycloakConn));
    }, 180000);

    const tmpRef = await localforage.getItem("ref");
    await localforage.setItem("token", keycloakConn.token);
    await localforage.setItem("refreshToken", keycloakConn.refreshToken);

    axios.post("/api/users", { user: keycloakConn.idTokenParsed });
    batch(() => {
      dispatch(setRef(tmpRef && tmpRef !== "/" ? tmpRef : "/home"));
      dispatch(setKeycloak(keycloakConn));
      dispatch(setAuthenticated(authenticated));
      dispatch(setKeycloakLoading(false));
    });
  };
};

export const logoutUser = () => {
  return async function (dispatch, getState) {
    const store = getState();
    const keycloak = store.keycloak.keycloak;

    keycloak.logout({ redirectUri: window.location.origin });
    dispatch(setAuthenticated(false));
    dispatch(setKeycloak());
  };
};
