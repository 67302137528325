import {
  Button,
  Checkbox,
  Input,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import FinessModal from "./FinessModal";
import InfoModal from "./InfoModal";
import axios from "../../utils/axios";
import { useSelector } from "react-redux";

function Finess() {
  const [showModal, setShowModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [search, setSearch] = useState("");
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showLicenceId, setShowLicenceId] = useState();
  const [showDisabled, setShowDisabled] = useState(false);
  const [filters, setFilters] = useState({});

  const clients = useSelector((state) => state.clients.clients);
  const groups = useSelector((state) => state.groups.groups);
  const loading = useSelector((state) => state.clients.loading);

  const emptyGroup = groups.find((grp) => grp.name === "Aucun");

  const newClient = {
    code_client_cti: "",
    raison_sociale: "",
    raison_sociale_abregee: "",
    finess: "",
    commentaire: "",
    nom_responsable: "",
    email_responsable: "",
    telephone_responsable: "",
    annule: " ",
    nom_responsable_2: "",
    code_interne_client: "",
    responsable_1: "",
    responsable_2: "",
    server_id: null,
    group_id: emptyGroup ? emptyGroup.value : null,
    vague_id: null,
    is_log_ignored: false,
  };

  useEffect(() => {
    let tmpFilters = {};
    clients.forEach((client) => {
      let servers = tmpFilters.servers ?? [];
      servers.push(client.hostname);
      tmpFilters.servers = servers;
      let groups = tmpFilters.groups ?? [];
      groups.push(client.group_name);
      tmpFilters.groups = groups;
      let vagues = tmpFilters.vagues ?? [];
      vagues.push(client.name);
      tmpFilters.vagues = vagues;
      let resp = tmpFilters.resp ?? [];
      resp.push(client.responsable_1);
      resp.push(client.responsable_2);
      tmpFilters.resp = resp;
    });
    tmpFilters.servers = [...new Set(tmpFilters.servers)]
      .sort((a, b) => (a ? a.localeCompare(b) : false))
      .map((server) => ({
        text: server,
        value: server,
      }));
    tmpFilters.groups = [...new Set(tmpFilters.groups)]
      .sort((a, b) => (a ? a.localeCompare(b) : false))
      .map((group) => ({
        text: group,
        value: group,
      }));
    tmpFilters.vagues = [...new Set(tmpFilters.vagues)]
      .sort((a, b) => (a ? a.localeCompare(b) : false))
      .map((vague) => ({
        text: vague,
        value: vague,
      }));
    tmpFilters.resp = [...new Set(tmpFilters.resp)]
      .sort((a, b) => (a ? a.localeCompare(b) : false))
      .map((resp) => ({
        text: resp,
        value: resp,
      }));
    setFilters(tmpFilters);

    if (clients.length) {
      const searchedValues = [
        ...Object.values(providers),
        ...Object.values(providers).map((key) => `${key}2`),
      ];
      let tmpProviderOptions = clients.reduce((prev, curr) => {
        const envSettings = curr.settings?.ENVIRONMENT;
        let newReturn = [...prev];
        if (envSettings) {
          for (const [key, value] of Object.entries(envSettings)) {
            if (searchedValues.includes(key)) {
              newReturn.push(value);
            }
          }
        }
        return newReturn;
      }, []);
      tmpProviderOptions = [...new Set(tmpProviderOptions)].filter(
        (prov) => prov !== ""
      );
      setProviderOptions(
        tmpProviderOptions.map((prov) => ({ label: prov, value: prov }))
      );
    }
  }, [clients]);

  const handleShowModal = (key) => {
    if (Object.keys(selectedClient).length) {
      setSelectedClient({});
      setShowModal(false);
    } else if (!key || typeof key === "object") {
      setSelectedClient(newClient);
      setShowModal(true);
    } else {
      setSelectedClient(clients.find((client) => client.key === key));
      setShowModal(true);
    }
  };

  const handleShowInfoModal = (licenceId) => {
    if (typeof licenceId !== "object") {
      setShowLicenceId(licenceId);
    }
    setShowInfoModal(!showInfoModal);
  };

  const clientColumns = [
    {
      title: "FINESS",
      dataIndex: "finess",
      key: "finess",
      width: 30,
      sorter: (a, b) =>
        a.finess.localeCompare(b.finess, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
    },
    {
      title: "Serveur",
      dataIndex: "hostname",
      key: "server",
      sorter: (a, b) =>
        a.hostname.toString().localeCompare(b.hostname.toString(), undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      filters: filters.servers,
      onFilter: (value, record) => record.hostname.indexOf(value) === 0,
    },
    {
      title: "Code",
      dataIndex: "code_client_cti",
      key: "code",
      sorter: (a, b) =>
        a.code_client_cti.localeCompare(b.code_client_cti, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
    },
    {
      title: "Groupe",
      dataIndex: "group_name",
      key: "group_name",
      sorter: (a, b) =>
        a?.group_name && b?.group_name
          ? a.group_name.localeCompare(b.group_name, undefined, {
              numeric: true,
              sensitivity: "base",
            })
          : false,
      filters: filters.groups,
      onFilter: (value, record) =>
        record.group_name ? record.group_name.indexOf(value) === 0 : false,
    },
    {
      title: "Raison Sociale",
      dataIndex: "raison_sociale",
      key: "raison_sociale",
      sorter: (a, b) =>
        a.raison_sociale.localeCompare(b.raison_sociale, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
    },
    {
      title: "Vague",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      filters: filters.vagues,
      onFilter: (value, record) =>
        record.name ? record.name.toString().indexOf(value) === 0 : false,
      render: (text, record, index) => {
        return (
          <>
            <span>{text}</span>
            {record.ignore_update && (
              <Tooltip
                title={`Mises à jour désactivées jusqu'au ${
                  record.ignore_until
                } (${record.comment ?? "Aucune raison spécifiée"})`}
              >
                <span className="dot"></span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Resp(s)",
      dataIndex: "responsables",
      key: "responsables",
      render: (text, record, index) => {
        const r1 = record.responsable_1;
        const r2 = record.responsable_2;
        if (r2) {
          return `${r1} & ${r2}`;
        } else {
          return r1;
        }
      },
      filters: filters.resp,
      onFilter: (value, record) =>
        record.responsable_1.indexOf(value) === 0 ||
        record.responsable_2.indexOf(value) === 0,
    },
    {
      title: "Désactivé",
      dataIndex: "annule",
      key: "annule",
      align: "center",
      sorter: (a, b) =>
        a.annule.localeCompare(b.annule, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      render: (text, record, index) => {
        const checked = record.annule === "O";
        return <Checkbox disabled checked={checked} />;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",

      render: (text, record, index) => {
        return (
          <div className="client-actions">
            <Button onClick={() => handleShowModal(record.key)}>
              <EditOutlined />
            </Button>
            <Tooltip title="Infos">
              <Button
                style={{ marginLeft: 8 }}
                onClick={() => handleShowInfoModal(record.key)}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Envoyer les licences">
              <Button
                style={{ marginLeft: 8 }}
                disabled={!record.is_mq_compatible}
                onClick={() => handleSendLicences(record)}
              >
                <UploadOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Mettre à jour l'environnement">
              <Popconfirm
                onConfirm={() => handleUpdateEnv(record)}
                title="Etes vous sur de vouloir mettre cet environnement à jour ?"
              >
                <Button
                  style={{ marginLeft: 8 }}
                  disabled={!record.is_mq_compatible}
                >
                  <SyncOutlined />
                </Button>
              </Popconfirm>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <FinessModal
        selectedClient={selectedClient}
        showModal={showModal}
        setSelectedClient={setSelectedClient}
        handleShowModal={handleShowModal}
      />
      <InfoModal
        showInfoModal={showInfoModal}
        handleShowInfoModal={handleShowInfoModal}
        licenceId={showLicenceId}
      />
      <div className="clients-header">
        <Input
          placeholder="Recherche"
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          showSearch
          filterOption={(input, option) =>
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={providerOptions}
          style={{ width: 450, marginLeft: 8 }}
          onChange={(value) => setSelectedProviders(value)}
          value={selectedProviders}
          mode="multiple"
          allowClear
          placeholder="Prestataires"
        />
        <span style={{ width: 180, marginLeft: 12 }}>
          Afficher les désactivé
        </span>
        <Switch
          checked={showDisabled}
          onChange={(val) => setShowDisabled(val)}
        />
        <Button onClick={handleShowModal} style={{ marginLeft: 8 }}>
          <PlusCircleOutlined /> Ajouter
        </Button>
      </div>
      <div className="scrollable">
        <Table
          columns={clientColumns}
          dataSource={clients.filter((client) => {
            let envValues = client.settings?.ENVIRONMENT
              ? Object.values(client.settings.ENVIRONMENT)
              : [];
            return (
              (!selectedProviders.length ||
                selectedProviders.some((prov) => envValues.includes(prov))) &&
              (showDisabled || (!showDisabled && client.annule !== "O")) &&
              (client.raison_sociale
                .toLowerCase()
                .includes(search.toLocaleLowerCase()) ||
                client.code_client_cti
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase()) ||
                client.finess
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase()))
            );
          })}
          pagination={false}
          size="small"
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
}

export default Finess;

export async function handleSendLicences(client, modules = []) {
  const queueName = `${client.hostname.toLowerCase()}-${client.server_id}`;
  let data = {
    queueName,
    envs: [`iCTI_${client.code_client_cti}`],
    action: "update-licence",
    modules,
  };
  let res = await axios.post("/api/messages", data);
  notification.info({
    message: "Message envoyée",
    description: `Identifiant du message: ${res.data.correlation_id}`,
  });
}

export async function handleUpdateEnv(client, modules = []) {
  const queueName = `${client.hostname.toLowerCase()}-${client.server_id}`;
  let data = {
    queueName,
    envs: [`iCTI_${client.code_client_cti}`],
    action: "auto-update",
    modules,
  };
  let res = await axios.post("/api/messages", data);
  notification.info({
    message: "Message envoyée",
    description: `Identifiant du message: ${res.data.correlation_id}`,
  });
}

export const providers = {
  iCTI_activite: "ADMPROVIDER",
  iCTI_pmsi: "PMSIPROVIDER",
  iCTI_pmsipsy: "PMSIPSYPROVIDER",
  iCTI_pmsihad: "PMSIHADPROVIDER",
  iCTI_pmsissr: "PMSISSRPROVIDER",
  iCTI_rh: "RHPROVIDER",
  iCTI_compta: "COMPTAPROVIDER",
  iCTI_eco: "ECOPROVIDER",
  iCTI_dpi: "DPIPROVIDER",
  iCTI_extraction: "KIWEEPROVIDER",
  iCTI_kiwee: "KIWEEPROVIDER",
  iCTI_fae: "KIWEEPROVIDER",
};
