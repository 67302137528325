import axios from "../../utils/axios";

export const SET_MESSAGES = "SET_MESSAGES";
export const SET_MESSAGES_LOADING = "SET_MESSAGES_LOADING";

export const setMessages = (messages) => {
  return {
    type: SET_MESSAGES,
    messages,
  };
};

export const setMessagesLoading = (loading) => {
  return {
    type: SET_MESSAGES_LOADING,
    loading,
  };
};

export const getMessages = () => {
  return async function (dispatch, getState) {
    dispatch(setMessagesLoading(true));
    const res = await axios.get("/api/messages");
    if (res.data && res.data.length) {
      dispatch(setMessages(res.data));
    }
    dispatch(setMessagesLoading(false));
  };
};
