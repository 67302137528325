import {
  Button,
  Collapse,
  Modal,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import { CopyOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { handleSendLicences, providers } from "./Finess";

import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { accessTypeOptions } from "./FinessModal";
import { format as prettyFormat } from "pretty-format";
import { useSelector } from "react-redux";

const { Panel } = Collapse;

function InfoModal({ licenceId, showInfoModal, handleShowInfoModal }) {
  const modules = useSelector((state) => state.modules.modules);
  const licences = useSelector((state) => state.licences.licences);
  const clients = useSelector((state) => state.clients.clients);
  const access = useSelector((state) => state.access.access);
  const contacts = useSelector((state) => state.contacts.contacts);
  const client = clients.find((client) => client.key === licenceId);

  const [currentAccess, setCurrentAccess] = useState([]);
  const [currentContacts, setCurrentContacts] = useState([]);

  useEffect(() => {
    if (client && access.length) {
      const clientAccess = access.filter(
        (acc) => acc.resource_type === 3 && acc.resource_id === client.oid
      );
      const serverAccess = access
        .filter(
          (acc) =>
            acc.resource_type === 2 && acc.resource_id === client.server_id
        )
        .map((acc) => ({ ...acc, comment: `Accès serveur - ${acc.comment}` }));
      const groupAccess = access
        .filter(
          (acc) =>
            acc.resource_type === 1 && acc.resource_id === client.group_id
        )
        .map((acc) => ({ ...acc, comment: `Accès groupe - ${acc.comment}` }));

      setCurrentAccess([...clientAccess, ...serverAccess, ...groupAccess]);
    }
  }, [access, client]);

  useEffect(() => {
    if (client && Object.keys(contacts).length) {
      setCurrentContacts(
        Object.values(contacts).filter((contact) =>
          contact.groups.includes(client.code_client_cti)
        )
      );
    }
  }, [contacts, client]);

  const options = {
    highlight: true,
  };

  const licenceColumns = [
    {
      key: "module",
      title: "Module",
      dataIndex: "module",
      width: 120,
      render: (text, record) => {
        const module = modules.find((mod) => mod.oid === record.module_id);
        return module.name;
      },
    },
    {
      key: "prestataires",
      title: "Prestataires",
      dataIndex: "prestataires",
      width: 120,
      render: (text, record) => {
        const module = modules.find((mod) => mod.oid === record.module_id);
        const providerKey = providers[module.name];
        const provider = client.settings?.ENVIRONMENT
          ? client.settings?.ENVIRONMENT[providerKey]
          : null;
        const provider2 = client.settings?.ENVIRONMENT
          ? client.settings?.ENVIRONMENT[`${providerKey}2`]
          : null;

        if (provider2) {
          return `${provider} & ${provider2}`;
        } else {
          return provider;
        }
      },
    },
    {
      key: "licence",
      title: "Licence",
      dataIndex: "cle",
      ellipsis: true,
    },
    {
      key: "actions",
      dataIndex: "cle",
      align: "center",
      width: 160,
      render: (text, record) => (
        <>
          <Tooltip title="Copier">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(text);
                notification.success({
                  message: "Succès",
                  description: "Licence copiée dans le presse-papier",
                });
              }}
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Envoyer la licence">
            <Button
              style={{ marginLeft: 8 }}
              onClick={() => {
                handleSendLicences(client, [record.name]);
              }}
            >
              <UploadOutlined />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const accessColumns = [
    {
      key: "type",
      title: "Type",
      dataIndex: "access_type_id",
      width: 100,
      render: (text, record) =>
        accessTypeOptions.find((type) => type.value === record.access_type_id)
          ?.label,
    },
    {
      key: "comment",
      title: "Commentaire",
      dataIndex: "comment",
      width: 300,
    },
  ];

  const contactsColumns = [
    {
      key: "name",
      title: "Nom",
      render: (text, record) => `${record.name} ${record.lastName}`,
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      render: (text, record) => (
        <a href={`mailto:${record.email}`}>{record.email}</a>
      ),
    },
    {
      key: "tel",
      title: "Tel",
      dataIndex: "tel",
    },
    {
      key: "mobile",
      title: "Mobile",
      dataIndex: "mobile",
    },
    {
      key: "groups",
      title: "Tags",
      dataIndex: "groups",
      render: (text, record) =>
        record.groups.map((grp) => <Tag key={grp}>{grp}</Tag>),
    },
  ];

  return (
    <Modal
      title="Licences"
      footer={null}
      visible={showInfoModal}
      onCancel={handleShowInfoModal}
      width="80vh"
    >
      <Collapse defaultActiveKey={["1", "4"]}>
        <Panel header="Accès" key="1">
          <Table
            size="small"
            pagination={false}
            columns={accessColumns}
            dataSource={currentAccess}
          />
        </Panel>
        <Panel header="Licences" key="2">
          <Table
            size="small"
            pagination={false}
            columns={licenceColumns}
            dataSource={licences.filter((lic) => lic.finess_id === licenceId)}
          />
        </Panel>
        <Panel header="Contacts" key="3">
          <Table
            size="small"
            pagination={false}
            columns={contactsColumns}
            dataSource={currentContacts}
          />
        </Panel>
        <Panel header="Settings" key="4">
          <pre
            style={{ background: "rgb(13,42,53)", color: "white", padding: 8 }}
          >
            {client && prettyFormat(client.settings, options)}
          </pre>
        </Panel>
        <Panel header="Commentaire" key="5">
          <div>
            {client && <ReactMarkdown>{client.commentaire}</ReactMarkdown>}
          </div>
        </Panel>
      </Collapse>
    </Modal>
  );
}

InfoModal.propTypes = {
  licenceId: PropTypes.number,
  handleShowInfoModal: PropTypes.func,
  showInfoModal: PropTypes.bool,
};

export default InfoModal;
