import axios from "../../utils/axios";

export const SET_VAGUES = "SET_VAGUES";
export const SET_VAGUES_LOADING = "SET_VAGUES_LOADING";

export const setVagues = (vagues) => {
  return {
    type: SET_VAGUES,
    vagues,
  };
};

export const setVaguesLoading = (loading) => {
  return {
    type: SET_VAGUES_LOADING,
    loading,
  };
};

export const getVagues = () => {
  return async function (dispatch, getState) {
    dispatch(setVaguesLoading(true));
    const res = await axios.get("/api/vagues");
    if (res.data && res.data.length) {
      dispatch(setVagues(res.data));
    }
    dispatch(setVaguesLoading(false));
  };
};
