import "./AppPopover.css";

import { Button } from "antd";
import React from "react";
import bitbucket from "./bitbucket.png";
import gitea from "./gitea.png";
import jenkins from "./jenkins.png";
import jira from "./jira.png";
import vault from "./vault.png";

function AppPopover(props) {
  return (
    <div className="app-popover">
      <Button
        target="_blank"
        href="https://docs.ctisante.com"
        type="text"
        className="app-item"
      >
        <div className="item-label">Docs</div>
        <div className="item-icon">
          <img alt="icon" src={jira} />
        </div>
      </Button>
      <Button
        target="_blank"
        href="https://vault.ctisante.com"
        type="text"
        className="app-item"
      >
        <div className="item-label">Vault</div>
        <div className="item-icon">
          <img alt="icon" src={vault} />
        </div>
      </Button>
      <Button
        target="_blank"
        href="https://gitea.ctisante.com"
        type="text"
        className="app-item"
      >
        <div className="item-label">Gitea</div>
        <div className="item-icon">
          <img alt="icon" src={gitea} />
        </div>
      </Button>
      <Button
        target="_blank"
        href="https://bitbucket.ctisante.com"
        type="text"
        className="app-item"
      >
        <div className="item-label">Bitbucket</div>
        <div className="item-icon">
          <img alt="icon" src={bitbucket} />
        </div>
      </Button>
      <Button
        target="_blank"
        href="https://jira.ctisante.com"
        type="text"
        className="app-item"
      >
        <div className="item-label">Jira</div>
        <div className="item-icon">
          <img alt="icon" src={jira} />
        </div>
      </Button>
      <Button
        target="_blank"
        href="https://jenkins.ctisante.com"
        type="text"
        className="app-item"
      >
        <div className="item-label">Jenkins</div>
        <div className="item-icon">
          <img alt="icon" src={jenkins} />
        </div>
      </Button>
    </div>
  );
}

export default AppPopover;
