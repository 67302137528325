import { Button, List } from "antd";

import { DesktopOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";

function RdsList({ data, group, resourceAccess }) {
  const getDesc = (activeConns) => {
    let message = "";
    switch (activeConns) {
      case 0:
        message = "Pas d'utilisateur connecté";
        break;
      case 1:
        message = "1 utilisateur connecté";
        break;
      default:
        message = `${activeConns} utilisateurs connectés`;
        break;
    }
    return message;
  };

  const getConnectionLink = (id) => {
    var u8 = new Uint8Array([0]);
    var decoder = new TextDecoder("utf8");
    const nul = decoder.decode(u8);
    const uid = btoa(`${id}${nul}c${nul}mysql`);
    return `https://exploit.ctisante.com/guacamole/#/client/${uid}`;
  };

  const handleRunVpn = (ref) => {
    var a = document.createElement("a");
    a.href = ref;
    a.target = "_blank";
    a.click();
  };

  const renderItem = (item) => {
    const itemNameSplit = item[0].name.split("-");
    if (itemNameSplit.length > 2) {
      itemNameSplit.pop();
    }
    const itemName = itemNameSplit.join("-");

    const activeConnections = item.reduce(
      (prev, curr) => prev + curr.activeConnections,
      0
    );

    return (
      <List.Item
        actions={item.map((userConn, index) => {
          const splitName = userConn.name.split("-");
          let type = "primary";
          let user =
            splitName.length > 2
              ? splitName[splitName.length - 1].trim()
              : index + 1;
          let name = user;
          let style = { minWidth: 120 };
          const isExploit = user.toString().includes("(xp)");
          if (isExploit) {
            user = user.replace("(xp)", "");
            style.background = "#f39c12";
            style.borderColor = "#f39c12";
            name = user;
          } else {
            name = `Utilisateur ${name}`;
          }
          if (userConn.activeConnections > 0) {
            name = `Actif (${name})`;
          }
          if (
            !isExploit ||
            resourceAccess?.guacamole.roles.includes("guac-exploit") ||
            resourceAccess?.guacamole.roles.includes("guac-admin")
          ) {
            return (
              <Button
                onClick={() =>
                  handleRunVpn(getConnectionLink(userConn.identifier))
                }
                style={style}
                type={type}
                disabled={userConn.activeConnections > 0}
              >
                {name}
              </Button>
            );
          } else return null;
        })}
      >
        <List.Item.Meta
          avatar={
            <DesktopOutlined
              style={{
                color: activeConnections > 0 ? "#f39c12" : "rgba(0,0,0,0.85)",
              }}
            />
          }
          title={itemName}
          description={getDesc(activeConnections)}
        />
      </List.Item>
    );
  };

  if (group === "0") {
    let listData = Object.values(data).reduce(
      (prev, curr) => [...prev, ...curr],
      []
    );
    return (
      <List
        itemLayout="horizontal"
        dataSource={listData}
        renderItem={(conn) => (
          <List.Item
            actions={[
              <Button
                onClick={() => handleRunVpn(getConnectionLink(conn.identifier))}
                style={{ minWidth: 120 }}
                type="primary"
                disabled={conn.activeConnections > 0}
              >
                {conn.activeConnections > 0 ? "Actif" : "Lancer"}
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <DesktopOutlined
                  style={{
                    color:
                      conn.activeConnections > 0
                        ? "#f39c12"
                        : "rgba(0,0,0,0.85)",
                  }}
                />
              }
              title={conn.name}
              description={getDesc(conn.activeConnections)}
            />
          </List.Item>
        )}
      />
    );
  } else {
    return (
      <List
        itemLayout="horizontal"
        dataSource={Object.values(data)}
        renderItem={(item, index) => renderItem(item, index)}
      />
    );
  }
}

RdsList.propTypes = {
  data: PropTypes.object,
};

export default RdsList;
