import "./Map.css";

import * as d3 from "d3";
import * as d3Collection from "d3-collection";

import React, { useEffect, useState } from "react";
import {
  getLogs,
  setSelectedBuild,
  setShowLogsModal,
} from "../../redux/actions/logsActions";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumb from "./Breadcrumb";
import Minimap from "./Minimap";
import { Spin } from "antd";
//import { selectAll } from "d3-selection";
import { useHistory } from "react-router-dom";
import useResize from "../../hooks/useResize";

function Map2() {
  /* ---------------------------------- Hooks --------------------------------- */
  const logs = useSelector((state) => state.logs.logs);
  const loading = useSelector((state) => state.logs.loading);
  const [currentLogs, setCurrentLogs] = useState({});
  const [path, setPath] = useState(["racine"]);
  const [selectedNode, setSelectedNode] = useState({});
  const dispatch = useDispatch();
  const ref = React.useRef();
  const mapRef = React.useRef();
  const size = useResize(mapRef);
  const history = useHistory();

  useEffect(() => {
    dispatch(getLogs());
  }, [dispatch]);

  useEffect(() => {
    history.push("/map/" + path.join("/"));
  }, [path, history]);

  useEffect(() => {
    if (logs.children) {
      setCurrentLogs(logs);
    }
  }, [logs]);

  /* -------------------------- Preparation functions ------------------------- */

  const handleDrillChange = React.useCallback(
    (nodeName) => {
      const { dataNode, renderNode } = findNode(logs, nodeName, path);
      setSelectedNode(dataNode);
      setCurrentLogs(renderNode);
      const nodeNameIndex = path.findIndex((node) => node === nodeName);
      let modPath = [...path];
      if (nodeName === "racine") {
        setPath(["racine"]);
      } else {
        modPath.splice(nodeNameIndex + 1, modPath.length - (nodeNameIndex + 1));
        setPath(modPath);
      }
    },
    [logs, path]
  );

  const handleShowModal = React.useCallback(
    (e, node, historyIndex = null) => {
      e.stopPropagation();
      if (historyIndex !== null) {
        dispatch(
          setSelectedBuild({
            build: node.data.history[historyIndex][1],
            traitement_id: node.data.traitement_id,
          })
        );
      } else {
        dispatch(
          setSelectedBuild({
            build: node.data.no_build,
            traitement_id: node.data.traitement_id,
          })
        );
      }
      dispatch(setShowLogsModal());
    },
    [dispatch]
  );

  const color = React.useCallback((node) => {
    if (node.data.type === "group" || node.data.type === "server") {
      return "rgb(240,242,245)";
    } else if (node.data.expired) {
      return "#716F81";
    } else {
      const colors = ["#388e3c", "#f57c0090", "#d32f2f", "#716F81", "#A9A9A9"];
      return colors[Number(node.data.status_id)];
    }
  }, []);

  const realColor = React.useCallback((node) => {
    const colors = ["#388e3c", "#f57c0090", "#d32f2f", "#716F81", "#A9A99"];
    return colors[Number(node.data.status_id)];
  }, []);

  const zoom = React.useCallback(
    (d, node) => {
      const name = node.data.name;
      let prevPath = [];
      if (node.data.type === "server") {
        prevPath = ["racine", node.data.group_name, name];
      } else if (node.data.type === "client") {
        if (node.parent.data.type === "server") {
          prevPath = [
            "racine",
            node.data.group_name,
            node.data.server_name,
            name,
          ];
        } else {
          prevPath = ["racine", node.data.group_name, name];
        }
      } else {
        prevPath = ["racine", name];
      }
      const { dataNode } = findNode(logs, name, prevPath);
      setSelectedNode(dataNode);

      if (node.data.type === "client") {
        if (node.parent.data.type === "server") {
          setPath([
            "racine",
            node.data.group_name.toLowerCase(),
            node.data.server_name.toLowerCase(),
            name.toLowerCase(),
          ]);
        } else {
          setPath([
            "racine",
            node.data.group_name.toLowerCase(),
            name.toLowerCase(),
          ]);
        }
      } else if (node.data.type === "server") {
        setPath([
          "racine",
          node.data.group_name.toLowerCase(),
          node.data.name.toLowerCase(),
        ]);
      } else {
        setPath(["racine", node.data.name.toLowerCase()]);
      }
      const treemapData = node.data.children ?? node.data.jobs;

      setCurrentLogs({
        name,
        children: treemapData,
      });
    },
    [logs]
  );

  const historyColor = d3
    .scaleLinear()
    .domain(["0", "1", "2", "3"])
    .range(["#388e3c", "#f57c00", "#d32f2f", "#D0D0D0"]);

  const opacity = React.useCallback((data) => {
    if (data.type === "group") {
      return 1;
    } else if (data.errors === 0) {
      return 0.9;
    } else {
      const dynamicOpacity = d3.scaleLinear().domain([0, 6]).range([0.6, 1]);
      return dynamicOpacity(data.errors);
    }
  }, []);

  const treemap = React.useCallback(
    (data, width, height) =>
      d3
        .treemap()
        .size([width, height])
        .paddingTop(18)
        .paddingBottom(4)
        .paddingRight(4)
        .paddingLeft(4)(
        d3
          .hierarchy(data)
          .sum((d) => (d.type === "client" || d.type === "job" ? 1 : 0))
          .sort((a, b) => b.value - a.value)
      ),
    []
  );

  /* --------------------------------- d3 render --------------------------------- */

  useEffect(() => {
    if (history.action === "POP" && logs.children) {
      const path = history.location.pathname;
      const nodes = path.split("/");
      const lastNode = nodes[nodes.length - 1];
      handleDrillChange(lastNode);
    }
  }, [history.location.pathname, logs, handleDrillChange, history]);

  useEffect(() => {
    if (!loading && currentLogs.children) {
      const data = currentLogs;
      const fullWidth = size?.width ?? mapRef.current.offsetWidth;
      const fullHeight = size?.height ?? mapRef.current.offsetHeight;
      d3.select(ref.current).selectAll("*").remove();
      const root = treemap(data, fullWidth, fullHeight);
      const svg = d3
        .select(ref.current)
        .attr("viewBox", [0, 0, fullWidth, fullHeight]);

      const nestedData = d3Collection
        .nest()
        .key((d) => d.height)
        .entries(root.descendants());

      const node = svg
        .selectAll("g")
        .data(nestedData)
        .join("g")
        .selectAll("g")
        .data((d) => d.values)
        .join("g")
        .attr("transform", (d) => `translate(${d.x0},${d.y0})`);

      node
        .append("rect")
        .attr("fill", (d) => color(d))
        .attr("stroke", "rgb(240,242,245)")
        .attr("stroke-width", (d) => (d.data.type === "group" ? "12px" : "1px"))
        .attr("width", (d) => d.x1 - d.x0)
        .attr("height", (d) => d.y1 - d.y0)
        .style("opacity", (d) => opacity(d.data));

      /**
       * Affiche des rectangles (group, env, traitements)
       */
      var defs = svg.append("defs");
      defs
        .append("pattern")
        .attr("id", "hash4_4")
        .attr("width", "12")
        .attr("height", "8")
        .attr("patternUnits", "userSpaceOnUse")
        .attr("patternTransform", "rotate(45)")
        .append("rect")
        .attr("width", "4")
        .attr("height", "8")
        .attr("transform", "translate(0,0)")
        .attr("opacity", 0.4)
        .attr("fill", "white");

      node
        .filter((d) => d.data.running)
        .append("rect")
        .attr("class", ".stripes")
        .attr("stroke", "rgb(240,242,245)")
        .attr("stroke-width", (d) => (d.data.type === "group" ? "12px" : "1px"))
        .attr("width", (d) => d.x1 - d.x0)
        .attr("height", (d) => d.y1 - d.y0)
        .attr("fill", "url(#hash4_4)");

      var tooltip = d3
        .select(".tooltip")
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("z-index", "10");

      /**
       * Ajout de l'historique
       */
      node
        .filter((d) => d.data.type === "job")
        .append("circle")
        .attr("fill", (d) => historyColor(d.data.history[0][0]))
        .attr("stroke", "black")
        .attr("cx", 15)
        .attr("cy", 65)
        .attr("r", 10)
        .on("click", (e, node) => handleShowModal(e, node, 0))
        .on("mouseover", function () {
          return tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (e, node) {
          return tooltip
            .html(
              `<div>Le ${node.data.history[0][2]}</div><div>Durée: ${node.data.history[0][3]}</div>`
            )
            .style("top", e.pageY + 10 + "px")
            .style("left", e.pageX + 10 + "px");
        })
        .on("mouseout", function () {
          return tooltip.style("visibility", "hidden");
        });

      node
        .filter((d) => d.data.type === "job")
        .append("circle")
        .attr("fill", (d) => historyColor(d.data.history[1][0]))
        .attr("stroke", "black")
        .attr("cx", 45)
        .attr("cy", 65)
        .attr("r", 10)
        .on("click", (e, node) => handleShowModal(e, node, 1))
        .on("mouseover", function () {
          return tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (e, node) {
          return tooltip
            .html(
              `<div>Le ${node.data.history[1][2]}</div><div>Durée: ${node.data.history[1][3]}</div>`
            )
            .style("top", e.pageY + 10 + "px")
            .style("left", e.pageX + 10 + "px");
        })
        .on("mouseout", function () {
          return tooltip.style("visibility", "hidden");
        });

      node
        .filter((d) => d.data.type === "job")
        .append("circle")
        .attr("fill", (d) => historyColor(d.data.history[2][0]))
        .attr("stroke", "black")
        .attr("cx", 75)
        .attr("cy", 65)
        .attr("r", 10)
        .on("click", (e, node) => handleShowModal(e, node, 2))
        .on("mouseover", function () {
          return tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (e, node) {
          return tooltip
            .html(
              `<div>Le ${node.data.history[2][2]}</div><div>Durée: ${node.data.history[2][3]}</div>`
            )
            .style("top", e.pageY + 10 + "px")
            .style("left", e.pageX + 10 + "px");
        })
        .on("mouseout", function () {
          return tooltip.style("visibility", "hidden");
        });

      node
        .filter((d) => d.data.type === "group")
        .selectAll("rect")
        .style("stroke", "#716F81")
        .style("stroke-width", path.length === 1 ? 1.5 : 0);

      /**
       * Texte pour intitulés serveurs
       */
      node
        .filter((d) => d.children && d !== root)
        .append("text")
        .selectAll("tspan")
        .data((d) => [d.data.name])
        .join("tspan")
        .style("font-size", "12px")
        .style("fill", "black")
        .attr("fill-opacity", (d, i, nodes) =>
          i === nodes.length - 1 ? 0.75 : null
        )
        .text((d) => d)
        .attr("dx", 5)
        .style("fill", "black")
        .attr("dy", 16);

      /**
       * Texte pour environnements
       */
      if (path.length > 1) {
        node
          .filter((d) => d.data.jobs)
          .append("text")
          .selectAll("tspan")
          .data((d) => {
            let ret = [d.data.name, d.data.vague_name];
            if (d.data.status_id === "2") {
              ret.push(`${d.data.errors}*`);
            }
            if (!d.data.latest_integrity) {
              ret.push("Erreur d'intégrité");
            }
            return ret;
          })
          .join("tspan")
          .attr("fill-opacity", (d, i, nodes) =>
            i === nodes.length - 2 ? 0.75 : null
          )
          .style("fill", (d, i, nodes) => {
            return d === "Erreur d'intégrité" ? "red" : "white";
          })
          .style("font-size", "12px")
          .text((d) => d)
          .attr("x", 3)
          .attr("y", (d, i, nodes) => (i === 0 ? 15 : (i + 1) * 15));

        node
          .filter((d) => d.data.expired && !d.data.running)
          .append("circle")
          .attr("fill", (d) => realColor(d))
          .attr("stroke", "white")
          .attr("cx", (d) => d.x1 - d.x0 - 20)
          .attr("cy", 20)
          .attr("r", 6);

        node
          .filter((d) => d.data.running)
          .append("circle")
          .attr("class", "running")
          .attr("fill", (d) => realColor(d))
          .attr("stroke", "white")
          .attr("cx", (d) => d.x1 - d.x0 - 20)
          .attr("cy", 20)
          .attr("r", 6);
      } else {
        node
          .filter((d) => d.data.jobs)
          .append("text")
          .selectAll("tspan")
          .data((d) => [d.data.name, d.data.vague_name])
          .join("tspan")
          .attr("fill-opacity", (d, i, nodes) =>
            i === nodes.length - 1 ? 0.75 : null
          )
          .style("font-size", "12px")
          .text((d) => d)
          .attr("x", 3)
          .attr("y", (d, i, nodes) => (i === 0 ? 15 : (i + 1) * 15));

        /**
         * Ajout du statut sans expired
         */
        node
          .filter(
            (d) =>
              (d.data.type === "client" || d.data.jobs) &&
              d.data.expired &&
              !d.data.running
          )
          .append("circle")
          .attr("fill", (d) => realColor(d))
          .attr("stroke", "white")
          .attr("cx", (d) => d.x1 - d.x0 - 10)
          .attr("cy", 10)
          .attr("r", 4);
        const running = node.filter((d) => d.data.running);

        running
          .append("circle")
          .attr("class", "running")
          .attr("fill", (d) => realColor(d))
          .attr("stroke", "white")
          .attr("cx", (d) => d.x1 - d.x0 - 10)
          .attr("cy", 10)
          .attr("r", 4);
      }
      // repeat();

      // //blink
      // function repeat() {
      //   selectAll(".running")
      //     .attr("r", 1)
      //     .attr("opacity", 1)
      //     .transition()
      //     .duration(2000)
      //     .attr("r", 8)
      //     .attr("opacity", 0)
      //     .on("end", repeat);
      // }

      /**
       * Texte pour traitements
       */
      node
        .filter((d) => d.data.type === "job")
        .append("text")
        .selectAll("tspan")
        .data((d) => [d.data.name, d.data.start_date_time, d.data.duration])
        .join("tspan")
        .attr("fill-opacity", (d, i, nodes) =>
          i === nodes.length - 1 ? 0.75 : null
        )
        .style("font-size", "12px")
        .text((d) => d)
        .attr("x", 5)
        .attr("y", (d, i, nodes) => (i === 0 ? 15 : (i + 1) * 15));

      node
        .filter(
          (d) => d.children || (d.data.jobs && d.data.jobs.length && d !== root)
        )
        .attr("cursor", "pointer")
        .on("click", (e, node) => zoom(e, node));

      node
        .filter((d) => d.data.type === "job")
        .attr("cursor", "pointer")
        .on("click", (e, node) => handleShowModal(e, node));
    }
  }, [
    loading,
    currentLogs,
    color,
    handleShowModal,
    historyColor,
    opacity,
    path,
    size,
    treemap,
    zoom,
    realColor,
  ]);

  /* --------------------------------- Render --------------------------------- */

  if (loading) {
    return <Spin style={{ width: "100%", marginTop: "50vh" }} />;
  }
  return (
    <React.Fragment>
      <div className="map-header">
        <div className="map-breadcrumb">
          <Breadcrumb path={path} handleDrillChange={handleDrillChange} />
        </div>
        {/* {path.length === 1 && (
          <div className="map-filters">
            <span>Selectionnez un groupe:</span>
            <Select
              options={logs.children.map((group) => ({
                label: group.name,
                value: group.name,
              }))}
              style={{ width: 300 }}
            />
          </div>
        )} */}
        <div className="map-minimap">
          <Minimap node={selectedNode} />
        </div>
      </div>
      <div className="map" ref={mapRef}>
        <svg ref={ref} style={{ fill: "rgb(240,242,245)" }} />
        <div className="tooltip" />
      </div>
    </React.Fragment>
  );
}

export default React.memo(Map2);

/* ---------------------------------- Utils --------------------------------- */

function flattenLogs(logs) {
  let flatLogs = [];
  logs.forEach((group) => {
    flatLogs.push(group);
    if (group.children) {
      flatLogs = flatLogs.concat(...flattenLogs(group.children));
    }
  });
  return flatLogs;
}

export function findNode(logs, nodeName, path) {
  const clickedIndex = path.findIndex(
    (pathItem) => pathItem.toLowerCase() === nodeName.toLowerCase()
  );
  let node = logs;
  let renderNode = node;
  if (clickedIndex !== 0) {
    const flatLogs = flattenLogs(logs.children);
    if (clickedIndex === 1) {
      node = flatLogs.find(
        (node) =>
          node.name && node.name.toLowerCase() === nodeName.toLowerCase()
      );
      renderNode = node;
    } else if (clickedIndex === 2) {
      node = flatLogs.find(
        (node) =>
          node.name &&
          node.group_name &&
          node.group_name.toLowerCase() ===
            path[clickedIndex - 1].toLowerCase() &&
          node.name.toLowerCase() === nodeName.toLowerCase()
      );
      renderNode = {
        name: nodeName,
        children: node.jobs ?? node.children,
      };
    } else {
      node = flatLogs.find(
        (node) =>
          node.name &&
          node.code_client_cti &&
          node.group_name.toLowerCase() ===
            path[clickedIndex - 2].toLowerCase() &&
          node.code_client_cti.toLowerCase() === nodeName.toLowerCase()
      );
      renderNode = {
        name: nodeName,
        children: node.jobs,
      };
    }
  }
  return { dataNode: node, renderNode };
}
