import {
  ADD_ERROR,
  MODIFY_ERROR,
  REMOVE_ERROR,
  SET_ERRORS,
  SET_ERRORS_LOADING,
} from "../actions/errorsActions";

const INITIAL_STATE = {
  errors: [],
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    case SET_ERRORS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case REMOVE_ERROR:
      return {
        ...state,
        errors: state.errors.filter((err) => err.key !== action.key),
      };
    case ADD_ERROR:
      return {
        ...state,
        errors: [action.error, ...state.errors],
      };
    case MODIFY_ERROR:
      const newData = [...state.errors];
      let error = state.errors.findIndex((err) => err.key === action.error.key);
      const item = newData[error];
      newData.splice(error, 1, { ...item, ...action.error });
      return {
        ...state,
        errors: newData,
      };
    default:
      return state;
  }
};

export default reducer;
