import {
  AppstoreAddOutlined,
  LoadingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";

import AppPopover from "./AppPopover";
import { Header } from "antd/lib/layout/layout";
import { ReactComponent as Logo } from "./cti-logo_court_dark.svg";
import React from "react";
import { logoutUser } from "../../redux/actions/keycloakActions";

function CtiHeader(props) {
  const loading = useSelector((state) => state.keycloak.loading);
  const dispatch = useDispatch();
  return (
    <Header>
      <Popover trigger="click" placement="topLeft" content={<AppPopover />}>
        <AppstoreAddOutlined className="app-button" />
      </Popover>
      <Logo
        style={{
          width: "100px",
          height: "46px",
          marginLeft: "auto",
        }}
      />
      <Button
        type="link"
        className="connect-link"
        onClick={() => dispatch(logoutUser())}
      >
        <LogoutOutlined style={{ marginRight: 8 }} />
        {loading ? <LoadingOutlined spin /> : "Se Déconnecter"}
      </Button>
    </Header>
  );
}

export default CtiHeader;
