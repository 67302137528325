import {
  SET_GANTT,
  SET_LIST,
  SET_LOGS,
  SET_LOGS_LOADING,
  SET_SELECTED_BUILD,
  SET_SHOW_LOGS_MODAL,
} from "../actions/logsActions";

const INITIAL_STATE = {
  logs: [],
  gantt: [],
  list: [],
  loading: false,
  selectedBuild: null,
  showModal: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOGS:
      return {
        ...state,
        logs: action.logs,
      };

    case SET_GANTT:
      return {
        ...state,
        gantt: action.logs,
      };

    case SET_LIST:
      return {
        ...state,
        list: action.logs,
      };

    case SET_LOGS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case SET_SELECTED_BUILD:
      return {
        ...state,
        selectedBuild: action.build,
      };

    case SET_SHOW_LOGS_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
      };

    default:
      return state;
  }
};

export default reducer;
