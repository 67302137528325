import "./GroupDashboard.css";

import React, { useEffect, useRef } from "react";
import { axisBottom, axisLeft } from "d3-axis";
import { scaleLinear, scaleOrdinal, scaleTime } from "d3-scale";

import { area } from "d3-shape";
import { extent } from "d3-array";
import { nest } from "d3-collection";
import { select } from "d3-selection";
import { timeFormat } from "d3-time-format";
import { timeHour } from "d3-time";
import useResize from "../../hooks/useResize";

function DiskHistoryGraph({
  data,
  index,
  isOffline,
  title,
  order,
  memoryData,
}) {
  const ref = useRef();
  const mapRef = useRef();
  const size = useResize(mapRef);

  useEffect(() => {
    select(ref.current).selectAll("*").remove();
    if (data.length) {
      const mappedData = data.map((row) => ({
        ...row,
        value: Number(row.percentage),
      }));

      const mappedMemoryData = memoryData.map((row) => ({
        ...row,
        value: Number(row.percentage),
      }));

      var sumstat = nest() // nest function allows to group the calculation per level of a factor
        .key((d) => d.disk)
        .entries(mappedData);

      var memorySumstat = nest() // nest function allows to group the calculation per level of a factor
        .key((d) => d.disk)
        .entries(mappedMemoryData);

      const fullWidth = size?.width ?? mapRef.current.offsetWidth;
      let fullHeight = size?.height ?? mapRef.current.offsetHeight;
      fullHeight = fullHeight - 40;
      const svg = select(ref.current).attr("viewBox", [
        0,
        0,
        fullWidth,
        fullHeight,
      ]);

      svg
        .append("text")
        .attr("x", fullWidth / 2)
        .attr("y", -20)
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .style("text-decoration", "underline")
        .style("fill", "black")
        .text("RAM");

      svg
        .append("text")
        .attr("x", fullWidth / 2)
        .attr("y", fullHeight / 2 + 10)
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .style("text-decoration", "underline")
        .style("fill", "black")
        .text("Disques");

      //   var tooltip = select(`.server-tooltip-${index}`)
      //     .style("position", "absolute")
      //     .style("visibility", "hidden")
      //     .style("z-index", "10");

      var x = scaleTime()
        .domain(extent(data, (d) => new Date(d.timestamp)))
        .range([0, fullWidth]);

      svg
        .append("g")
        .attr("transform", "translate(0," + fullHeight + ")")
        .call(
          axisBottom(x)
            .ticks(timeHour.every(12))
            .tickFormat((d) =>
              d.getHours() === 0 ? timeFormat("%d %b")(d) : ""
            )
        );

      const halfHeight = fullHeight / 2;
      svg
        .append("g")
        .attr("transform", "translate(0," + (halfHeight - 30) + ")")
        .call(
          axisBottom(x)
            .ticks(timeHour.every(12))
            .tickFormat((d) =>
              d.getHours() === 0 ? timeFormat("%d %b")(d) : ""
            )
        );

      // Add Y axis
      var yDisk = scaleLinear()
        .domain([0, 100])
        .range([fullHeight, fullHeight / 2 + 30]);

      svg.append("g").attr("class", "yAxis").call(axisLeft(yDisk).ticks(5));

      var yMemory = scaleLinear()
        .domain([0, 100])
        .range([fullHeight / 2 - 30, 0]);

      svg.append("g").attr("class", "yAxis").call(axisLeft(yMemory).ticks(5));

      svg.selectAll(".domain").remove();

      svg
        .selectAll("g.yAxis g.tick")
        .append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", fullWidth)
        .attr("y2", 0);
      svg.selectAll("g.yAxis g.tick line").style("stroke", "lightgrey");

      // const pathFn = area()
      //   .x((d) => x(new Date(d.timestamp)))
      //   .y0((d) => yDisk(0))
      //   .y1((d) => yDisk(d.value));

      const lineDiskFn = area()
        .x((d) => x(new Date(d.timestamp)))
        .y((d) => yDisk(d.value));

      const lineMemoryFn = area()
        .x((d) => x(new Date(d.timestamp)))
        .y((d) => yMemory(d.value));

      var color = scaleOrdinal()
        .domain(order.map((disk) => disk.disk))
        .range([
          "rgb(51, 204, 129)",
          "rgb(96, 51, 204)",
          "rgb(51, 85, 204)",
          "rgb(204, 51, 189)",
        ]);

      const diskGroup = svg
        .selectAll(".line-group")
        .data(sumstat)
        .enter()
        .append("g")
        .attr("class", "line-group");

      // diskGroup
      //   .append("path")
      //   .datum((d) => d.values)
      //   .attr("class", "chart-area")
      //   .attr("stroke", (d) => color(d[0].disk))
      //   .attr("fill", (d) => color(d[0].disk))
      //   .attr("stroke-width", 2)
      //   .attr("d", pathFn)
      //   .style("opacity", 0.1);

      diskGroup
        .append("path")
        .datum((d) => d.values)
        .attr("class", "chart-line")
        .attr("stroke", (d) =>
          d[0].percentage > 90 ? "#ff4b4b" : color(d[0].disk)
        )
        .attr("fill", (d) =>
          d[0].percentage > 90 ? "#ff4b4b" : color(d[0].disk)
        )
        .attr("stroke-width", 2)
        .attr("d", lineDiskFn);

      diskGroup
        .selectAll("circle")
        .data((d) => d.values)
        .enter()
        .append("circle")
        .attr("class", "chart-dot")
        .attr("fill", (d) => (d.percentage > 90 ? "#ff4b4b" : color(d.disk)))
        .attr("stroke", "white")
        .attr("stroke-width", 1)
        .attr("r", 3)
        .attr("cx", (d) => x(new Date(d.timestamp)))
        .attr("cy", (d) => yDisk(d.value));

      const memoryGroup = svg
        .selectAll(".memory-group")
        .data(memorySumstat)
        .enter()
        .append("g")
        .attr("class", "line-group");

      memoryGroup
        .append("path")
        .datum((d) => d.values)
        .attr("class", "chart-line")
        .attr("stroke", (d) => color(d[0].disk))
        .attr("fill", (d) => color(d[0].disk))
        .attr("stroke-width", 2)
        .attr("d", lineMemoryFn);

      memoryGroup
        .selectAll("circle")
        .data((d) => d.values)
        .enter()
        .append("circle")
        .attr("class", "chart-dot")
        .attr("fill", (d) => color(d.disk))
        .attr("stroke", "white")
        .attr("stroke-width", 1.5)
        .attr("r", 3)
        .attr("cx", (d) => x(new Date(d.timestamp)))
        .attr("cy", (d) => yMemory(d.value));
    }
  }, [data, size, index, isOffline, order, title, memoryData]);

  return (
    <div ref={mapRef} style={{ width: "100%", height: "100%" }}>
      <svg
        ref={ref}
        style={{
          fill: "rgb(275,242,245)",
          width: "100%",
          height: "100%",
          overflow: "visible",
        }}
      />
      {/* <div className={`server-tooltip server-tooltip-${index}`} /> */}
    </div>
  );
}

DiskHistoryGraph.propTypes = {};

export default DiskHistoryGraph;
