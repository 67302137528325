import "react-base-table/styles.css";
import "./Contacts.css";

import BaseTable, { AutoResizer } from "react-base-table";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Tag,
  notification,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  deleteContact,
  getContacts,
  saveContact,
} from "../../redux/actions/contactActions";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../utils/axios";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const emptyValue = {
  lastName: "",
  name: "",
  email: "",
  tel: "",
  mobile: "",
  adr: "",
  comment: "",
};
function Contacts() {
  const [search, setSearch] = useState("");
  const [showContactModal, setShowContactModal] = useState(false);
  const contacts = useSelector((state) => state.contacts.contacts);
  const loading = useSelector((state) => state.contacts.loading);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  if (!Object.keys(contacts).length) {
    return null;
  }

  const handleShowModal = (rowData) => {
    setShowContactModal(true);
    form.setFieldsValue(rowData);
  };

  const handleAddContact = () => {
    let values = form.getFieldsValue(true);
    values = {
      ...emptyValue,
      ...values,
      lastName: values.lastName.toUpperCase(),
      groups: values.groups
        ? values.groups.map((grp) => grp.toLowerCase())
        : [],
    };
    handleSaveContact(values, true);
    setShowContactModal(false);
  };

  const handleDeleteContact = (record) => {
    notification.info({
      message: "Contacts",
      description: "Suppresion en cours",
    });
    dispatch(deleteContact(record));
  };

  const handleSaveContact = (record, reload = false) => {
    dispatch(saveContact(record, reload));
  };

  const exportContactActifs = async () => {
    window.open("/api/contacts/export", "_blank");
  };

  const exportContactsComplet = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Contacts");
    worksheet.autoFilter = {
      from: "A1",
      to: "A3",
    };
    worksheet.columns = [
      { header: "Nom", key: "lastName", width: 32 },
      { header: "Prénom", key: "name", width: 32 },
      { header: "Téléphone", key: "tel", width: 20 },
      { header: "Portable", key: "mobile", width: 20 },
      { header: "Email", key: "email", width: 20 },
    ];

    const data = Object.values(contacts).filter(
      (contact) =>
        (contact.name &&
          contact.name.toLowerCase().includes(search.toLowerCase())) ||
        (contact.lastName &&
          contact.lastName.toLowerCase().includes(search.toLowerCase())) ||
        (contact.groups &&
          contact.groups.some((tag) =>
            tag.toLowerCase().includes(search.toLowerCase())
          ))
    );
    worksheet.addRows(data);

    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "contacts.xlsx");
    });
  };

  const contactsColumns = [
    {
      title: "Nom",
      dataIndex: "lastName",
      dataKey: "lastName",
      key: "lastName",
      width: 150,
    },
    {
      title: "Prénom",
      dataIndex: "name",
      dataKey: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      dataKey: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Téléphone",
      dataIndex: "tel",
      dataKey: "tel",
      key: "tel",
      width: 150,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      dataKey: "mobile",
      key: "mobile",
      width: 150,
    },
    {
      title: "Adresse",
      dataIndex: "adr",
      dataKey: "adr",
      key: "adr",
      width: 300,
    },
    {
      title: "Commentaire",
      dataIndex: "comment",
      dataKey: "comment",
      key: "comment",
      width: 200,
    },
    {
      title: "Tags",
      dataIndex: "groups",
      dataKey: "groups",
      key: "groups",
      width: 300,
      cellRenderer: ({ columns, column, columnIndex, rowData, rowIndex }) => {
        return (
          <React.Fragment>
            {rowData.groups.map((tag) => (
              <Tag color="default">{tag}</Tag>
            ))}
          </React.Fragment>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      dataKey: "actions",
      key: "actions",
      width: 300,
      align: "center",
      dataGetter: ({ columns, column, columnIndex, rowData, rowIndex }) => {
        return (
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ marginLeft: 8 }}
              onClick={() => handleShowModal(rowData)}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Etes vous sur de vouloir supprimer ce contact ?"
              okText="supprimer"
              cancelText="Annuler"
              onConfirm={() => handleDeleteContact(rowData)}
            >
              <Button style={{ marginLeft: 8 }} type="danger">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const rowRenderer = ({ isScrolling, cells }) => {
    if (isScrolling) return <span style={{ marginLeft: 8 }}>Scrolling</span>;
    return cells;
  };

  const getOverlay = () => {
    if (loading) {
      return (
        <div className="table-loading-overlay">
          <Spin size="small" />
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <Modal
        title="Créer un contact"
        visible={showContactModal}
        onCancel={() => setShowContactModal(false)}
        onOk={handleAddContact}
      >
        <Form form={form}>
          <Form.Item label="Nom" name="lastName">
            <Input />
          </Form.Item>
          <Form.Item label="Prénom" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input />
          </Form.Item>
          <Form.Item label="Téléphone" name="tel">
            <Input />
          </Form.Item>
          <Form.Item label="Mobile" name="mobile">
            <Input />
          </Form.Item>
          <Form.Item label="Adresse" name="adr">
            <Input />
          </Form.Item>
          <Form.Item label="Commentaire" name="comment">
            <Input />
          </Form.Item>
          <Form.Item label="Tags" name="groups">
            <Select style={{ width: "100%" }} mode="tags" />
          </Form.Item>
        </Form>
      </Modal>
      <div className="contacts">
        <div className="clients-header">
          <Input
            placeholder="Recherche"
            allowClear
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            onClick={() => {
              form.resetFields();
              setShowContactModal(true);
            }}
            style={{ marginLeft: 8 }}
          >
            <PlusCircleOutlined />
            Ajouter
          </Button>
          <Button onClick={exportContactActifs} style={{ marginLeft: 8 }}>
            <FileExcelOutlined />
            Exporter
          </Button>
        </div>
        <div style={{ flex: 3 }}>
          <AutoResizer>
            {({ width, height }) => (
              <BaseTable
                useIsScrolling
                rowRenderer={rowRenderer}
                size="small"
                width={width}
                height={height}
                rowKey="key"
                columns={contactsColumns}
                data={Object.values(contacts).filter(
                  (contact) =>
                    (contact.name &&
                      contact.name
                        .toLowerCase()
                        .includes(search.toLowerCase())) ||
                    (contact.lastName &&
                      contact.lastName
                        .toLowerCase()
                        .includes(search.toLowerCase())) ||
                    (contact.groups &&
                      contact.groups.some((tag) =>
                        tag.toLowerCase().includes(search.toLowerCase())
                      ))
                )}
                overlayRenderer={getOverlay}
              />
            )}
          </AutoResizer>
        </div>
      </div>
    </React.Fragment>
  );
}

Contacts.propTypes = {};

export default Contacts;
