import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  createGroup,
  deleteGroup,
  updateGroup,
} from "../../redux/actions/groupActions";
import { useDispatch, useSelector } from "react-redux";

import EditableTable from "../utils/EditableTable";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getVagues } from "../../redux/actions/vagueActions";

function Groups() {
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [search, setSearch] = useState("");

  const groups = useSelector((state) => state.groups.groups);
  const loading = useSelector((state) => state.groups.loading);
  const vagues = useSelector((state) => state.vagues.vagues);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getVagues());
  }, [dispatch]);

  const handleCreateGroup = async () => {
    try {
      const values = await form.validateFields();
      dispatch(createGroup(values));
      setShowGroupModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSave = (group) => {
    dispatch(updateGroup(group));
  };

  const handleDelete = (key) => {
    dispatch(deleteGroup(key));
  };

  const handleChangeVague = (record, value) => {
    handleSave({ ...record, defaultVague: value });
  };

  const groupColumns = [
    {
      key: "oid",
      title: "oid",
      dataIndex: "key",
      width: 60,
    },
    {
      key: "name",
      title: "Nom",
      dataIndex: "name",
      editable: true,
    },
    {
      key: "defaultVague",
      title: "Vague par défaut",
      dataIndex: "defaultVague",
      render: (text, record) => {
        return (
          <Select
            value={record.default_vague}
            options={vagues}
            style={{ width: 300 }}
            onChange={(value) => handleChangeVague(record, value)}
          />
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Modal
        title="Créer un groupe"
        visible={showGroupModal}
        onCancel={() => setShowGroupModal(false)}
        onOk={handleCreateGroup}
      >
        <Form form={form}>
          <Form.Item label="Nom du groupe" name="name">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <div className="clients-header">
        <Input
          placeholder="Recherche"
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          onClick={() => setShowGroupModal(true)}
          style={{ marginLeft: 8 }}
        >
          <PlusCircleOutlined />
          Ajouter
        </Button>
      </div>
      <div className="scrollable">
        <EditableTable
          columns={groupColumns}
          dataSource={groups.filter((grp) =>
            grp.name.toLowerCase().includes(search.toLocaleLowerCase())
          )}
          size="small"
          onSave={handleSave}
          loading={loading}
          onDelete={handleDelete}
        />
      </div>
    </React.Fragment>
  );
}

export default Groups;
