import axios from "../../utils/axios";
import { getListLogs } from "./logsActions";
import { notification } from "antd";

export const SET_PROJECTS = "SET_PROJECTS";
export const SET_USERS = "SET_USERS";
export const SET_FIELDS = "SET_FIELDS";
export const SET_JIRA_LOADING = "SET_JIRA_LOADING";
export const SET_JIRA_TYPES = "SET_JIRA_TYPES";

export const setProjects = (projects) => {
  return {
    type: SET_PROJECTS,
    projects,
  };
};

export const setJiraLoading = (loading) => {
  return {
    type: SET_JIRA_LOADING,
    loading,
  };
};

export const setSubtypes = (types) => {
  return {
    type: SET_JIRA_TYPES,
    types,
  };
};

export const setFields = (fields) => {
  return {
    type: SET_FIELDS,
    fields,
  };
};

export const setUsers = (users) => {
  return {
    type: SET_USERS,
    users,
  };
};

export const getProjects = () => {
  return async function (dispatch, getState) {
    dispatch(setJiraLoading(true));
    const res = await axios.get("/api/jira/meta");
    if (res.data) {
      dispatch(setProjects(res.data));
    }
    dispatch(setJiraLoading(false));
  };
};

export const getSubTypes = (project) => {
  return async function (dispatch, getState) {
    dispatch(setJiraLoading(true));
    const res = await axios.get("/api/jira/meta/" + project);
    if (res.data) {
      dispatch(setSubtypes(res.data.values));
    }
    dispatch(setJiraLoading(false));
  };
};

export const getFields = (project, type) => {
  return async function (dispatch, getState) {
    dispatch(setJiraLoading(true));
    const res = await axios.get("/api/jira/meta/" + project + "/" + type);
    if (res.data) {
      dispatch(setFields(res.data.values.filter((field) => field.required)));
    }
    dispatch(setJiraLoading(false));
  };
};

export const getUsers = (project) => {
  return async function (dispatch, getState) {
    dispatch(setJiraLoading(true));
    const res = await axios.get("/api/jira/meta/" + project + "/users");
    if (res.data) {
      dispatch(setUsers(res.data));
    }
    dispatch(setJiraLoading(false));
  };
};

export const createIssue = (data) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.post("/api/jira/issue", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.data?.key) {
      notification.success({
        duration: 10,
        message: `Ticket ${res.data.key} crée`,
        description: (
          <a
            href={`https://jira.ctisante.com/browse/${res.data.key}`}
            target="_blank"
            rel="noreferrer"
          >
            Cliquez-ici pour aller sur JIRA
          </a>
        ),
      });
    }
    resolve(res.data.key);
  });
};

export const createLogIssue = (log, issue) => {
  return async function (dispatch, getState) {
    dispatch(setJiraLoading(true));
    await axios.post(`/api/logs/issue/${log}/${issue}`);
    dispatch(getListLogs());
  };
};
