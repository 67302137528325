import axios from "../../utils/axios";

export const SET_JOBFINESS = "SET_JOBFINESS";
export const SET_JOBFINESS_LOADING = "SET_JOBFINESS_LOADING";

export const setJobFiness = (jobFiness) => {
  return {
    type: SET_JOBFINESS,
    jobFiness,
  };
};

export const setJobFinessLoading = (loading) => {
  return {
    type: SET_JOBFINESS_LOADING,
    loading,
  };
};

export const getJobFiness = () => {
  return async function (dispatch, getState) {
    dispatch(setJobFinessLoading(true));
    const res = await axios.get("/api/jobFiness");
    if (res.data && res.data.length) {
      dispatch(setJobFiness(res.data));
    }
    dispatch(setJobFinessLoading(false));
  };
};

export const deleteJobFiness = (oid) => {
  return async function (dispatch, getState) {
    dispatch(setJobFinessLoading(true));
    await axios.delete("/api/jobFiness/" + oid);
    dispatch(getJobFiness());
  };
};
