import axios from "axios";
import localforage from "localforage";

const service = axios.create();

service.interceptors.request.use(
  async (config) => {
    const token = await localforage.getItem("token");
    config.headers["X-AUTH-TOKEN"] = token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default service;
