import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";

function Breadcrumb({ path, handleDrillChange }) {
  const items = path.map((item, index) => (
    <span key={item}>
      <Button
        className="breadcrumb-link"
        onClick={() => handleDrillChange(item)}
        style={{ marginLeft: index === 0 ? 0 : 8 }}
      >
        {item.toUpperCase()}
      </Button>
      {index !== path.length - 1 && "➜"}
    </span>
  ));
  return <div>{items}</div>;
}

Breadcrumb.propTypes = {
  path: PropTypes.array,
  handleDrillChange: PropTypes.func,
};

export default Breadcrumb;
