import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { Client } from "@stomp/stompjs";
import Clients from "../Clients/Clients";
import Dashboards from "../Dashboards/Dashboards";
import Errors from "../Clients/Errors";
import Gantt from "../Logs/Gantt";
import GroupDashboard from "../Dashboards/GroupDashboard";
import Home from "./Home/Home";
import List from "../Logs/List";
import LogModal from "../Logs/LogModal";
import Login from "./Login";
import Map2 from "../Logs/Map2";
import Messages from "../Clients/Messages";
import React from "react";
import Remote from "../Remote/Remote";
import Settings from "../Settings/Settings";
import SideMenu from "./SideMenu";
import Stats from "../Stats";
import axios from "../../utils/axios";
import { notification } from "antd";
import { useSelector } from "react-redux";

function Main(props) {
  const keycloak = useSelector((state) => state.keycloak);
  const [stompClient, setStompClient] = useState();
  const [runningClient, setRunningClient] = useState();
  const [token, setToken] = useState("");

  async function getToken() {
    let bodyContent = new URLSearchParams();
    bodyContent.set("username", "admin");
    bodyContent.set("password", "ER2Acc9Mq7WEk");
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const res = await axios.post("/guacamole/api/tokens", bodyContent, config);
    if (res.data?.authToken) {
      setToken(res.data.authToken);
    }
  }

  const initStomp = async () => {
    if (!stompClient) {
      let runningClient;
      const client = new Client({
        brokerURL: "wss://mq.ctisante.com/ws",
        connectHeaders: {
          login: "admin",
          passcode: "S9rjTPR9DZ!err",
        },
        // debug: function (str) {
        //   console.log(str);
        // },
        onConnect: function (frame) {
          // The return object has a method called `unsubscribe`
          runningClient = client.subscribe(
            "/exchange/reply",
            function (message) {
              const payload = JSON.parse(message.body);
              let type = payload.status === 0 ? "success" : "error";
              notification[type]({
                message: "Message",
                description: payload.message,
              });
            }
          );
        },
      });
      setStompClient(client);
      setRunningClient(runningClient);
    }
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      initStomp();
      getToken();
    }
    // eslint-disable-next-line
  }, [keycloak.authenticated]);

  const location = useLocation();

  useEffect(() => {
    if (keycloak.authenticated && stompClient) {
      stompClient.activate();
    }
    return () => {
      if (runningClient) {
        runningClient.unsubscribe();
      }
    };
  }, [keycloak.authenticated, runningClient, stompClient]);

  if (!keycloak.authenticated && location.pathname !== "/login") {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: location.pathname },
        }}
      />
    );
  }
  return (
    <>
      {keycloak.authenticated && <SideMenu />}
      <LogModal />
      <div className="main-content">
        <Switch>
          <Route exact path="/login" component={Login} />
          {keycloak.authenticated && (
            <>
              <Route exact path="/home" component={Home} />
              <Route path="/map" component={Map2} />
              <Route exact path="/gantt" component={Gantt} />
              <Route exact path="/list" component={List} />
              <Route exact path="/stats" component={Stats} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/clients" component={Clients} />
              <Route exact path="/dashboards" component={Dashboards} />
              <Route exact path="/dashboards/list/:group">
                <GroupDashboard list={true} />
              </Route>
              <Route
                exact
                path="/dashboards/:group"
                component={GroupDashboard}
              />
              <Route exact path="/clients/errors" component={Errors} />
              <Route exact path="/clients/messages" component={Messages} />
              <Route exact path="/remote">
                <Remote
                  resourceAccess={keycloak.keycloak.resourceAccess}
                  username={keycloak.keycloak.idTokenParsed.preferred_username}
                  token={token}
                />
              </Route>
            </>
          )}
        </Switch>
      </div>
    </>
  );
}

Main.propTypes = {};

export default Main;
