import axios from "../../utils/axios";
import { notification } from "antd";

export const SET_LICENCES = "SET_LICENCES";
export const ADD_LICENCE = "ADD_LICENCE";
export const UPDATE_LICENCE = "UPDATE_LICENCE";
export const SET_LICENCES_LOADING = "SET_LICENCES_LOADING";

export const setLicences = (licences) => {
  return {
    type: SET_LICENCES,
    licences,
  };
};

export const setLicencesLoading = (loading) => {
  return {
    type: SET_LICENCES_LOADING,
    loading,
  };
};

export const updateLicence = (licence) => {
  return {
    type: UPDATE_LICENCE,
    licence,
  };
};

export const addLicence = (licence) => {
  return {
    type: ADD_LICENCE,
    licence,
  };
};

export const getLicences = () => {
  return async function (dispatch, getState) {
    dispatch(setLicencesLoading(true));
    const res = await axios.get("/api/licences");
    if (res.data && Array.isArray(res.data)) {
      dispatch(
        setLicences(
          res.data.map((lic, index) => ({
            ...lic,
            modified: false,
            key: `${lic.finess_id}-${index}`,
          }))
        )
      );
    }
    dispatch(setLicencesLoading(false));
  };
};

export const saveLicence = (licence, update = true) => {
  return async function (dispatch, getState) {
    dispatch(setLicencesLoading(true));
    await axios.post("/api/licences", { licence });
    if (update) {
      dispatch(getLicences());
    }
  };
};

export const sendAllLicences = (licences) => {
  return async function (dispatch, getState) {
    dispatch(setLicencesLoading(true));
    notification.info({
      message: "Mise à jour des licences",
      description: "Mise à jour en cours...",
    });
    const res = await axios.post("/api/licences/all", { licences });
    if (res.status === 200) {
      notification.success({
        message: "Mise à jour des licences",
        description: "Mise à jour terminée",
      });
    } else {
      notification.error({
        message: "Mise à jour des licences",
        description: "Erreur lors de la mise à jour, veuillez réessayer",
      });
    }
    dispatch(getLicences());
  };
};

export const deleteLicence = (licence) => {
  return async function (dispatch, getState) {
    dispatch(setLicencesLoading(true));
    await axios.delete("/api/licences", { data: licence });
    dispatch(getLicences());
  };
};
