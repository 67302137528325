import axios from "../../utils/axios";

export const SET_MODULES = "SET_MODULES";
export const UPDATE_MODULE = "UPDATE_MODULE";
export const SET_MODULES_LOADING = "SET_MODULES_LOADING";

export const setModules = (modules) => {
  return {
    type: SET_MODULES,
    modules,
  };
};

export const updateModule = (module) => {
  return {
    type: UPDATE_MODULE,
    module,
  };
};

export const setModulesLoading = (loading) => {
  return {
    type: SET_MODULES_LOADING,
    loading,
  };
};

export const getModules = () => {
  return async function (dispatch, getState) {
    dispatch(setModulesLoading(true));
    const res = await axios.get("/api/modules");
    if (res.data && res.data.length) {
      dispatch(setModules(res.data));
    }
    dispatch(setModulesLoading(false));
  };
};
