import { Button, Tag, Tooltip } from "antd";

import { InfoCircleTwoTone } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";

function ModalTitle({ getLogs, build, diag }) {
  return (
    <div className="modal-header">
      <div className="modal-title">
        <span>
          Détails de la log:{" "}
          {build && `${build.code_client_cti} - ${build.filename}`}
        </span>
        {diag && (
          <Tooltip title={<DiagTooltip diag={diag} />}>
            <span style={{ marginLeft: 8 }}>
              <InfoCircleTwoTone />
            </span>
          </Tooltip>
        )}
      </div>
      <Button
        type="primary"
        onClick={() =>
          getLogs({ build: build.no_build, traitement_id: build.traitement_id })
        }
      >
        Recharger
      </Button>
    </div>
  );
}

ModalTitle.propTypes = {
  getLogs: PropTypes.func,
  build: PropTypes.object,
};

function DiagTooltip({ diag }) {
  return (
    <React.Fragment>
      <h4 style={{ color: "white" }}>Diagnostic Principal</h4>
      <div>
        <span>Code: </span>
        <Tag color="red">{diag.code}</Tag>
      </div>
      <div>
        <span>Texte: </span>
        {diag.texte}
      </div>
      <div>
        <span>Niveau de support: </span>
        <Tag color="lime">{`Niveau ${diag.support_level}`}</Tag>
      </div>
      <div>
        <span>Résolution: </span>
        {diag.resolution}
      </div>
    </React.Fragment>
  );
}

export default ModalTitle;
