import { Input, Spin } from "antd";
import React, { useState } from "react";

import ClientList from "./ClientList";
import axios from "../../utils/axios";
import { useEffect } from "react";

function RemoteClients() {
  /* ---------------------------------- Hooks --------------------------------- */

  const [data, setData] = useState([]);
  const [vpn, setVpn] = useState([]);
  const [search, setSearch] = useState("");

  const getData = React.useCallback(async () => {
    const res = await axios.get("/clients.json");
    setData(
      Object.values(res.data).sort((a, b) => a.name.localeCompare(b.name))
    );
  }, []);

  const getVpnData = React.useCallback(async () => {
    const res2 = await axios.get("/docker/containers/json?all=true");
    let tmpData = res2.data.filter((con) => con.Labels["name"]);
    setVpn(tmpData);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getVpnData();
    const interval = setInterval(() => {
      getVpnData();
    }, 10000);
    return () => clearInterval(interval);
  }, [getVpnData]);

  const getActiveVpn = (_group) => {
    const sVpn = vpn.find((sVpn) => {
      const name = sVpn.Names[0].replace("/", "");
      return name === _group.vpn_container_name;
    });
    return sVpn && sVpn.State === "running";
  };

  /* --------------------------------- Render --------------------------------- */
  return (
    <>
      <div className="vpn-header">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Recherche"
        />
      </div>
      {data.length && vpn.length ? (
        data.map((group) => {
          if (group.name !== "Elsan") {
            return (
              <div className="rds-data" key={group.name}>
                <ClientList
                  data={group}
                  vpnActive={getActiveVpn(group)}
                  search={search}
                />
              </div>
            );
          } else {
            return null;
          }
        })
      ) : (
        <Spin
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      )}
    </>
  );
}

RemoteClients.propTypes = {};

export default RemoteClients;
