import * as d3 from "d3";

import { DatePicker, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  getGanttData,
  setSelectedBuild,
  setShowLogsModal,
} from "../../redux/actions/logsActions";
import { useDispatch, useSelector } from "react-redux";

import TimelinesChart from "timelines-chart";
import { getJobStrategies } from "../../redux/actions/strategyActions";
import moment from "moment";
import useResize from "../../hooks/useResize";

const { RangePicker } = DatePicker;

function Gantt() {
  const mapRef = useRef();
  const ref = useRef();
  const size = useResize(mapRef);
  const dispatch = useDispatch();
  const [dates, setDates] = useState([moment().subtract("1", "day"), moment()]);
  const [excludeJobs, setExcludeJobs] = useState();
  const logs = useSelector((state) => state.logs.gantt);
  const jobs = useSelector((state) => state.strategies.jobs);
  const [loading, setLoading] = useState(false);

  const labelSort = (a, b) => {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  var ganttChart = TimelinesChart();
  const render = (data) => {
    d3.select(ref.current).selectAll("*").remove();
    const fullWidth = size?.width ?? mapRef.current.offsetWidth;
    const fullHeight = (size?.height ?? mapRef.current.offsetHeight) - 40;
    ganttChart
      .data(data)
      .width(fullWidth)
      .maxHeight(fullHeight)
      .leftMargin(150)
      .sort(labelSort)
      .zQualitative(true)(ref.current)
      .onSegmentClick((d) => {
        dispatch(
          setSelectedBuild({
            build: d.data.no_build,
            traitement_id: d.data.traitement_id,
          })
        );
        dispatch(setShowLogsModal());
      })
      .segmentTooltipContent((d) => {
        return `<div>${d.labelVal}</div>
            <div>Du ${moment(d.timeRange[0]).format(
              "DD-MM-YYYY à HH:mm"
            )}</div><div>Au ${moment(d.timeRange[1]).format(
          "DD-MM-YYYY à HH:mm"
        )}</div>
        <div>Durée: ${moment
          .utc(
            moment
              .duration(moment(d.timeRange[1]).diff(moment(d.timeRange[0])))
              .asMilliseconds()
          )
          .format("HH:mm:ss")}</div>`;
      });
  };

  useEffect(() => {
    if (!logs.length) {
      setLoading(true);
      dispatch(getGanttData());
      dispatch(getJobStrategies());
    }
  }, [dispatch, logs.length]);

  useEffect(() => {
    if (logs.length && mapRef.current) {
      const formattedLogs = formatGantt(logs, dates, excludeJobs);
      render(formattedLogs);
    }
    // eslint-disable-next-line
  }, [logs, size, dates, mapRef, excludeJobs]);

  function formatGantt(_builds, _dates, _exclude = []) {
    setLoading(true);
    const excludeJobs = jobs
      .filter((job) => _exclude.includes(job.oid))
      .map((job) => job.filename);
    const data = _builds
      .filter((build) => {
        if (excludeJobs.includes(build.filename)) {
          return false;
        } else {
          return (
            moment(build.start_time).isBetween(_dates[0], _dates[1]) ||
            moment(build.end_time).isBetween(_dates[0], _dates[1])
          );
        }
      })
      .reduce((prev, curr) => {
        let server = prev.find((srv) => srv.group === curr.hostname);
        if (!server) {
          server = {
            group: curr.hostname,
            data: [],
          };
          prev.push(server);
        }
        let clients = server.data.filter((client) =>
          client.label.startsWith(curr.env)
        );
        const overlapIndex = clients.findIndex((client) =>
          client.data.some(
            (build) =>
              moment(curr.start_time).isBetween(
                build.timeRange[0],
                build.timeRange[1]
              ) ||
              moment(curr.end_time).isBetween(
                build.timeRange[0],
                build.timeRange[1]
              ) ||
              (moment(curr.start_time).isBefore(build.timeRange[0]) &&
                moment(curr.end_time).isAfter(build.timeRange[1]))
          )
        );
        if (overlapIndex < 0 && !clients.length) {
          const client = {
            label: curr.env,
            data: [
              {
                timeRange: [moment(curr.start_time), moment(curr.end_time)],
                val: curr.filename,
                no_build: curr.no_build,
                traitement_id: curr.traitement_id,
              },
            ],
          };
          server.data.push(client);
        } else if (overlapIndex < 0) {
          let client = clients[0];
          client.data.push({
            timeRange: [moment(curr.start_time), moment(curr.end_time)],
            val: curr.filename,
            no_build: curr.no_build,
            traitement_id: curr.traitement_id,
          });
        } else {
          const clearClient = clients.find(
            (client) =>
              !client.data.some(
                (build) =>
                  moment(curr.start_time).isBetween(
                    build.timeRange[0],
                    build.timeRange[1]
                  ) ||
                  moment(curr.end_time).isBetween(
                    build.timeRange[0],
                    build.timeRange[1]
                  ) ||
                  (moment(curr.start_time).isBefore(build.timeRange[0]) &&
                    moment(curr.end_time).isAfter(build.timeRange[1]))
              )
          );
          if (clearClient) {
            clearClient.data.push({
              timeRange: [moment(curr.start_time), moment(curr.end_time)],
              val: curr.filename,
              no_build: curr.no_build,
              traitement_id: curr.traitement_id,
            });
          } else {
            const client = {
              label: `${curr.env} (${clients.length + 1})`,
              data: [
                {
                  timeRange: [moment(curr.start_time), moment(curr.end_time)],
                  val: curr.filename,
                  no_build: curr.no_build,
                  traitement_id: curr.traitement_id,
                },
              ],
            };
            server.data.push(client);
          }
        }
        return prev;
      }, []);
    setLoading(false);
    return data;
  }

  return (
    <div className="gantt">
      <div className="gantt-options">
        <RangePicker
          value={dates}
          onChange={(dates) => setDates(dates)}
          ranges={{
            "Aujourd'hui": [moment().subtract("1", "day"), moment()],
            "Deux derniers jours": [moment().subtract("2", "day"), moment()],
          }}
        />
        <Select
          mode="multiple"
          allowClear
          style={{ flex: 3, marginLeft: 12 }}
          placeholder="Jobs à exclure"
          options={jobs}
          value={excludeJobs}
          showSearch
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(val) => setExcludeJobs(val)}
        />
      </div>
      <div className="gantt-chart" ref={mapRef}>
        {loading && <Spin style={{ width: "100%", marginTop: "50vh" }} />}
        <svg
          ref={ref}
          style={{ fill: "rgb(240,242,245)", width: "100%", height: "100%" }}
        />
      </div>
    </div>
  );
}

export default Gantt;
