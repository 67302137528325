import {
  ADD_STRATEGY,
  SET_JOB_STRATEGIES,
  SET_STRATEGIES,
  SET_STRATEGIES_LOADING,
  SET_STRATEGY,
} from "../actions/strategyActions";

const INITIAL_STATE = {
  strategies: [],
  jobs: [],
  jobStrategies: [],
  loading: false,
  latest: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STRATEGIES:
      return {
        ...state,
        strategies: action.strategies,
      };

    case SET_STRATEGIES_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case ADD_STRATEGY:
      return {
        ...state,
        strategies: [action.strategy, ...state.strategies],
      };

    case SET_STRATEGY:
      const newData = [...state.strategies];
      let strategy = newData.findIndex(
        (srv) => srv.key === action.strategy.key
      );
      const item = newData[strategy];
      newData.splice(strategy, 1, { ...item, ...action.strategy });
      return {
        ...state,
        strategies: newData,
      };

    case SET_JOB_STRATEGIES:
      return {
        ...state,
        strategies: action.data.strategies,
        jobs: action.data.jobs,
        jobStrategies: action.data.jobStrategies,
      };

    default:
      return state;
  }
};

export default reducer;
