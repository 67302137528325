import {
  SET_GROUP_STATS,
  SET_LATEST,
  SET_RESTARTS,
  SET_STATS,
  SET_STATS_LOADING,
} from "../actions/statsActions";

const INITIAL_STATE = {
  stats: null,
  loading: false,
  latest: null,
  group: null,
  restarts: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STATS:
      return {
        ...state,
        stats: action.stats,
      };

    case SET_STATS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case SET_LATEST:
      return {
        ...state,
        latest: action.latest,
      };

    case SET_GROUP_STATS:
      return {
        ...state,
        group: action.stats,
      };

    case SET_RESTARTS:
      return {
        ...state,
        restarts: action.restarts,
      };

    default:
      return state;
  }
};

export default reducer;
