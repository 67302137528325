import { Alert, Button, Input, Popconfirm, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  deleteJobFiness,
  getJobFiness,
} from "../../redux/actions/jobFinessActions";
import { useDispatch, useSelector } from "react-redux";

import { DeleteOutlined } from "@ant-design/icons";
import { getClients } from "../../redux/actions/clientActions";

function JobFiness() {
  const jobFiness = useSelector((state) => state.jobFiness.jobFiness);
  const clients = useSelector((state) => state.clients.clients);
  const loading = useSelector((state) => state.jobFiness.loading);
  const [filteredJobFiness, setFilteredJobFiness] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedEnv, setSelectedEnv] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobFiness());
    dispatch(getClients());
  }, [dispatch]);

  useEffect(() => {
    let tmpJobFiness = jobFiness.map((jobfiness) => ({ ...jobfiness }));
    if (selectedEnv) {
      const fullEnv = clients.find((client) => client.key === selectedEnv);
      if (fullEnv) {
        tmpJobFiness = tmpJobFiness.filter((job) =>
          job.finess_name.includes(fullEnv.code_client_cti)
        );
      }
    }
    if (search) {
      tmpJobFiness = tmpJobFiness.filter((job) =>
        job.filename.toLowerCase().includes(search.toLowerCase())
      );
    }
    setFilteredJobFiness(tmpJobFiness.slice(0, 50));
  }, [search, selectedEnv, jobFiness, clients]);

  const handleDelete = (id) => {
    dispatch(deleteJobFiness(id));
  };

  const strategyColumns = [
    {
      key: "oid",
      title: "oid",
      dataIndex: "oid",
      width: 60,
    },
    {
      key: "finess",
      title: "Client",
      dataIndex: "finess_name",
      width: 300,
    },
    {
      key: "job",
      title: "Job",
      dataIndex: "filename",
      width: 300,
    },
    {
      key: "delete",
      title: "Supprimer",
      width: 140,
      align: "center",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Etes vous sur de vouloir supprimer ce job pour cet environnement ?"
            okText="Supprimer"
            cancelText="Annuler"
            onConfirm={() => handleDelete(record.oid)}
          >
            <Button>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Alert
        description="Seuls 50 lignes sont affichées en même temps. Veuillez utiliser la recherche."
        type="warning"
        showIcon
        style={{ marginBottom: 12 }}
      />
      <div className="clients-header">
        <Select
          style={{ width: "50%", marginRight: "8px" }}
          onChange={(val) => setSelectedEnv(val)}
          options={clients.map((client) => ({
            label: `${client.code_client_cti} - ${client.raison_sociale}`,
            value: client.key,
          }))}
          showSearch
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={loading}
          disabled={loading}
          allowClear
        />
        <Input
          allowClear
          placeholder="Recherche"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="scrollable">
        <Table
          columns={strategyColumns}
          dataSource={filteredJobFiness}
          size="small"
          loading={loading}
          pagination={false}
        />
      </div>
    </React.Fragment>
  );
}

export default JobFiness;
