import {
  AlertOutlined,
  BoxPlotOutlined,
  DashboardOutlined,
  DesktopOutlined,
  DotChartOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  MenuOutlined,
  SendOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import { Menu, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { getLatest } from "../../redux/actions/statsActions";
import moment from "moment";

function SideMenu() {
  const [latestImport, setLatestImport] = useState(null);
  const [width, setWidth] = useState(80);
  const latest = useSelector((state) => state.stats.latest);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!latest) {
      dispatch(getLatest());
    } else {
      const date = moment(latest).format("DD/MM/YYYY");
      const time = moment(latest).format("HH:mm");
      setLatestImport({ date, time });
    }
  }, [dispatch, latest]);

  const authenticatedMenu = (
    <React.Fragment>
      <Menu.Item key="Accueil" icon={<HomeOutlined />}>
        <Link to="/home">Accueil</Link>
      </Menu.Item>
      <Menu.SubMenu key="clients" icon={<UserOutlined />} title="Clients">
        <Menu.Item key="Clients" icon={<UserOutlined />}>
          <Link to="/clients">Gestion</Link>
        </Menu.Item>
        <Menu.Item key="Dashboards" icon={<DashboardOutlined />}>
          <Link to="/dashboards">Dashboards</Link>
        </Menu.Item>
        <Menu.Item key="errors" icon={<AlertOutlined />}>
          <Link to="/clients/errors">Erreurs</Link>
        </Menu.Item>
        <Menu.Item key="messages" icon={<SendOutlined />}>
          <Link to="/clients/messages">Messages</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="logs" icon={<DotChartOutlined />} title="Logs">
        <Menu.Item key="map" icon={<WindowsOutlined />}>
          <Link to="/map">Map</Link>
        </Menu.Item>
        <Menu.Item key="gantt" icon={<BoxPlotOutlined />}>
          <Link to="/gantt">Gantt</Link>
        </Menu.Item>
        <Menu.Item key="list" icon={<UnorderedListOutlined />}>
          <Link to="/list">Liste</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="settings" icon={<SettingOutlined />}>
        <Link to="/settings">Configuration</Link>
      </Menu.Item>
      <Menu.Item key="remote" icon={<DesktopOutlined />}>
        <Link to="/remote">Remote</Link>
      </Menu.Item>
    </React.Fragment>
  );

  return (
    <div className="side-menu" style={{ width }}>
      <div className="fold">
        {width === 80 ? (
          <MenuOutlined onClick={() => setWidth(300)} />
        ) : (
          <LeftOutlined onClick={() => setWidth(80)} />
        )}
      </div>
      <Menu
        mode="inline"
        theme="light"
        className="menu-content"
        onSelect={() => setWidth(80)}
        inlineCollapsed={width === 80}
      >
        {latestImport && width > 80 ? (
          <Menu.Item key="time" disabled style={{ float: "right" }}>
            {`Dernier import le ${latestImport.date} à ${latestImport.time}`}
          </Menu.Item>
        ) : latestImport ? (
          <Menu.Item key="time" disabled>
            <Tooltip
              title={`Dernier import le ${latestImport.date} à ${latestImport.time}`}
              placement="topLeft"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Menu.Item>
        ) : null}
        {authenticatedMenu}
      </Menu>
    </div>
  );
}

export default SideMenu;
