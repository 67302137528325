import accessReducer from "./reducers/accessReducer";
import clientReducer from "./reducers/clientReducer";
import { combineReducers } from "redux";
import contactReducer from "./reducers/contactReducer";
import errorsReducer from "./reducers/errorsReducer";
import gitReducer from "./reducers/gitReducer";
import groupReducer from "./reducers/groupReducer";
import jiraReducer from "./reducers/jiraReducer";
import jobFinessReducer from "./reducers/jobFinessReducer";
import keycloakReducer from "./reducers/keycloakReducer";
import licenceReducer from "./reducers/licenceReducer";
import logsReducer from "./reducers/logsReducer";
import messageReducer from "./reducers/messageReducer";
import moduleReducer from "./reducers/moduleReducer";
import serverReducer from "./reducers/serverReducer";
import statsReducer from "./reducers/statsReducer";
import strategyReducer from "./reducers/strategyReducer";
import userReducer from "./reducers/userReducer";
import vagueReducer from "./reducers/vagueReducer";

const rootReducer = combineReducers({
  logs: logsReducer,
  errors: errorsReducer,
  keycloak: keycloakReducer,
  stats: statsReducer,
  clients: clientReducer,
  servers: serverReducer,
  vagues: vagueReducer,
  groups: groupReducer,
  licences: licenceReducer,
  modules: moduleReducer,
  strategies: strategyReducer,
  jira: jiraReducer,
  jobFiness: jobFinessReducer,
  messages: messageReducer,
  users: userReducer,
  access: accessReducer,
  contacts: contactReducer,
  git: gitReducer,
});

export default rootReducer;
