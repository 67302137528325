import "./GroupDashboard.css";

import { Button, Divider, Result, Table, Tabs } from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  SmileTwoTone,
} from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { getGroupLists, getGroups } from "../../redux/actions/groupActions";
import { useDispatch, useSelector } from "react-redux";

import Environment from "./Environment";
import { Facebook } from "react-content-loader";
import GroupMinimap from "./GroupMinimap";
import Servers from "./Servers";
import { getGroupStats } from "../../redux/actions/statsActions";
import moment from "moment";

const Ok = () => (
  <CheckCircleTwoTone twoToneColor="#7cd992" style={{ fontSize: 48 }} />
);
// const Warning = () => (
//   <InfoCircleTwoTone twoToneColor="#f7e463" style={{ fontSize: 48 }} />
// );
const Error = () => (
  <CloseCircleTwoTone twoToneColor="#eb6060" style={{ fontSize: 48 }} />
);

const { TabPane } = Tabs;

function GroupDashboard({ list }) {
  const { group } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const stats = useSelector((state) => state.stats.group);
  const groups = useSelector((state) => state.groups.groups);
  const lists = useSelector((state) => state.groups.lists);
  const keycloak = useSelector((state) => state.keycloak);
  const loading = useSelector((state) => state.stats.loading);
  const chartRef = useRef();

  useEffect(() => {
    dispatch(getGroupStats(group, list));
  }, [group, dispatch, list]);

  useEffect(() => {
    if (stats?.jobErrors) {
      let treeErrors = stats.jobErrors.reduce((prev, curr, index) => {
        if (!curr.message) {
          prev.push({ ...curr, key: index });
        } else {
          const currentJob = prev.find(
            (job) =>
              job.code_client_cti === curr.code_client_cti &&
              job.filename === curr.filename
          );
          currentJob.children = currentJob.children ?? [];
          currentJob.children.push({ ...curr, key: index });
        }
        return prev;
      }, []);
      setData(treeErrors);
    }
  }, [stats]);

  useEffect(() => {
    if (!groups.length) {
      dispatch(getGroups());
      dispatch(getGroupLists());
    }
  }, [groups, dispatch]);

  const fullGroup = list
    ? lists.find((list) => list.oid === Number(group))
    : groups.find((grp) => grp.key === Number(group));

  const ready = !loading && stats;

  const columns = [
    {
      title: "Environnement",
      dataIndex: "code_client_cti",
      key: "client",
      width: 100,
    },
    {
      title: "Job",
      dataIndex: "filename",
      key: "filename",
      width: 200,
      ellipsis: true,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: 300,
      ellipsis: true,
    },
  ];

  return (
    <div className="group-tabs">
      <Tabs>
        <TabPane tab="Accueil" key="Accueil" forceRender>
          <div className="group-dashboard">
            <div className="group-header">
              <h1>
                {fullGroup?.label ?? fullGroup?.name ?? ""} - Bienvenue{" "}
                {keycloak.keycloak.tokenParsed.name}
              </h1>
              <Divider />
            </div>
            <div className="group-widgets">
              <div className="group-card group-first-row">
                <div className="card-title">Mises à jour</div>
                <div className="card-content">
                  {!ready ? (
                    <Facebook width={100} />
                  ) : (
                    <div className="card-content">
                      <div className="card-value">
                        {stats.updates.filter((hit) => hit.tag_ok === 2).length}
                      </div>
                      <div className="card-icon">
                        {stats.updates.filter((hit) => hit.tag_ok === 2)
                          .length ? (
                          <Error />
                        ) : (
                          <Ok />
                        )}
                      </div>
                      <div
                        className="card-desc"
                        style={{
                          color: stats.updates.filter((hit) => hit.tag_ok === 2)
                            .length
                            ? "#f39c12"
                            : "green",
                        }}
                      >
                        Modules en erreur de mise à jour
                      </div>
                      <div
                        className="card-desc desc-warning"
                        style={{
                          color: stats.updates.filter((hit) => hit.tag_ok === 1)
                            .length
                            ? "#f39c12"
                            : "green",
                        }}
                      >
                        {stats.updates.filter((hit) => hit.tag_ok === 1).length}{" "}
                        Modules en attente de mise à jour
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="group-card group-first-row">
                <div className="card-title">Licences</div>
                <div className="card-content">
                  {!ready ? (
                    <Facebook width={100} />
                  ) : (
                    <div className="card-content">
                      <div className="card-value">
                        <Link to="/clients/errors">
                          {
                            stats.licences.filter((lic) => lic.status === 2)
                              .length
                          }
                        </Link>
                      </div>
                      <div className="card-icon">
                        {stats.licences.filter((lic) => lic.status === 2)
                          .length ? (
                          <Error />
                        ) : (
                          <Ok />
                        )}
                      </div>
                      <div
                        className="card-desc"
                        style={{
                          color: stats.licences.filter(
                            (lic) => lic.status === 2
                          ).length
                            ? "#f39c12"
                            : "green",
                        }}
                      >
                        Licence(s) périmée(s)
                      </div>
                      <div
                        className="card-desc desc-warning"
                        style={{
                          color: stats.licences.filter(
                            (lic) => lic.status === 2
                          ).length
                            ? "#f39c12"
                            : "green",
                        }}
                      >
                        {
                          stats.licences.filter((lic) => lic.status === 2)
                            .length
                        }{" "}
                        licence(s) vont périmer dans le mois
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {list ? (
                <div className="group-card group-first-row"></div>
              ) : (
                <div className="group-card group-first-row">
                  <div className="card-title">Outils</div>
                  <div className="group-tools">
                    {!ready ? (
                      <Facebook width={100} />
                    ) : (
                      <React.Fragment>
                        <div className="group-resp">
                          <span>Votre responsable de compte: </span>
                          <span>{stats.resp[0]?.responsable_1}</span>
                        </div>
                        <div className="group-mail">
                          <Button disabled>Contacter par mail</Button>
                        </div>
                        <div className="group-mail">
                          <Button disabled>Accès à Jira</Button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
              <div className="group-card group-second-row">
                <div className="card-title">
                  Nuit du {moment().add(-1, "days").format("DD/MM")}{" "}
                  <span>🌙</span>
                </div>
                <div className="card-content">
                  <div className="group-night">
                    {!ready ? (
                      <Facebook width={400} />
                    ) : (
                      <div className="night-row">
                        <div className="night-col">
                          <div className="stats-jobs night-text">
                            {stats.jobs} traitements exécutés
                          </div>
                          <div
                            className={`night-text ${
                              stats.jobErrors.filter((err) => !err.message)
                                .length
                                ? "stats-jobs-error"
                                : "stats-jobs-success"
                            }`}
                          >
                            {
                              stats.jobErrors.filter(
                                (err) => err.message === null
                              ).length
                            }{" "}
                            traitements en erreur
                          </div>
                          <div className="stats-jobs-success night-text">
                            {Math.floor(
                              (1 -
                                stats.jobErrors.filter((err) => !err.message)
                                  .length /
                                  stats.jobs) *
                                100
                            )}{" "}
                            % de réussite
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="night-row group-map">
                      <div
                        ref={chartRef}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <GroupMinimap group={group} list={list} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane disabled={!ready} tab="Détail" key="details">
          <div className="night-table">
            {data.length ? (
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={!ready}
                expandable={{ defaultExpandAllRows: false }}
              />
            ) : (
              <Result
                icon={<SmileTwoTone twoToneColor="#7cd992" />}
                title={
                  <span className="stats-jobs-success">
                    Aucune erreur detectée cette nuit
                  </span>
                }
              />
            )}
          </div>
        </TabPane>
        <TabPane disabled={!ready} tab="Environnements" key="envs">
          <Environment stats={stats} ready={ready} />
        </TabPane>
        <TabPane disabled={!ready} tab="Serveurs" key="servers">
          <Servers list={list} group={group} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default GroupDashboard;
