import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Tag } from "antd";

function ErrorTooltip({ errors, selectedError }) {
  const [error, setError] = useState({});

  useEffect(() => {
    const usedError = errors.find((err) => err.oid === selectedError);
    if (usedError) {
      setError(usedError);
    }
  }, [selectedError, errors]);

  if (!Object.keys(error).length) {
    return null;
  }

  return (
    <React.Fragment>
      <div>
        <span>Code: </span>
        <Tag color="red">{error.code}</Tag>
      </div>
      <div>
        <span>Texte: </span>
        {error.texte}
      </div>
      <div>
        <span>Niveau de support: </span>
        <Tag color="lime">{`Niveau ${error.support_level}`}</Tag>
      </div>
      <div>
        <span>Résolution: </span>
        {error.resolution}
      </div>
    </React.Fragment>
  );
}

ErrorTooltip.propTypes = {
  errors: PropTypes.array,
  selectedError: PropTypes.number,
};

export default ErrorTooltip;
