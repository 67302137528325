import axios from "../../utils/axios";
import { notification } from "antd";

export const SET_CONTACTS = "SET_CONTACTS";
export const SET_CONTACTS_LOADING = "SET_CONTACTS_LOADING";
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";

export const setContacts = (contacts) => {
  return {
    type: SET_CONTACTS,
    contacts,
  };
};

export const setContactsLoading = (loading) => {
  return {
    type: SET_CONTACTS_LOADING,
    loading,
  };
};

export const addContact = (contact) => {
  return {
    type: ADD_CONTACT,
    contact,
  };
};

export const updateContact = (contact) => {
  return {
    type: UPDATE_CONTACT,
    contact,
  };
};

export const getContacts = () => {
  return async function (dispatch, getState) {
    dispatch(setContactsLoading(true));
    const res = await axios.get("/api/contacts");
    if (res.data && res.data) {
      const contacts = formatContacts(res.data);
      dispatch(setContacts(contacts));
    }
    dispatch(setContactsLoading(false));
  };
};

export const saveContact = (contact, reload = false) => {
  return async function (dispatch, getState) {
    dispatch(setContactsLoading(true));
    const res = await axios.post("/api/contacts", { contact });
    res.status === 200
      ? notification.success({
          message: "Succès",
          description: "Contact enregistré",
        })
      : notification.error({
          message: "Erreur",
          description: "Erreur lors de l'enregistrement",
        });
    if (reload) {
      dispatch(getContacts());
    } else {
      let newContacts = { ...getState().contacts.contacts };
      newContacts[contact.key] = contact;
      dispatch(setContacts(newContacts));
    }
  };
};

export const deleteContact = (contact) => {
  return async function (dispatch, getState) {
    dispatch(setContactsLoading(true));
    await axios.post("/api/contacts/delete", { contact });
    notification.success({
      message: "Contacts",
      description: "Contact supprimé",
    });
    dispatch(getContacts());
  };
};

function formatContacts(rawContacts) {
  let contacts = {};
  for (let key in rawContacts) {
    const contact = rawContacts[key];
    const uriField = contact[1].find((field) => field[0] === "uid");
    const uri = uriField ? uriField[3] : "";
    const nameField = contact[1].find((field) => field[0] === "n");
    const lastName = nameField ? nameField[3][0] : "";
    const name = nameField ? nameField[3][1] : "";
    const emailField = contact[1].find((field) => field[0] === "email");
    const email = emailField ? emailField[3] : "";
    const notesField = contact[1].find((field) => field[0] === "note");
    const comment = notesField ? notesField[3] : "";
    const adrField = contact[1].find((field) => field[0] === "adr");
    const adr = adrField ? adrField[3][2] : "";
    const telField = contact[1].find(
      (field) =>
        field[0] === "tel" &&
        typeof field[1] === "object" &&
        ((Array.isArray(field[1].type) && field[1].type.includes("WORK")) ||
          field[1].type === "WORK")
    );
    const tel = telField ? telField[3] : "";
    const mobileField = contact[1].find(
      (field) =>
        field[0] === "tel" &&
        typeof field[1] === "object" &&
        ((Array.isArray(field[1].type) && field[1].type.includes("CELL")) ||
          field[1].type === "CELL")
    );
    const mobile = mobileField ? mobileField[3] : "";
    const groupField = contact[1].find((field) => field[0] === "categories");
    const groups = groupField ? groupField.slice(3) : [];

    let newContact = {
      uri: key,
      name,
      lastName,
      email,
      comment,
      tel,
      mobile,
      groups,
      adr,
      key: uri,
      modified: false,
    };
    contacts[uri] = newContact;
  }
  return contacts;
}
