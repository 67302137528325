import "./GroupDashboard.css";

import React, { useEffect, useRef } from "react";

import PropTypes from "prop-types";
import { arc } from "d3-shape";
import { scaleOrdinal } from "d3-scale";
import { select } from "d3-selection";
import useResize from "../../hooks/useResize";

function ServerDiskGraph({ disks, index, isOffline }) {
  const ref = useRef();
  const mapRef = useRef();
  const size = useResize(mapRef);

  useEffect(() => {
    select(ref.current).selectAll("*").remove();
    if (disks.length) {
      const fullWidth = size?.width ?? mapRef.current.offsetWidth;
      const fullHeight = size?.height ?? mapRef.current.offsetHeight;
      const svg = select(ref.current).attr("viewBox", [
        0,
        0,
        fullWidth,
        fullHeight,
      ]);

      var PI = Math.PI;
      var arcMax = 90; // inner radius of the first arc
      var arcWidth = 15; // width
      var arcPad = 3;

      var tooltip = select(`.server-tooltip-${index}`)
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("z-index", "10");

      var color = scaleOrdinal()
        .domain([0, 1, 2, 3])
        .range([
          "rgb(51, 204, 129)",
          "rgb(96, 51, 204)",
          "rgb(51, 85, 204)",
          "rgb(204, 51, 189)",
        ]);

      const drawArc = arc()
        .innerRadius(function (d, i) {
          return arcMax - i * arcWidth - arcPad;
        })
        .outerRadius(function (d, i) {
          return arcMax - (i + 1) * arcWidth;
        })
        .cornerRadius(20)
        .startAngle(-0.05)
        .endAngle(function (d, i) {
          return (2 * PI * d.percentage) / 100;
        });

      var defs = svg.append("defs");

      var filter = defs.append("filter").attr("id", "dropshadow");

      filter
        .append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", 2)
        .attr("result", "blur");
      filter
        .append("feOffset")
        .attr("in", "blur")
        .attr("dx", 2)
        .attr("dy", 2)
        .attr("result", "offsetBlur");
      filter
        .append("feFlood")
        .attr("in", "offsetBlur")
        .attr("flood-color", "#3d3d3d")
        .attr("flood-opacity", "0.5")
        .attr("result", "offsetColor");
      filter
        .append("feComposite")
        .attr("in", "offsetColor")
        .attr("in2", "offsetBlur")
        .attr("operator", "in")
        .attr("result", "offsetBlur");

      var feMerge = filter.append("feMerge");

      feMerge.append("feMergeNode").attr("in", "offsetBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");

      svg
        .selectAll()
        .data(
          disks.map((disk) => ({
            ...disk,
            percentage: 100,
            prev_percentage: disk.percentage,
          }))
        )
        .enter()
        .append("path")
        .attr("d", drawArc)
        .attr("transform", `translate(${fullWidth / 2}, 120)`)
        .style("fill", (d, i) => (isOffline ? "#A9A9A9" : color(i)))
        .style("opacity", "0.1");

      const nodes = svg
        .selectAll()
        .data(disks)
        .enter()
        .append("path")
        .attr("d", drawArc)
        .attr("transform", `translate(${fullWidth / 2}, 120)`)
        .attr("filter", "url(#dropshadow)")
        .style("fill", (d) =>
          isOffline
            ? "#A9A9A9"
            : Number(d.percentage) > 90
            ? "#ff4b4b"
            : color(Number(d.percentage))
        );

      svg
        .selectAll()
        .data(disks)
        .enter()
        .append("text")
        .style("font-size", "7px")
        .style("fill", "white")
        .style("font-weight", 600)
        .attr(
          "transform",
          (d, i) => `translate(${fullWidth / 2 + 1}, ${42 + arcWidth * i})`
        )
        .text((d) => d.disk);

      nodes
        .on("click", (e, d) => console.log(d))
        .on("mouseover", function (d) {
          select(this).style("opacity", 0.7);
          return tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (e, node) {
          return tooltip
            .html(
              `<div>${node.disk} : ${node.available} Go disponibles sur ${node.total} Go</div>`
            )
            .style("top", e.pageY + 10 + "px")
            .style("left", e.pageX + "px");
        })
        .on("mouseout", function () {
          select(this).style("opacity", 1);
          return tooltip.style("visibility", "hidden");
        });
    }
  }, [disks, size, index, isOffline]);

  return (
    <div ref={mapRef} style={{ width: "100%", height: "100%" }}>
      <svg
        ref={ref}
        style={{
          fill: "rgb(275,242,245)",
          width: "100%",
          height: "100%",
          overflow: "visible",
        }}
      />
      <div className={`server-tooltip server-tooltip-${index}`} />
    </div>
  );
}

ServerDiskGraph.propTypes = {
  disks: PropTypes.array,
};

export default ServerDiskGraph;
