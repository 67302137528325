import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  notification,
} from "antd";
import {
  CheckCircleTwoTone,
  DeleteOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  addAccess,
  deleteAccess,
  saveAccess,
  updateAccess,
} from "../../redux/actions/accessActions";
import { addClient, updateClient } from "../../redux/actions/clientActions";
import {
  addLicence,
  deleteLicence,
  saveLicence,
  sendAllLicences,
  updateLicence,
} from "../../redux/actions/licenceActions";
import { useDispatch, useSelector } from "react-redux";

import DatePresets from "./DatePresets";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { encode } from "uint8-to-base64";
import moment from "moment";
import { providers } from "./Finess";

export const accessTypeOptions = [
  {
    label: "Remote",
    value: 1,
  },
  {
    label: "Teamviewer - Vault",
    value: 2,
  },
  {
    label: "Bastion - Vault",
    value: 3,
  },
  {
    label: "VPN & RDS - Vault",
    value: 4,
  },
];

export const resourceTypeOptions = [
  {
    label: "Groupe",
    value: 1,
  },
  {
    label: "Serveur",
    value: 2,
  },
  {
    label: "Environnement",
    value: 3,
  },
];

const pako = require("pako");

function FinessModal({
  selectedClient,
  showModal,
  handleShowModal,
  setSelectedClient,
}) {
  /* ---------------------------------- Hooks --------------------------------- */
  const [extraVagueData, setExtraVagueData] = useState("");
  const servers = useSelector((state) => state.servers.servers);
  const vagues = useSelector((state) => state.vagues.vagues);
  const [vagueDates, setVagueDates] = useState({});
  const groups = useSelector((state) => state.groups.groups);
  const licences = useSelector((state) => state.licences.licences);
  const licenceLoading = useSelector((state) => state.licences.loading);
  const keycloak = useSelector((state) => state.keycloak.keycloak);
  const users = useSelector((state) => state.users.users);
  const access = useSelector((state) => state.access.access);
  const modules = useSelector((state) => state.modules.modules);

  const [renewDate, setRenewDate] = useState(
    moment().endOf("year").add(1, "month")
  );
  const [fullAccess, setFullAccess] = useState([]);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    let days = [];
    var tuesday = moment().startOf("month").day("Tuesday");
    let week = 1;
    while (tuesday.isBefore(moment().add(1, "month"))) {
      if (week === 1 && tuesday.date() > 7) {
        tuesday.add(7, "d");
      }
      days.push({
        title: `v${week}`,
        allDay: true,
        start: tuesday.format("YYYY-MM-DD"),
        display: "background",
        color: "#f39c12",
      });
      tuesday.add(7, "d");
      week = week === 4 ? 1 : week + 1;
    }
    let vagues = {};
    days
      .filter((day) => moment(day.start).isAfter(moment()))
      .reverse()
      .forEach((day) => (vagues[day.title] = day.start));
    setVagueDates(vagues);
  }, []);

  useEffect(() => {
    if (selectedClient) {
      const modClient = {
        ...selectedClient,
        ignore_until: moment(selectedClient.ignore_until).isValid()
          ? moment(selectedClient.ignore_until)
          : moment(),
      };
      form.setFieldsValue(modClient);
      if (vagues.length) {
        const fullValue = vagues.find(
          (vague) => vague.oid === selectedClient.vague_id
        );
        if (fullValue) {
          const tuesday = vagueDates[fullValue.name];
          setExtraVagueData(`Prochaine mise à jour le ${tuesday}`);
        }
      }
    } else {
      form.setFieldsValue();
    }
  }, [selectedClient, form, groups, vagues, vagueDates]);

  useEffect(() => {
    if (Object.keys(selectedClient).length && access.length) {
      const clientAccess = access.filter(
        (acc) =>
          acc.resource_type === 3 && acc.resource_id === selectedClient.oid
      );
      const serverAccess = access.filter(
        (acc) =>
          acc.resource_type === 2 &&
          acc.resource_id === selectedClient.server_id
      );

      const groupAccess = access.filter(
        (acc) =>
          acc.resource_type === 1 && acc.resource_id === selectedClient.group_id
      );

      setFullAccess([...clientAccess, ...serverAccess, ...groupAccess]);
    }
  }, [selectedClient, access]);

  /* -------------------------------- Handlers -------------------------------- */
  const updateAllLicences = (renew = false) => {
    const currentLicences = licences.filter(
      (lic) => lic.finess_id === selectedClient.key
    );
    notification.info({
      message: "Mise à jour des licences",
      description:
        "Mise à jour des licences suite au changement de serveur ou finess",
    });
    const allLicences = currentLicences.map((licence) => {
      return handleSaveLicence(
        licence.key,
        licence.module_id,
        licence.finess_id,
        renew
      );
    });
    dispatch(sendAllLicences(allLicences));
  };
  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      if (
        values.finess !== selectedClient.finess ||
        values.server_id !== selectedClient.server_id
      ) {
        updateAllLicences();
      }
      const newValues = {
        ...selectedClient,
        ...values,
        ignore_until: values.ignore_until.format("YYYY-MM-DD 12:00"),
      };
      if (!newValues.key) {
        dispatch(addClient(newValues));
      } else {
        dispatch(updateClient(newValues));
      }
      handleShowModal();
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancelChange = (e) => {
    const modClient = {
      ...selectedClient,
      ...form.getFieldsValue(),
      annule: e.target.checked ? "O" : " ",
    };
    setSelectedClient(modClient);
  };

  const handleModuleChange = (key, value, field, moduleId, finessId) => {
    const currentLicence = licences.find((lic) => lic.key === key);

    const newLicence = {
      ...currentLicence,
      [field]: value,
      modified: true,
    };
    dispatch(updateLicence(newLicence));
  };

  const handleAccessChange = (key, value, field) => {
    const currentAccess = access.find((acc) => acc.key === key);
    let newAccess = {
      ...currentAccess,
      [field]: value,
      modified: true,
    };
    if (field === "resource_type") {
      let resource_id = selectedClient.oid;
      switch (value) {
        case 1:
          resource_id = selectedClient.group_id;
          break;

        case 2:
          resource_id = selectedClient.server_id;
          break;

        case 3:
          resource_id = selectedClient.oid;
          break;

        default:
          break;
      }
      newAccess.resource_id = resource_id;
    }

    dispatch(updateAccess(newAccess));
  };

  const handleSaveAccess = (record) => {
    dispatch(saveAccess(record));
  };

  const handleSaveLicence = (key, moduleId, finessId, renew = false) => {
    const currentLicence = licences.find((lic) => lic.key === key);
    if (currentLicence.module_id === 0) {
      notification.error({
        message: "Erreur",
        description: "Veuillez choisir un module avant d'enregistrer",
      });
      return;
    }
    try {
      const serverId = form.getFieldValue("server_id");
      const finess = form.getFieldValue("finess");
      const moduleCodeId = currentLicence.module_id;
      const date_expiration = renew
        ? renewDate.format("YYYY-MM-DD")
        : currentLicence.date_expiration;
      const server = servers.find((serv) => serv.key === serverId);
      const serverName = server.hostname.toUpperCase().replace(/[-\s]/g, "");
      const module = modules.find((mod) => mod.oid === moduleCodeId);
      const moduleCode = module.name;
      const date = date_expiration.replace(/\//g, "").replace(/-/gm, "");
      if (renew) {
        currentLicence.comment = `Renouvellement effectué le ${moment().format(
          "DD/MM/YYYY"
        )} par ${keycloak.idTokenParsed.preferred_username}`;
        currentLicence.date_expiration = date_expiration;
      }

      var enc = new TextEncoder(); // always utf-8
      const arr = enc.encode(`--${serverName}|${finess}|${moduleCode}|${date}`);
      const output = pako.deflate(arr);
      const key = encode(output);

      return { ...currentLicence, cle: key };
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Erreur",
        description:
          "Veuillez remplir tous les champs avant de saisir des licences",
      });
    }
  };

  const handleDeleteLicence = (moduleId, finessId) => {
    const currentLicence = licences.find(
      (lic) => lic.finess_id === finessId && lic.module_id === moduleId
    );
    dispatch(deleteLicence(currentLicence));
  };

  const handleAddLicence = () => {
    if (licences.some((lic) => lic.modified)) {
      notification.error({
        message: "Mise à jour des licences",
        description:
          "Veuillez enregistrer la licence en cours avant de poursuivre",
      });
      return;
    }
    const licencesCount = licences.length;
    dispatch(
      addLicence({
        key: `${selectedClient.key}-${licencesCount}`,
        finess_id: selectedClient.key,
        module_id: 0,
        comment: "",
        date_fabrication: moment().format("YYYY-MM-DD"),
        date_expiration: moment()
          .endOf("year")
          .add(1, "month")
          .format("YYYY-MM-DD"),
      })
    );
  };

  const handleAddAccess = () => {
    dispatch(
      addAccess({
        resource_id: selectedClient.oid,
        resource_type: 3,
        access_type_id: 1,
        comment: "",
        modified: true,
        key: -1,
      })
    );
  };

  const handleVagueChange = (value) => {
    const fullValue = vagues.find((vague) => vague.oid === value);
    if (!fullValue) {
      setExtraVagueData("");
      return;
    }
    const tuesday = vagueDates[fullValue.name];
    setExtraVagueData(`Prochaine mise à jour le ${tuesday}`);
  };

  const handleChangeDate = (date) => {
    form.setFieldsValue({ ignore_until: date });
  };

  /* ----------------------------- Partial render ----------------------------- */
  const moduleColumns = [
    {
      title: "Module",
      dataIndex: "module_id",
      key: "module",
      width: 100,
      render: (text, record, index) => {
        let options = modules.map((mod) => ({
          label: mod.name,
          value: mod.oid,
        }));
        options.unshift({
          label: "A renseigner",
          value: 0,
        });
        if (!record.cle) {
          return (
            <Select
              style={{ width: 150 }}
              options={options}
              value={record.module_id}
              onChange={(val) =>
                handleModuleChange(
                  record.key,
                  val,
                  "module_id",
                  record.module_id,
                  record.finess_id
                )
              }
            />
          );
        } else {
          const selectedOption = modules.find(
            (mod) => mod.oid === record.module_id
          );
          return selectedOption ? selectedOption.name : text;
        }
      },
    },
    {
      key: "prestataires",
      title: "Prestataires",
      dataIndex: "prestataires",
      width: 120,
      render: (text, record) => {
        const module = modules.find((mod) => mod.oid === record.module_id);
        const providerKey = providers[module?.name];
        const provider = selectedClient.settings?.ENVIRONMENT
          ? selectedClient.settings?.ENVIRONMENT[providerKey]
          : null;
        const provider2 = selectedClient.settings?.ENVIRONMENT
          ? selectedClient.settings?.ENVIRONMENT[`${providerKey}2`]
          : null;

        if (provider2) {
          return `${provider} & ${provider2}`;
        } else {
          return provider;
        }
      },
    },
    {
      title: "Expiration",
      dataIndex: "expiration",
      key: "expiration",
      width: 100,
      render: (text, record, index) => {
        return (
          <DatePicker
            value={moment(record.date_expiration)}
            onChange={(val, stringVal) =>
              handleModuleChange(
                record.key,
                stringVal,
                "date_expiration",
                record.module_id,
                record.finess_id
              )
            }
          />
        );
      },
    },
    {
      title: "Commentaire",
      dataIndex: "comment",
      key: "comment",
      width: 300,
      render: (text, record, index) => {
        return (
          <Input
            value={record.comment}
            disabled
            onChange={debounce(
              (e) =>
                handleModuleChange(
                  record.key,
                  e.target.value,
                  "comment",
                  record.module_id,
                  record.finess_id
                ),
              500
            )}
          />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 50,
      align: "center",
      render: (text, record, index) => {
        return (
          <React.Fragment>
            <Button
              onClick={async () => {
                await form.validateFields();
                const lic = handleSaveLicence(
                  record.key,
                  record.module_id,
                  record.finess_id
                );
                dispatch(saveLicence(lic));
              }}
              disabled={!record.modified}
            >
              <CheckCircleTwoTone
                twoToneColor={record.modified ? "#52c41a" : "gray"}
              />
            </Button>
            <Popconfirm
              title="Etes vous sur de vouloir supprimer cette licence ?"
              okText="supprimer"
              cancelText="Annuler"
              onConfirm={() =>
                handleDeleteLicence(record.module_id, record.finess_id)
              }
            >
              <Button style={{ marginLeft: 8 }} type="danger">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </React.Fragment>
        );
      },
    },
  ];

  const accesColumns = [
    {
      title: "Type",
      dataIndex: "access_type_id",
      key: "access_type_id",
      width: 150,
      render: (text, record, index) => {
        return (
          <Select
            style={{ width: 150 }}
            options={accessTypeOptions}
            value={record.access_type_id}
            onChange={(val) =>
              handleAccessChange(record.key, val, "access_type_id")
            }
          />
        );
      },
    },
    {
      title: "Partagé",
      dataIndex: "resource_type",
      key: "resource_type",
      width: 150,
      render: (text, record, index) => {
        return (
          <Select
            style={{ width: 150 }}
            options={resourceTypeOptions}
            value={record.resource_type}
            onChange={(val) =>
              handleAccessChange(record.key, val, "resource_type")
            }
          />
        );
      },
    },
    {
      title: "Commentaire",
      dataIndex: "comment",
      key: "comment",
      width: 200,
      render: (text, record, index) => {
        return (
          <Input
            value={record.comment}
            onChange={(e) =>
              handleAccessChange(record.key, e.target.value, "comment")
            }
          />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 40,
      align: "center",
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", flexFlow: "column" }}>
            <Button
              disabled={!record.modified}
              onClick={(e) => handleSaveAccess(record)}
            >
              <CheckCircleTwoTone
                twoToneColor={record.modified ? "#52c41a" : "gray"}
              />
            </Button>
            <Popconfirm
              title="Etes vous sur de vouloir supprimer cet accès ?"
              okText="supprimer"
              cancelText="Annuler"
              onConfirm={() => {
                dispatch(deleteAccess(record));
              }}
            >
              <Button style={{ marginTop: 8 }} type="danger">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  /* --------------------------------- Render --------------------------------- */

  return (
    <Modal
      title={null}
      okText="Enregistrer"
      cancelText="Annuler"
      onCancel={handleShowModal}
      onOk={handleSave}
      visible={showModal}
      width="90vw"
      maskClosable={false}
      forceRender
    >
      <Form form={form}>
        <h2>Etablissement</h2>
        <Divider />
        <div className="form-item">
          <Card
            type="inner"
            size="small"
            title="Généralités"
            style={{ marginBottom: 12 }}
          >
            <Form.Item
              label="Desactivé"
              name="desactive"
              valuePropName="checked"
            >
              <Checkbox
                checked={selectedClient.annule === "O"}
                onChange={handleCancelChange}
              />
            </Form.Item>
            <Form.Item hidden name="annule">
              <Input />
            </Form.Item>
            <Form.Item
              label="Finess"
              name="finess"
              required
              rules={[
                { required: true, message: "Veuillez renseigner ce champ" },
              ]}
            >
              <Input disabled={selectedClient.annule === "O"} />
            </Form.Item>
            <Form.Item
              name="raison_sociale"
              label="Raison sociale"
              required
              rules={[
                { required: true, message: "Veuillez renseigner ce champ" },
              ]}
            >
              <Input disabled={selectedClient.annule === "O"} />
            </Form.Item>
            <Form.Item
              name="code_client_cti"
              label="Code client CTI"
              required
              rules={[
                { required: true, message: "Veuillez renseigner ce champ" },
              ]}
            >
              <Input disabled={selectedClient.annule === "O"} />
            </Form.Item>
            <Form.Item
              name="raison_sociale_abregee"
              label="Raison sociale abrégée"
            >
              <Input disabled={selectedClient.annule === "O"} />
            </Form.Item>
            <Form.Item
              name="group_id"
              label="Groupe"
              required
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez renseigner ce champ (renseigner aucun si client indépendant)",
                },
              ]}
            >
              <Select
                disabled={selectedClient.annule === "O"}
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={groups}
              />
            </Form.Item>
          </Card>
        </div>
        <div className="form-row">
          <div className="form-column" style={{ marginRight: 12 }}>
            <div className="form-item">
              <Card
                type="inner"
                size="small"
                title="Autres"
                style={{ marginBottom: 12 }}
              >
                <Form.Item
                  name="code_interne_client"
                  label="Code interne client"
                >
                  <Input disabled={selectedClient.annule === "O"} />
                </Form.Item>
                <div style={{ display: "flex" }}>
                  <Form.Item
                    name="responsable_1"
                    label="Resp. (trigramme)"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Veuillez renseigner ce champ",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: 80 }}
                      disabled={selectedClient.annule === "O"}
                    />
                  </Form.Item>
                  <Form.Item name="responsable_2" style={{ marginLeft: 8 }}>
                    <Input
                      style={{ width: 80 }}
                      disabled={selectedClient.annule === "O"}
                    />
                  </Form.Item>
                </div>
              </Card>
            </div>
            <div className="form-item">
              <Card
                type="inner"
                size="small"
                title={
                  <>
                    <span>Accès </span>
                    <Button
                      size="small"
                      onClick={handleAddAccess}
                      type="primary"
                      style={{ marginLeft: 8 }}
                    >
                      <PlusCircleOutlined /> Ajouter
                    </Button>
                  </>
                }
                style={{ marginBottom: 12 }}
              >
                <div style={{ marginBottom: 12 }}></div>
                <Table
                  size="small"
                  columns={accesColumns}
                  pagination={false}
                  dataSource={fullAccess}
                />
              </Card>
            </div>
          </div>
          <div className="form-column">
            <div className="form-item">
              <Card
                type="inner"
                size="small"
                title={
                  selectedClient.vague_name
                    ? `Exploitation - Vague ${selectedClient.vague_name} conseillée`
                    : "Exploitation"
                }
                style={{
                  marginBottom: 12,
                  height: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Form.Item
                  label="Serveur"
                  name="server_id"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Veuillez renseigner ce champ",
                    },
                  ]}
                >
                  <Select
                    disabled={selectedClient.annule === "O"}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={servers}
                  />
                </Form.Item>

                <Form.Item shouldUpdate>
                  {(form) => (
                    <Form.Item
                      label="Vague"
                      name="vague_id"
                      extra={extraVagueData}
                    >
                      <Select
                        disabled={selectedClient.annule === "O"}
                        showSearch
                        onChange={handleVagueChange}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        options={[
                          { value: 0, label: "Pas de vague" },
                          ...vagues,
                        ]}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item
                  label="Ignorer les logs"
                  name="is_log_ignored"
                  valuePropName="checked"
                >
                  <Checkbox disabled={selectedClient.annule === "O"} />
                </Form.Item>
                <Form.Item
                  label="Mises à jour débrayées"
                  name="ignore_update"
                  valuePropName="checked"
                >
                  <Checkbox disabled={selectedClient.annule === "O"} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {(form) => (
                    <Form.Item label="Jusqu'au (à midi)" name="ignore_until">
                      <DatePicker
                        showToday={false}
                        renderExtraFooter={() => (
                          <DatePresets handleChangeDate={handleChangeDate} />
                        )}
                        disabled={
                          !form.getFieldsValue().ignore_update ||
                          selectedClient.annule === "O"
                        }
                      />
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item shouldUpdate>
                  {(form) => (
                    <Form.Item
                      label="Utilisateurs à prevenir"
                      name="warn_users"
                      extra="Utilisateurs à prévenir en plus du responsable de compte"
                    >
                      <Select
                        mode="multiple"
                        options={users}
                        disabled={
                          !form.getFieldsValue().ignore_update ||
                          selectedClient.annule === "O"
                        }
                      />
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item shouldUpdate>
                  {(form) => (
                    <Form.Item label="Raison du débrayage" name="comment">
                      <Input
                        disabled={
                          !form.getFieldsValue().ignore_update ||
                          selectedClient.annule === "O"
                        }
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Card>
            </div>
            <div className="form-item">
              <Card
                type="inner"
                size="small"
                title="Commentaire - Markdown possible"
                style={{ marginBottom: 12 }}
              >
                <Form.Item name="commentaire">
                  <Input.TextArea
                    style={{ height: "100%" }}
                    autoSize={{ minRows: 12 }}
                  />
                </Form.Item>
              </Card>
            </div>
          </div>
        </div>
        <span style={{ fontSize: "1.5em", fontWeight: 500, marginRight: 12 }}>
          Modules
        </span>
        <span>
          <Button onClick={handleAddLicence} type="primary">
            <PlusCircleOutlined /> Ajouter
          </Button>
        </span>
        <span>
          <Button
            onClick={() => {
              updateAllLicences(true);
            }}
            style={{ marginLeft: 8, marginRight: 8 }}
            loading={licenceLoading}
          >
            <ReloadOutlined /> Renouveler toutes les licences
          </Button>
          <span>au</span>
          <DatePicker
            disabled={licenceLoading}
            value={renewDate}
            onChange={(val, stringVal) => setRenewDate(val)}
            style={{ marginLeft: 8 }}
          />
        </span>
        <Divider />
        <Table
          columns={moduleColumns}
          pagination={false}
          dataSource={licences.filter(
            (lic) => lic.finess_id === selectedClient.key
          )}
        />
      </Form>
    </Modal>
  );
}

FinessModal.propTypes = {
  selectedClient: PropTypes.object,
  showModal: PropTypes.bool,
  handleShowModal: PropTypes.func,
};

export default FinessModal;
