import React, { useEffect, useRef, useState } from "react";
import { scaleBand, scaleLinear } from "d3-scale";

import { Modal } from "antd";
import PropTypes from "prop-types";
import axios from "../../utils/axios";
import moment from "moment";
import { select } from "d3-selection";
import useResize from "../../hooks/useResize";

function Minimap({ node }) {
  const ref = useRef();
  const mapRef = useRef();
  const [mapData, setMapData] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [historyData, setHistoryData] = useState();
  const size = useResize(mapRef);

  useEffect(() => {
    setMapData([]);
    const getData = async () => {
      let url = "";
      if (node.group_id && node.finess_id) {
        url = `/api/minimap/${node.group_id}/${node.finess_id}`;
      } else {
        url = `/api/minimap/${node.oid}`;
      }
      const res = await axios.get(url);
      setMapData(res.data);
    };
    if (node.server_id || node.finess_id || node.oid) {
      getData();
    }
  }, [node]);

  useEffect(() => {
    const getData = async (_node) => {
      let url = "";
      if (_node.group_id && _node.finess_id) {
        url = `/api/minimap/${_node.group_id}/${_node.finess_id}?night=${historyData}`;
      } else {
        url = `/api/minimap/${_node.oid}?night=${historyData}`;
      }
      const res = await axios.get(url);
      console.log(historyData, res);
    };
    if (node.server_id || node.finess_id || node.oid) {
      getData(node);
    } else {
      console.log(node);
    }
  }, [historyData, node]);

  useEffect(() => {
    select(ref.current).selectAll("*").remove();
    if (mapData.length && node.name !== "Racine") {
      const fullWidth = size?.width ?? mapRef.current.offsetWidth;
      const fullHeight = size?.height ?? mapRef.current.offsetHeight;
      const svg = select(ref.current).attr("viewBox", [
        0,
        0,
        fullWidth,
        fullHeight,
      ]);

      const color = (node, stroke = false) => {
        const colors = [
          "rgb(47, 204, 102)",
          "rgb(233, 146, 29)",
          "rgb(230, 107, 44)",
          "#A9A9A9",
        ];
        return colors[
          stroke ? Number(node.status_id) : Number(node.resolved_id)
        ];
      };
      const data = [...mapData].reverse();

      var tooltip = select(".tooltip")
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("z-index", "10");

      const yScale = scaleLinear().range([34, 0]).domain([0, 1]);
      const xScale = scaleBand()
        .range([0, fullWidth])
        .domain(data.map((s, i) => i))
        .paddingInner(0.2);

      const nodes = svg
        .selectAll()
        .data(data)
        .enter()
        .append("rect")
        .attr("x", (s, i) => xScale(i))
        .attr("width", 5)
        .attr("height", (d) => 34 - yScale(0))
        .attr("y", yScale(0))
        .style("fill", (d) => color(d))
        .attr("rx", 1)
        .attr("ry", 1);

      const nodesResolved = svg
        .selectAll()
        .data(data)
        .enter()
        .append("rect")
        .attr("x", (s, i) => xScale(i))
        .attr("width", 5)
        .attr("height", (d) => 34 - yScale(0))
        .attr("y", yScale(0))
        .style("fill", (d) => color(d, true))
        .attr("rx", 1)
        .attr("ry", 1);

      svg
        .append("text")
        .attr("x", 0)
        .attr("y", 44)
        .style("font-size", "10px")
        .style("fill", "grey")
        .text("J-15");

      svg
        .append("text")
        .attr("x", fullWidth / 2)
        .attr("y", 44)
        .style("font-size", "10px")
        .style("fill", "grey")
        .attr("text-anchor", "middle")
        .text("Historique");

      svg
        .append("text")
        .attr("x", fullWidth - 52)
        .attr("y", 44)
        .style("font-size", "10px")
        .style("fill", "grey")
        .text("Aujourd'hui");

      svg
        .append("line")
        .style("stroke", "lightgrey")
        .style("stroke-width", 1)
        .attr("x1", 30)
        .attr("y1", 40)
        .attr("x2", fullWidth / 2 - 30)
        .attr("y2", 40);

      svg
        .append("line")
        .style("stroke", "lightgrey")
        .style("stroke-width", 1)
        .attr("x1", fullWidth / 2 + 30)
        .attr("y1", 40)
        .attr("x2", fullWidth - 60)
        .attr("y2", 40);

      nodes
        .on("click", (e, d) => {
          //setShowHistory(true);
          setHistoryData(d.night);
        })
        .on("mouseover", function (d) {
          select(this).style("opacity", 0.7);
          return tooltip.style("visibility", "visible");
        })
        .on("mousemove", function (e, node) {
          return tooltip
            .html(
              `<div>${moment(node.night).format("DD/MM")}</div><div>${
                node.errors
              } erreurs</div>`
            )
            .style("top", e.pageY + 10 + "px")
            .style("left", e.pageX - 60 + "px");
        })
        .on("mouseout", function () {
          select(this).style("opacity", 1);
          return tooltip.style("visibility", "hidden");
        });

      nodes
        .transition()
        .duration(600)
        .attr("y", function (d) {
          return yScale(1);
        })
        .attr("height", function (d) {
          return 34 - yScale(1);
        })
        .delay(function (d, i) {
          return 15 - i * 3;
        });

      nodesResolved
        .transition()
        .duration(600)
        .attr("y", function (d) {
          return yScale(0.5);
        })
        .attr("height", function (d) {
          return d.resolved_id !== d.status_id ? yScale(0.5) : yScale(1);
        })
        .delay(function (d, i) {
          return 15 - i * 3;
        });
    }
  }, [mapData, size, node]);

  console.log(historyData);

  return (
    <React.Fragment>
      <Modal
        width="15vw"
        title="Historique"
        visible={showHistory}
        onOk={() => setShowHistory(false)}
        onCancel={() => setShowHistory(false)}
      ></Modal>
      <div ref={mapRef} style={{ width: "100%", height: "100%" }}>
        <svg
          ref={ref}
          style={{
            fill: "rgb(240,242,245)",
            width: "100%",
            height: "100%",
            overflow: "visible",
          }}
        />
      </div>
    </React.Fragment>
  );
}

Minimap.propTypes = {
  node: PropTypes.object,
};

export default Minimap;
