import axios from "../../utils/axios";
import { notification } from "antd";

export const SET_CLIENTS = "SET_CLIENTS";
export const SET_CLIENTS_LOADING = "SET_CLIENTS_LOADING";
export const SET_CLIENTS_ERRORS = "SET_CLIENTS_ERRORS";

export const setClients = (clients) => {
  return {
    type: SET_CLIENTS,
    clients,
  };
};

export const setClientsLoading = (loading) => {
  return {
    type: SET_CLIENTS_LOADING,
    loading,
  };
};

export const setClientErrors = (errors) => {
  return {
    type: SET_CLIENTS_ERRORS,
    errors,
  };
};

export const getClients = () => {
  return async function (dispatch, getState) {
    dispatch(setClientsLoading(true));
    const res = await axios.get("/api/clients");
    if (res.data && res.data.length) {
      let clients = res.data.map((client) => {
        try {
          return {
            ...client,
            settings: JSON.parse(client.latest_settings),
            warn_users: JSON.parse(client.warn_users),
          };
        } catch (e) {
          return { ...client, settings: {} };
        }
      });
      dispatch(setClients(clients));
    }
    dispatch(setClientsLoading(false));
  };
};

export const updateClient = (client) => {
  return async function (dispatch, getState) {
    dispatch(setClientsLoading(true));
    await axios.put("/api/clients/" + client.key, { client });
    dispatch(getClients());
  };
};

export const addClient = (client) => {
  return async function (dispatch, getState) {
    dispatch(setClientsLoading(true));
    try {
      await axios.post("/api/clients", { client });
      dispatch(getClients());
    } catch (e) {
      dispatch(setClientsLoading(false));
      notification.error({
        message: "Erreur",
        description: "Erreur lors de la création du client",
      });
    }
  };
};

export const getClientErrors = () => {
  return async function (dispatch, getState) {
    dispatch(setClientsLoading(true));
    const res = await axios.get("/api/versions/errors");
    dispatch(setClientErrors(res.data));
    dispatch(setClientsLoading(false));
  };
};
