import axios from "../../utils/axios";
import { nest } from "d3-collection";

export const SET_LOGS = "SET_LOGS";
export const SET_LIST = "SET_LIST";
export const SET_GANTT = "SET_GANTT";
export const SET_LOGS_LOADING = "SET_LOGS_LOADING";
export const SET_SELECTED_BUILD = "SET_SELECTED_BUILD";
export const SET_SHOW_LOGS_MODAL = "SET_SHOW_LOGS_MODAL";

const setLogs = (logs) => {
  return {
    type: SET_LOGS,
    logs,
  };
};

const setGantt = (logs) => {
  return {
    type: SET_GANTT,
    logs,
  };
};

const setList = (logs) => {
  return {
    type: SET_LIST,
    logs,
  };
};

const setLogsLoading = (loading) => {
  return {
    type: SET_LOGS_LOADING,
    loading,
  };
};

export const setSelectedBuild = (build = null) => {
  return {
    type: SET_SELECTED_BUILD,
    build,
  };
};

export const setShowLogsModal = () => {
  return {
    type: SET_SHOW_LOGS_MODAL,
  };
};

export const getLogs = () => {
  return async function (dispatch, getState) {
    dispatch(setLogsLoading(true));
    const res = await axios.get("/api/logs");
    const groups = formatData(res.data);
    dispatch(setLogs(formatGroup(groups)));
    dispatch(setLogsLoading(false));
  };
};

export const getGanttData = () => {
  return async function (dispatch, getState) {
    dispatch(setLogsLoading(true));
    const res = await axios.get("/api/gantt");
    dispatch(setGantt(res.data));
    dispatch(setLogsLoading(false));
  };
};

export const getListLogs = () => {
  return async function (dispatch, getState) {
    dispatch(setLogsLoading(true));
    const res = await axios.get("/api/list");
    dispatch(setList(res.data));
    dispatch(setLogsLoading(false));
  };
};

/* --------------------------------- Helpers -------------------------------- */

function formatData(_data) {
  return _data.reduce((prev, curr, index) => {
    let group = prev.find((group) => group.oid === curr.group_id);
    if (group) {
      if (!group.children) {
        group.children = [];
      }
    } else {
      group = {
        name: curr.group_name,
        children: [],
        oid: curr.group_id,
        comment: curr.group_name,
      };
      prev.push(group);
    }
    let client = group.children.find(
      (child) => child.finess_id === curr.finess_id
    );
    if (!client) {
      group.children.push({
        ...curr,
        name: curr.code_client_cti,
        key: curr.code_client_cti + index,
        children: [curr],
      });
    } else {
      client.children.push(curr);
    }
    return prev;
  }, []);
}

function formatGroup(_logs) {
  const subLogs = _logs.map((group) => {
    let newServer = {
      ...group,
      key: group.oid,
      serverName: group.oid,
      name: group.name,
      status_id: 0,
      children: [],
      errors: 0,
      tooltipLabel: "environnement(s)",
      type: "group",
      type_id: 1,
    };
    if (group.children) {
      const servers = nest()
        .key((d) => d.server_id)
        .entries(group.children);
      let data;
      if (servers.length > 1) {
        data = formatServers(group.children, group);
      } else {
        data = formatClients(group.children);
      }
      newServer.children = data.clients;
      newServer.status_id = data.level;
      newServer.errors = data.errorCount;
    }
    return newServer;
  });
  return {
    name: "Racine",
    children: subLogs.filter((group) => group.children.length),
  };
}

function formatServers(_clients) {
  const servers = nest()
    .key((d) => d.server_id)
    .entries(_clients);
  const fullServers = servers.map((server) => {
    const { level, errorCount, clients } = formatClients(server.values, true);
    return {
      ...server.key,
      name: clients[0].server_name ?? clients[0].server_hostname,
      group_name: clients[0].group_name,
      type: "server",
      type_id: 2,
      level: level,
      errorCount: errorCount,
      children: clients,
    };
  });
  return {
    level: 0,
    errorCount: 0,
    clients: fullServers,
  };
}

function formatClients(_clients) {
  let levels = [];
  const clients = _clients.map((client) => {
    const { clientLevel, errorCount, tasks, expired, running } = formatTasks(
      client.children
    );
    levels.push(clientLevel);
    const modClient = {
      ...client,
      latest_integrity: checkIntegrity(JSON.parse(client.latest_integrity)),
      status_id: clientLevel,
      expired,
      running,
      jobs: tasks,
      errors: errorCount,
      tooltipLabel: "traitements(s)",
      type: "client",
      value: 1,
      type_id: 3,
      group_id: client.group_id,
      group_name: client.group_name,
      server_name: client.server_name ?? client.server_hostname,
    };
    delete modClient.children;
    return modClient;
  });
  let level = levels.reduce(
    (prev, curr) => (Number(curr) > Number(prev) ? curr : prev),
    "0"
  );
  return {
    level: level,
    errorCount: levels.filter((level) => level === "2").length,
    clients,
  };
}

function formatTasks(_tasks) {
  let level = _tasks.reduce((prev, curr) => {
    return Number(curr.status_id) > Number(prev) ? curr.status_id : prev;
  }, "0");
  return {
    expired: _tasks.some((task) => task.expired),
    running: _tasks.some((task) => task.running),
    clientLevel: level,
    errorCount: _tasks.filter((task) => task.status_id === "2").length,
    tasks: _tasks
      .filter((task) => task.traitement_id !== null)
      .map((task) => ({
        ...task,
        history: JSON.parse(task.history),
      })),
  };
}

function checkIntegrity(_data) {
  if (!_data || !Object.keys(_data).length) {
    return true;
  }
  let ret = true;
  const modules = Object.values(_data);
  modules.forEach((module) => {
    const modRet = Object.values(module).includes("KO");
    if (modRet) {
      ret = false;
    }
  });
  return ret;
}
