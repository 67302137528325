import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Table } from "antd";
import { getMessages } from "../../redux/actions/messageActions";

function Messages() {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages.messages);
  const loading = useSelector((state) => state.messages.loading);

  useEffect(() => {
    dispatch(getMessages());
  }, [dispatch]);

  const columns = [
    {
      key: "uuid",
      title: "Identifiant",
      dataIndex: "uuid",
    },
    {
      key: "queue",
      title: "Queue",
      dataIndex: "queue",
    },
    {
      key: "message",
      title: "Message",
      dataIndex: "message",
      render: (text, record) => {
        const parsedMessage = JSON.parse(text);
        return (
          <div className="messages-content">
            <div className="message-item">
              <div className="message-label">Environnement: </div>
              <div>{parsedMessage.env}</div>
            </div>
            <div className="message-item">
              <div className="message-label">Action: </div>
              <div>{parsedMessage.action}</div>
            </div>
            <div className="message-item">
              <div className="message-label">Modules: </div>
              <div>
                {!parsedMessage.modules.length
                  ? "Tous"
                  : parsedMessage.modules.join(",")}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      key: "send_date",
      title: "Date d'envoi",
      dataIndex: "send_date",
    },
    {
      key: "ack_date",
      title: "Date accusé reception",
      dataIndex: "ack_date",
    },
    {
      key: "status",
      title: "Statut",
      dataIndex: "ack_status",
      width: 60,
      align: "center",
      render: (text, record) =>
        record.ack_status === 0 ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : !record.ack_status ? (
          <LoadingOutlined />
        ) : (
          <CloseCircleTwoTone twoToneColor="red" />
        ),
    },
    {
      key: "ack_message",
      title: "Message retour",
      dataIndex: "ack_message",
    },
  ];

  return (
    <React.Fragment>
      <div className="scrollable">
        <Table
          columns={columns}
          dataSource={messages}
          size="small"
          pagination={false}
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
}

export default Messages;
