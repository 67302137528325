import { Button, Divider, List } from "antd";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";

function ClientList({ data, vpnActive, search }) {
  const [group, setGroup] = useState({});

  useEffect(() => {
    const newGroup = {
      ...data,
      paths: data.paths
        ? data.paths.filter((path) => path.label.toLowerCase().includes(search.toLowerCase()))
        : [],
    };
    setGroup(newGroup);
  }, [data, search]);

  const handleCreateLink = (path) => {
    var a = document.createElement("a");
    a.href = path;
    a.target = "_blank";
    a.click();
  };

  if (!group.paths || !group.paths.length) {
    return null;
  }
  return (
    <>
      <h3>{data.name}</h3>
      <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
      <List
        itemLayout="horizontal"
        dataSource={data.paths.filter((path) => path.label.toLowerCase().includes(search.toLowerCase()))}
        renderItem={(path) => (
          <List.Item
            actions={[
              <Button
                type="primary"
                disabled={!vpnActive}
                onClick={() => handleCreateLink(path.url)}
                style={{ minWidth: "120px" }}
              >
                Ouvrir
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={<UserOutlined />}
              description={vpnActive ? "VPN Démarré" : "VPN coupé"}
              style={{ color: vpnActive ? "green" : "red" }}
              title={path.label}
            />
          </List.Item>
        )}
      />
    </>
  );
}

ClientList.propTypes = {
  data: PropTypes.object,
  vpnActive: PropTypes.bool,
  search: PropTypes.string,
};

export default ClientList;
