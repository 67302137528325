import axios from "../../utils/axios";

export const SET_STRATEGIES = "SET_STRATEGIES";
export const SET_STRATEGIES_LOADING = "SET_STRATEGIES_LOADING";
export const ADD_STRATEGY = "ADD_STRATEGY";
export const SET_STRATEGY = "SET_STRATEGY";
export const SET_JOB_STRATEGIES = "SET_JOB_STRATEGIES";

export const setStrategies = (strategies) => {
  return {
    type: SET_STRATEGIES,
    strategies,
  };
};

export const setStrategiesLoading = (loading) => {
  return {
    type: SET_STRATEGIES_LOADING,
    loading,
  };
};

export const setStrategy = (strategy) => {
  return {
    type: SET_STRATEGY,
    strategy,
  };
};

export const addStrategy = (strategy) => {
  return {
    type: ADD_STRATEGY,
    strategy,
  };
};

export const setJobStrategies = (data) => {
  return {
    type: SET_JOB_STRATEGIES,
    data,
  };
};

export const getStrategies = () => {
  return async function (dispatch, getState) {
    dispatch(setStrategiesLoading(true));
    const res = await axios.get("/api/strategies");
    if (res.data && Array.isArray(res.data)) {
      dispatch(setStrategies(res.data));
    }
    dispatch(setStrategiesLoading(false));
  };
};

export const updateStrategy = (strategy) => {
  return async function (dispatch, getState) {
    dispatch(setStrategy(strategy));
    await axios.put("/api/strategies/" + strategy.key, { strategy });
  };
};

export const createStrategy = (strategy) => {
  return async function (dispatch, getState) {
    await axios.post("/api/strategies", { strategy });
    dispatch(getStrategies());
  };
};

export const deleteStrategy = (key) => {
  return async function (dispatch, getState) {
    await axios.delete("/api/strategies/" + key);
    dispatch(getStrategies());
  };
};

export const getJobStrategies = () => {
  return async function (dispatch, getState) {
    dispatch(setStrategiesLoading(true));
    const res = await axios.get("/api/jobstrategies");
    if (res.data) {
      dispatch(setJobStrategies(res.data));
    }
    dispatch(setStrategiesLoading(false));
  };
};

export const updateJobStrategy = (job) => {
  return async function (dispatch, getState) {
    dispatch(setStrategiesLoading(true));
    const jobId = job.job_id ?? job.oid;
    await axios.put("/api/jobstrategies/" + jobId, { job });
    dispatch(getJobStrategies());
  };
};
