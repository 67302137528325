import { Modal, Spin, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LogLineError from "./LogLineError";
import ModalTitle from "./ModalTitle";
import axios from "../../utils/axios";
import { setShowLogsModal } from "../../redux/actions/logsActions";

const mostFrequent = (arr) =>
  Object.entries(
    arr.reduce((a, v) => {
      a[v] = a[v] ? a[v] + 1 : 1;
      return a;
    }, {})
  ).reduce((a, v) => (v[1] >= a[1] ? v : a), [null, 0])[0];

function LogModal() {
  const [logs, setLogs] = useState([]);
  const [build, setBuild] = useState();
  const [diag, setDiag] = useState();
  const dispatch = useDispatch();

  const visible = useSelector((state) => state.logs.showModal);
  const data = useSelector((state) => state.logs.selectedBuild);
  const errors = useSelector((state) => state.errors.errors);
  const authenticated = useSelector((state) => state.keycloak.authenticated);

  const getLogs = async (_data) => {
    setLogs([]);
    const res = await axios.get(
      "/api/logs/" + _data.build + "/" + _data.traitement_id
    );
    setLogs(res.data.logs);
    setBuild(res.data.build);
  };

  const getDiag = React.useCallback(() => {
    const errorsLogs = logs
      .filter((log) => log.status_id > 0)
      .map((log) => log.error_oid);
    const mostFrequentErrorId = Number(mostFrequent(errorsLogs));
    setDiag(errors.find((err) => err.oid === mostFrequentErrorId));
  }, [errors, logs]);

  useEffect(() => {
    if (data) {
      getLogs(data);
    }
  }, [data]);

  useEffect(() => {
    getDiag();
  }, [logs, errors, getDiag]);

  const getColor = (_row, _tag = false) => {
    let color = _tag ? "blue" : "black";
    switch (_row.status_id) {
      case 2:
        color = _tag ? "red" : "#d32f2f";
        break;
      case 1:
        color = _tag ? "orange" : "#ff9800";
        break;
      default:
        break;
    }
    return color;
  };

  const handleRowProps = (_record) => {
    const color = getColor(_record);
    return {
      style: { color },
    };
  };

  const handleErrorRender = (text, record, index) => {
    if (record.status_id === 0) {
      return null;
    } else {
      return <LogLineError record={record} errors={errors} />;
    }
  };

  const handleSetVisible = () => {
    dispatch(setShowLogsModal());
  };

  let columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 100,
    },
    {
      title: "Heure",
      dataIndex: "time",
      key: "time",
      width: 80,
    },
    {
      title: "Niveau",
      dataIndex: "level",
      key: "level",
      width: 80,
      render: (text, record) => {
        return <Tag color={getColor(record, true)}>{text}</Tag>;
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
  ];

  if (authenticated) {
    columns.push({
      title: "Erreur",
      dataIndex: "error",
      key: "error",
      render: handleErrorRender,
    });
  }

  return (
    <Modal
      title={<ModalTitle getLogs={getLogs} build={build} diag={diag} />}
      visible={visible}
      onOk={handleSetVisible}
      onCancel={handleSetVisible}
      width="85vw"
      className="logs-modal"
      footer={null}
    >
      {logs.length ? (
        <Table
          columns={columns}
          dataSource={logs}
          pagination={false}
          size="small"
          onRow={handleRowProps}
        />
      ) : (
        <Spin style={{ width: "100%" }} />
      )}
    </Modal>
  );
}

export default React.memo(LogModal);
